import { Customizer } from '@koala/sdk';
import { type AnyAction } from 'redux';
import actions from './actions';
import { type ICustomizeState } from '@/types/customize';
import { toggleBodyScroll } from '@/utils/global';

export const initialState: ICustomizeState = {
  customize: null,
  canonicalProduct: null,
  index: null,
  final: null,
  label: null,
  open: false,
  options: [],
  product: null,
  quantity: 1,
  upsellId: null,
  displayOptionsErrors: false,
  toggleToastOptionsError: false,
};

export const customize = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_PRODUCT_TO_CUSTOMIZE: {
      if (action.payload && action.payload.product) {
        const customize = new Customizer(action.payload.product, action.payload.existingOptions);
        const product = customize.getProduct();

        // Disable body scroll
        toggleBodyScroll(false);

        return {
          customize,
          canonicalProduct: action.payload.product,
          index: action.payload.index,
          final: product,
          label: action.payload.label,
          product,
          options: product.options,
          open: typeof action.payload.open !== 'undefined' ? action.payload.open : true,
          quantity: action.payload.item ? action.payload.item.quantity : initialState.quantity,
          upsellId: action.payload.upsellId,
        };
      }

      return state;
    }
    case actions.CLEAR_PRODUCT_TO_CUSTOMIZE:
      // Re-enable body scroll
      toggleBodyScroll(true);

      return { ...initialState };
    case actions.INCREMENT_OPTION: {
      if (action.payload && action.payload.id) {
        state.customize.incrementOption(action.payload.id);

        const product = state.customize.getProduct();

        return {
          ...state,
          product,
          options: product.options,
          final: product,
        };
      }
      return state;
    }
    case actions.DECREMENT_OPTION: {
      if (action.payload && action.payload.id) {
        state.customize.decrementOption(action.payload.id, action.payload.setToZero);

        const product = state.customize.getProduct();

        return {
          ...state,
          product,
          options: product.options,
          final: product,
        };
      }
      return state;
    }
    case actions.DISPLAY_OPTIONS_ERRORS:
      return Object.assign({}, state, {
        displayOptionsErrors: action.displayOptionsErrors,
      });
    case actions.TOGGLE_TOAST_OPTIONS_ERROR:
      return Object.assign({}, state, {
        toggleToastOptionsError: action.toggleToastOptionsError,
      });
    case actions.TOGGLE_QUANTITY:
      if (action.payload) {
        return {
          ...state,
          quantity: action.payload.quantity,
        };
      }
      return state;
    default:
      return state;
  }
};
