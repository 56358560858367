import { CONVEYANCE_TYPES } from '@koala/sdk';
import { DATE_FORMAT } from '@/constants/dates';
import { type IAdvancedOrderDays } from '@/types/fulfillment';
import { addTime, formatDate } from '@/utils/dates';

// TODO - test
export const deriveDeliveryDates = (
  locationOrderAhead: boolean | undefined,
  locationAdvanceOrderDays: number,
) => {
  const dates: IAdvancedOrderDays[] = [];
  let daysAhead = 1;

  // Days to order ahead, assuming the location supports advanced ordering
  if (locationOrderAhead) {
    daysAhead = daysAhead + locationAdvanceOrderDays;
  }

  for (let i = 0; i < daysAhead; i++) {
    const day = addTime(new Date(), i, 'days');

    dates.push({
      value: formatDate(day, DATE_FORMAT.YEAR_MONTH_DAY_DASHED),
      label: formatDate(day, DATE_FORMAT.LABEL),
    });
  }

  return dates;
};

// TODO - test
export const determineHourType = (type: CONVEYANCE_TYPES) =>
  type === CONVEYANCE_TYPES.DELIVERY ? 'delivery_hours' : 'operating_hours';
