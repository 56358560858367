import type * as Stitches from '@stitches/react';
import { Base, Field } from './base';
import { useConfigOverride } from '@/features/configs/configs';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  required?: boolean;
  type?: string;
  error?: string;
  css?: Stitches.CSS;
}

export function Input({ label, name, required = false, error, ...otherProps }: Props) {
  const baseProps = { label, name, required, error };
  const { background_color, border_color, border_radius, font } = useConfigOverride('forms');

  return (
    <Base {...baseProps}>
      <Field
        id={name}
        placeholder=" "
        required={required}
        aria-label={label}
        css={{
          backgroundColor: background_color ?? 'transparent',
          borderColor: border_color ?? '$shade300',
          borderRadius: `${Number(border_radius)}px`,
          ...getFontStyles(font, ['color', 'font_family', 'font_weight', 'font_style']),
        }}
        {...otherProps}
      />
    </Base>
  );
}
