export enum StoredValueFields {
  PIN = 'pin',
  CARD = 'card',
}

export const storedValueMessages = {
  'PIN field is required.': StoredValueFields.PIN,
  'Oops, gift card not found': StoredValueFields.CARD,
  VALID: 'Gift Card Applied!',
  VALID_WITH_BALANCE:
    'Gift Card Applied. Please enter payment information to Pay for the balanace of the order.',
  VALID_WITH_TIP_OR_FEES:
    'Gift Card Applied. Please enter payment information to Pay for the balanace of the order. Taxes, Fees, ad Tip cannot be paid for with a Gift Card.',
};

// Whitelisted API errors
export const storedValueErrors = {
  PIN_REQUIRED: 'PIN field is required.',
  CAPTCHA: 'Oops, we could not validate this gift card. Please try again.',
  DEFAULT: 'Oops, gift card not found',
  INVALID: 'Invalid Gift Card, Please Try Again.',
};
