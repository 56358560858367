import { type ParsedUrlQuery } from 'querystring';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { type SingletonRouter, type NextRouter } from 'next/router';
import {
  ALLOWED_URL_PARAMETER_KEYS,
  ALLOWED_URL_PARAMETER_VALUES,
  KOALA_SESSION_STORAGE_KEYS,
} from '@/constants/global';
import { LOYALTY_ROUTES, ROUTES } from '@/constants/routes';
import { type IErrorResponse } from '@/types/errors';

// Generic method to group an array of objects by a specific key
// currently used to group locations by state and cart items by filter tag
/** @TODO refactor this utility to correctly use generics. */
export const genericGroupItemsByKey = (array: {}[], stringPathToKey: string, uniqueBy?: string) => {
  // `stringPathToKey` is the path to the key you want to sort by - accessible via the lodash 'get' method
  const filtersObject = {};
  const itemDict = {};
  // @ts-expect-error
  let filtersArray = [];

  if (!array) {
    // @ts-expect-error
    return { filtersArray, filtersObject };
  }

  array.forEach((arrayItem, index) => {
    const entry = get(arrayItem, stringPathToKey, false);

    // Don't push duplicate items if a uniqueBy property is defined
    if (uniqueBy) {
      // @ts-expect-error
      if (itemDict[arrayItem[uniqueBy]]) {
        return;
      }
      // @ts-expect-error
      itemDict[arrayItem[uniqueBy]] = true;
    }
    // @ts-expect-error
    filtersObject[entry] = filtersObject[entry] || [];
    // @ts-expect-error
    filtersObject[entry].push({ ...arrayItem, index });
  });

  // Derive an array of key strings
  filtersArray = Object.keys(filtersObject);
  filtersArray.sort();

  // Order the entries within each filtersObject key
  Object.keys(filtersObject).forEach((key) => {
    // @ts-expect-error
    filtersObject[key] = orderBy(
      // @ts-expect-error
      filtersObject[key],
      ['entry', 'label'],
      ['asc', 'asc'],
    );
  });

  return { filtersArray, filtersObject };
};

/**
 * Disable body scroll when scrollable / fixed overlays appear
 *
 * @param {Boolean} scroll
 */
export const toggleBodyScroll = (scroll: boolean) => {
  // Add the 'no-scroll' class via vanilla JS since not sure how to pass redux state to _document
  if (!scroll) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
};

/**
 * Calculate the scrollTo offset dependent on which header style is used.
 */
export const scrollToOffset = (elementTop = 0, additionalOffset = 0) => {
  if (typeof window === 'undefined') {
    return 0;
  }

  return elementTop + window.pageYOffset - additionalOffset;
};

/**
 * Calculate the x & y offsets of an document element
 */
export const getElementOffset = (el: HTMLElement | null) => {
  let _x = 0;
  let _y = 0;

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    // @ts-expect-error assigning Element to HTMLElement
    el = el.offsetParent;
  }

  return { top: _y, left: _x };
};

export const deriveErrorMessage = (error: IErrorResponse | null): string => {
  if (!error) {
    return '';
  }

  let message = '';

  if (typeof error.error_description === 'string') {
    message += `${error.error_description} `;
  }

  if (error.error_data && typeof error.error_data.failures === 'object') {
    message += Object.values(error.error_data.failures).join(', ');
  } else if (typeof error.error_description === 'object' && typeof error.error === 'string') {
    message += error.error;
  }

  return message;
};

export interface PreparedErrorMessage {
  message: string;
  error: IErrorResponse | null;
}

function isResponse(error: unknown): error is Response {
  return typeof (error as Response).text === 'function';
}

export async function prepareErrorMessage(
  staticMessage: string | null,
  errorResponse: unknown,
): Promise<PreparedErrorMessage> {
  let message = staticMessage ?? '';
  let error: IErrorResponse | null = null;
  if (isResponse(errorResponse)) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      error = await errorResponse
        .text()
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then((text) => (text ? JSON.parse(text) : {}));
    } catch (e) {
      console.error(e, errorResponse.text());
    }
    message += ` ${deriveErrorMessage(error)}`;
  } else if (errorResponse && typeof errorResponse === 'object' && 'text' in errorResponse) {
    // @ts-expect-error: typing issues
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    error = JSON.parse(errorResponse.text);
    message += deriveErrorMessage(error);
  } else {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    message += ` ${errorResponse}`;
  }

  return { message, error };
}

/**
 * Given a pathname, derive a user-facing page title
 */
export const derivePageNameFromPath = (pathname?: string) => {
  let pageName = '';

  if (!pathname) {
    return pageName;
  }

  // Iterate through a pathname array
  pathname.split('/').forEach((pathParam: string) => {
    if (pathParam) {
      // Remove all dashes
      const sanitizedPathParam = pathParam.replace(/-/g, ' ');

      // Uppercase first letter & add pipe
      pageName += `${sanitizedPathParam.charAt(0).toUpperCase()}${sanitizedPathParam.slice(1)} | `;
    }
  });

  return decodeURI(pageName);
};

/**
 * Return a valid referrer for use on post sign-up or login
 */
export const deriveReferrer = (router: {
  query: { referrer?: string };
  pathname: string;
  asPath: string;
}) => {
  // Default to homepage redirect
  let referrer = ROUTES.HOMEPAGE;

  // /checkout or /store/[...id] are valid referrer pages
  if (
    [
      ROUTES.CHECKOUT,
      ROUTES.STORE,
      ROUTES.ANDROID,
      LOYALTY_ROUTES.REWARDS,
      LOYALTY_ROUTES.FAVORITES,
    ].includes(router?.pathname)
  ) {
    referrer = router?.pathname;

    // If it's a store page, we have to swap the pathname (/store/[...id]) for the asPath (/store/{storeId}/{storeName})
    if (router.pathname === ROUTES.STORE) {
      referrer = router?.asPath;
    }
  }

  // BUT! if we have an existing referrer, lets just pass that along
  return encodeURIComponent(router?.query?.referrer || referrer);
};

/**
 * Return a valid referrer for use on post sign-up or login
 */
export const parseReferrer = (router: SingletonRouter | NextRouter) => {
  // Either we'll use the existing referrer or the homepage
  let referrer = router?.query?.referrer || ROUTES.HOMEPAGE;

  // If we're on the checkout page, we do not want to redirect
  if (ROUTES.CHECKOUT === router.pathname) {
    referrer = '';
  }

  return referrer;
};

export const copyToClipboard = (str?: string | null) => {
  if (!str) {
    return;
  }

  // Works for all browsers except IE
  const copySuccess = navigator.clipboard.writeText(str).then(
    () =>
      /* clipboard successfully set */
      true,
    () =>
      /* clipboard write failed */
      false,
  );

  return copySuccess;
};

export const getCanonicalUrl = (baseUrl: string, router: NextRouter) => {
  // Remove hashes and query params from url
  let path = router.asPath.split(/[?#]/)[0];

  // Standardize store urls
  if (router.pathname === ROUTES.STORE) {
    /** @TODO guard to ensure that query.id is defined. */
    // @ts-expect-error
    path = `/store/${router.query.id[0]}`;
  }

  return `${baseUrl}${path}`;
};

/**
 * Derive a collection of persistable parameters from a given collection of URL parameters.
 *
 * @param parameters Record<string, string>
 * @param allowUnlisted boolean
 * @returns Record<string, string>
 */
export const derivePersistentURLParameters = (parameters: ParsedUrlQuery): ParsedUrlQuery => {
  const derivedParameters: ParsedUrlQuery = {};

  Object.keys(parameters).forEach((param) => {
    /** @TODO ensure that `parameters[param]` is defined before use. */
    // @ts-expect-error
    if (isPersistentParameterAllowed(param, parameters[param].toString())) {
      derivedParameters[param] = parameters[param];
    }
  });

  return derivedParameters;
};

/**
 * Determine if a parameter and its value are allowed to persist
 *
 * @param parameter string
 * @param value string
 * @return boolean;
 */
export const isPersistentParameterAllowed = (parameter: string, value: string): boolean => {
  if (!Object.values(ALLOWED_URL_PARAMETER_KEYS).includes(parameter)) {
    return false;
  }

  // Check if value is valid (empty [] indicate any value is acceptable and is also valid)
  if (
    ALLOWED_URL_PARAMETER_VALUES[parameter].includes(value) ||
    ALLOWED_URL_PARAMETER_VALUES[parameter].length === 0
  ) {
    return true;
  }

  return false;
};

/**
 * Get a persistent parameter value from session storage
 *
 * @param parameter string
 * @returns string
 */
export const getPersistentParameterValue = (parameter: string): string => {
  try {
    const sessionParameters = JSON.parse(
      sessionStorage.getItem(KOALA_SESSION_STORAGE_KEYS.PARAMETERS) || '{}',
    );

    return sessionParameters[parameter];
  } catch (err) {
    if (err instanceof Error) {
      // This is the same error that Safari private browsing will return
      if (err.name === 'QUOTA_EXCEEDED_ERR') {
        console.error('We have exceeded the allowed storage quota');
      }
    }

    console.error(err);

    return '';
  }
};

export function removeScriptFromDOM(scriptSource: string) {
  const script = document.querySelector(`script[src="${scriptSource}"]`);

  script?.parentNode?.removeChild(script);
}

export function isScriptInDOM(scriptSource: string) {
  return Boolean(document.querySelector(`script[src="${scriptSource}"]`));
}

export function addScriptToDOM(scriptSource: string, callBackOnLoad: () => void) {
  const script = document.createElement('script');

  script.src = scriptSource;
  //script is executed when the page has finished parsing.
  //https://developer.mozilla.org/en-US/docs/Web/API/Window/DOMContentLoaded_event
  script.defer = true;

  script.onload = callBackOnLoad;

  script.onerror = (event: Event | string) => {
    const src =
      typeof event === 'string'
        ? scriptSource
        : // NOTE: have to cast target to HTMLScriptElement as in ts definition target is a EventTarge type which does not have src.
          // But in current context it is a HTMLScriptElement
          (event.target as HTMLScriptElement | null)?.src;

    console.warn(`The script "${src}" didn't load correctly.`);
  };

  document.body.appendChild(script);
}
