import { type IGlobalEvent, type IGlobalEventContext } from '@/analytics/events/constants';

export const pushDataLayerPageView = (path: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'pageview',
    page: {
      path,
    },
  });
};

export const pushDataLayerGenericEvent = (event: IGlobalEvent, context: IGlobalEventContext) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event.eventName || context.name,
  });
};
