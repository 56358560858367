import styled from 'styled-components';
import { getDetails } from 'use-places-autocomplete';
import { mapAutocompleteAddress, useAddressAutocomplete } from './helpers';
import { Image } from '@/components/image';
import { Combobox } from '@/components/ui/form/combobox';
import { useStringsOverride } from '@/redux/cmsConfig';

interface GoogleAddress {
  street_address: string;
  city: string;
  state: string;
  zip_code: string;
}

interface Props {
  onUpdate: (address: GoogleAddress) => void | Promise<void>;
}

export const GoogleAutocomplete = ({ onUpdate }: Props) => {
  const { delivery_search_placeholder } = useStringsOverride('locations');
  const { clearSuggestions, data, ready, updateValue } = useAddressAutocomplete();

  async function setSelection(suggestion: google.maps.places.AutocompletePrediction) {
    const { description, place_id } = suggestion;

    // When user selects a place, we can replace the keyword without
    // request data from API by setting the second parameter to `false`
    updateValue(description, false);
    clearSuggestions();

    const details = await getDetails({
      placeId: place_id,
      fields: ['address_components', 'place_id', 'name', 'types'],
    });

    if (typeof details === 'string' || !details?.address_components) {
      return;
    }

    const {
      locality,
      sublocality,
      sublocality_level_1,
      administrative_area_level_1,
      postal_code,
      street_number,
      route,
    } = mapAutocompleteAddress(details.address_components);

    // everything returned can be undefined, so we cast as a string
    // the address object will always be checked by the Ordering API
    // to this should be a safe operation
    void onUpdate({
      street_address: `${String(street_number)} ${String(route)}`.trim(),
      city: String(locality ?? sublocality ?? sublocality_level_1),
      state: String(administrative_area_level_1),
      zip_code: String(postal_code),
    });
  }

  if (!ready) {
    return null;
  }

  return (
    <Combobox
      label={delivery_search_placeholder}
      items={data.map((suggestion) => {
        const {
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return {
          value: suggestion,
          label: `${main_text}, ${secondary_text}`,
        };
      })}
      onChange={async (value) => {
        // @ts-expect-error: value is unknown but we know it equates to the `value` property in the object returned from `data.map` above
        await setSelection(value);
      }}
      onUpdate={(value) => {
        updateValue(value);
      }}
    >
      <PoweredByGoogle>
        <Image
          src="/static/img/powered_by_google_on_white_hdpi.png"
          alt="Powered By Google"
          height={12}
          width={96}
        />
      </PoweredByGoogle>
    </Combobox>
  );
};

const PoweredByGoogle = styled.div({
  marginInlineStart: 'auto',
  marginInlineEnd: '1rem',
  marginBlockEnd: '.05rem',
});
