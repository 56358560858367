import { type Category, type BasketItem, type ExistingOption, type MenuProduct } from '@koala/sdk';
import { type PRODUCT_LOCATION_LABELS } from '@/constants/checkout';

const customizeActions = {
  SET_PRODUCT_TO_CUSTOMIZE: '/koala/customize/SET_PRODUCT_TO_CUSTOMIZE',
  SET_CROSS_SELL_QUICK_ADD: '/koala/customize/SET_CROSS_SELL_QUICK_ADD',
  INCREMENT_OPTION: '/koala/customize/INCREMENT_OPTION',
  DECREMENT_OPTION: '/koala/customize/DECREMENT_OPTION',
  CLEAR_PRODUCT_TO_CUSTOMIZE: '/koala/customize/CLEAR_PRODUCT_TO_CUSTOMIZE',
  TOGGLE_QUANTITY: '/koala/customize/TOGGLE_QUANTITY',
  DISPLAY_OPTIONS_ERRORS: '/koala/customize/DISPLAY_OPTIONS_ERRORS',
  TRIGGER_TOAST_OPTIONS_ERROR: '/koala/customize/TRIGGER_TOAST_OPTIONS_ERROR',
  TOGGLE_TOAST_OPTIONS_ERROR: '/koala/customize/DISPLAY_TOAST_OPTIONS_ERROR',

  setProduct: (payload: {
    product: MenuProduct;
    menuCategories: Category[];
    label: PRODUCT_LOCATION_LABELS;
    item?: BasketItem | null;
    index?: number;
    upsellId?: string;
    existingOptions?: Record<string, ExistingOption>;
    open?: boolean;
  }) => ({
    type: customizeActions.SET_PRODUCT_TO_CUSTOMIZE,
    payload,
  }),
  setCrossSellQuickAdd: (product: MenuProduct, menuCategories: Category[], upsellId?: string) => ({
    type: customizeActions.SET_CROSS_SELL_QUICK_ADD,
    payload: {
      product,
      upsellId,
      menuCategories,
    },
  }),
  clearProduct: () => ({
    type: customizeActions.CLEAR_PRODUCT_TO_CUSTOMIZE,
  }),
  incrementOption: (id: string) => ({
    type: customizeActions.INCREMENT_OPTION,
    payload: {
      id,
    },
  }),
  decrementOption: (id: string, setToZero?: boolean) => ({
    type: customizeActions.DECREMENT_OPTION,
    payload: {
      id,
      setToZero,
    },
  }),
  toggleQuantity: (quantity: number) => ({
    type: customizeActions.TOGGLE_QUANTITY,
    payload: {
      quantity,
    },
  }),
  displayOptionsErrors: (bool: boolean) => ({
    type: customizeActions.DISPLAY_OPTIONS_ERRORS,
    displayOptionsErrors: bool,
  }),
  triggerToastOptionsError: () => ({
    type: customizeActions.TRIGGER_TOAST_OPTIONS_ERROR,
  }),
  toggleToastOptionsError: (bool: boolean) => ({
    type: customizeActions.TOGGLE_TOAST_OPTIONS_ERROR,
    toggleToastOptionsError: bool,
  }),
};

export default customizeActions;
