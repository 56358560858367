import { type IGlobalEvent, type IGlobalEventContext } from '@/analytics/events/constants';
import { GOOGLE_TRACKER_NAME } from '@/utils/googleAnalytics';

/** @deprecated Google Universal Analytics will cease to collect data on July 1, 2023 */
export const fireGaPageView = (page: string) => {
  if (typeof window?.ga !== 'function') {
    return;
  }

  window.ga(`${GOOGLE_TRACKER_NAME}send`, {
    hitType: 'pageview',
    page,
  });
};

/** @deprecated Google Universal Analytics will cease to collect data on July 1, 2023 */
export const fireGaGenericEvent = (event: IGlobalEvent, context: IGlobalEventContext) => {
  if (typeof window?.ga !== 'function') {
    return;
  }

  // Google actions should map to the static eventName, unless it is not defined this
  // means the action should be dynamic, in which case, use the context.name
  const eventAction = event.eventName || context.name;

  // If the eventName is undefined, we've used the context.name as the action
  // meaning we should use the context.details as the label
  const eventLabel = event.eventName ? context.name : context.details;

  const mergedParams = {
    hitType: 'event',
    eventCategory: event.eventCategory,
    eventAction,
    eventLabel,
    // eventValue: params?.value,
  };

  window.ga(`${GOOGLE_TRACKER_NAME}send`, mergedParams);
};
