export const BASKET_ERROR = {
  initCheckoutSaga: 'Oops, an error occurred creating the checkout basket.  Please reload.',
  submitCheckoutSaga: 'Oops, an error occurred submitting the checkout basket.',
  fetchSavedCardSaga: 'Oops, an error occurred fetching saved payment info.  Please reload.',
  fetchRewardsSaga: 'Oops, an error occurred fetching available rewards: ',
  addRewardsSaga: 'Oops, an error occurred applying a reward to the checkout basket: ',
  removeRewardSaga: 'Oops, an error occurred removing a reward from the checkout basket: ',
  getPickupTimesSaga:
    'Oops, an error occurred loading the available times for the order. Please try reloading.',
  setPickupTimeSaga: 'Oops, an error occurred setting the selected time for the order.',
  addPromoSaga: 'Oops, an error occurred applying a coupon to the checkout basket: ',
  removePromoSaga: 'Oops, an error occurred clearing a coupon from the checkout basket.',
  applyAllergensSaga: 'Oops, an error occurred applying applying allergens to the basket.',
  addItemErrorToastSaga: 'Oops, an error occurred adding an item to your basket',
  pleaseReload: 'Please try reloading.',
  genericErrorSaga: 'Oops, an error occurred: ',
  addTipSaga: 'Oops, an error occurred applying a tip to your order: ',
};

export const BASKET_MESSAGE = {
  addItemToastSaga: 'An item was added to your basket',
  addItemsToastSaga: 'items were added to your basket',
  updateItemToastSaga: 'An item was updated in your basket',
  updateItemsToastSaga: 'items were updated in your basket',
};
