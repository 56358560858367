import {
  type MenuProductWithCategory,
  type BasketItem,
  type BasketOrder,
  type Category,
  type Location,
  type MenuProduct,
  type OrderDetails,
  type BasketItemOption,
  sdkUtils,
} from '@koala/sdk';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { type IBasketState } from '@/types/basket';
import {
  findOptionByOptionId,
  recreateCustomizerProductFromMenuProduct,
  toDollars,
} from '@/utils/basket';
import { fireGaEvent, gaActions, gaCats, GOOGLE_TRACKER_NAME } from '@/utils/googleAnalytics';

//Google UA commerce actions
const GOOGLE_COMMERCE_ACTIONS = {
  click: 'click',
  detail: 'detail',
  add: 'add',
  remove: 'remove',
  checkout: 'checkout',
  checkout_option: 'checkout_option',
  purchase: 'purchase',
  refund: 'refund',
  promo_click: 'promo_click',
};

//Google Enhanced UA Commerce events pushed through GTM
const GOOGLE_COMMERCE_EVENTS = {
  productClick: 'productClick',
  productView: 'productView',
  addToCart: 'addToCart',
  removeFromCart: 'removeFromCart',
  promotionClick: 'promotionClick',
  checkout: 'checkout',
  purchase: 'purchase',
  productImpression: 'productImpression',
};

// Checkout steps reported through GTM
export const GOOGLE_COMMERCE_CHECKOUT_STEPS = {
  1: 'Checkout Page Reached',
  2: 'User Details Set',
  3: 'Basket Staged',
  4: 'Payment Set',
  5: 'Order Submission Started',
  6: 'Receipt Received',
};

//Maps UA and GTM commerce actions to Gtag.js commerce event names
const commerceToGTagMap = new Map<string, Gtag.EventNames>([
  [GOOGLE_COMMERCE_ACTIONS.click, 'select_item'],
  [GOOGLE_COMMERCE_ACTIONS.detail, 'view_item'],
  [GOOGLE_COMMERCE_ACTIONS.add, 'add_to_cart'],
  [GOOGLE_COMMERCE_ACTIONS.remove, 'remove_from_cart'],
  [GOOGLE_COMMERCE_ACTIONS.checkout, 'begin_checkout'],
  [GOOGLE_COMMERCE_ACTIONS.purchase, 'purchase'],
  [GOOGLE_COMMERCE_ACTIONS.refund, 'refund'],
  [GOOGLE_COMMERCE_ACTIONS.promo_click, 'select_promotion'],
  [GOOGLE_COMMERCE_EVENTS.productClick, 'select_item'],
  [GOOGLE_COMMERCE_EVENTS.productView, 'view_item'],
  [GOOGLE_COMMERCE_EVENTS.addToCart, 'add_to_cart'],
  [GOOGLE_COMMERCE_EVENTS.removeFromCart, 'remove_from_cart'],
  [GOOGLE_COMMERCE_EVENTS.promotionClick, 'select_promotion'],
  [GOOGLE_COMMERCE_EVENTS.checkout, 'begin_checkout'],
  [GOOGLE_COMMERCE_EVENTS.purchase, 'purchase'],
  [GOOGLE_COMMERCE_EVENTS.productImpression, 'view_item'],
]);

interface ICommerceProduct {
  id: string;
  name: string;
  category: string;
  brand: string;
  variant: string;
  price: number;
  quantity: number;
  position?: number;
  list?: string;
  affiliation?: string;
}

/**
 * Google Analytics ecommerce channel support
 */
/** @deprecated The hasUA() check will not be relevant after UA is deprecated on July 1, 2023 */
const hasUA = (): boolean => typeof window?.ga === 'function';
const hasGtm = (): boolean => typeof window?.dataLayer?.push === 'function';
const hasGtag = (): boolean => typeof window?.gtag === 'function';

/**
 * Assemble a standard Google Commerce product
 *
 */
export const buildCommerceProduct = (
  menuProduct: MenuProduct | MenuProductWithCategory | undefined,
  category: string,
  location?: Location,
  item?: BasketItem,
): ICommerceProduct | null => {
  if (!menuProduct) {
    return null;
  }

  // Default menu product values - i.e. clicking on a menu product or viewing a menu item
  let productVariant = '';
  let productCost = menuProduct.cost;

  // If we have a working version of the item - i.e. have applied modifiers via customization
  if (item?.options.length) {
    //Build a comma-separated list of product option names for the product variant field
    productVariant = item.options
      .map(
        (option: BasketItemOption) =>
          option?.name ?? findOptionByOptionId(option?.id, menuProduct?.option_groups ?? [])?.name, //Fallback to the option group name found in the menu
      )
      .filter((name: string | undefined) => name)
      .join(', ');

    productCost =
      recreateCustomizerProductFromMenuProduct(menuProduct, item.options)?.final_cost ?? 0;
  }

  const commerceProduct: ICommerceProduct = {
    id: menuProduct?.id ?? item?.id ?? '',
    name: menuProduct?.name ?? item?.product.name ?? '',
    category,
    brand: '',
    variant: productVariant,
    price: parseFloat(toDollars(productCost)),
    quantity: item?.quantity ?? 1,
  };

  if (location) {
    commerceProduct.brand = location.label;
    commerceProduct.affiliation = `Store: ${location.id ?? ''} - ${location.label}`;
  }

  return commerceProduct;
};

/**
 * Translates an item built for reporting through a GTM action to a GTag item
 * @param commerceProduct
 * @return ICommerceProduct
 */
const translateCommerceProductToGTagItem = (commerceProduct: ICommerceProduct): Gtag.Item => ({
  item_id: commerceProduct.id,
  item_name: commerceProduct.name,
  item_category: commerceProduct.category,
  item_brand: commerceProduct.brand || undefined,
  item_variant: commerceProduct.variant || undefined,
  price: commerceProduct.price,
  quantity: commerceProduct.quantity,
  index: commerceProduct?.position,
  item_list_name: commerceProduct?.list,
});

/**
 * Derive commerce list value from PRODUCT_LOCATION_LABELS
 *
 * Basically the list value will be the label, unless coming from the Menu,
 * in which case we also append the product category name
 */
const deriveProductList = (label: PRODUCT_LOCATION_LABELS, categoryName?: string): string =>
  label === PRODUCT_LOCATION_LABELS.MENU ? `${label}: ${categoryName ?? ''}` : label;

/**
 * Google Add Product Impression pushed through UA ECommerce and GTM.
 * An impression for each menu category product are triggered when the first time a
 * menu category is visible per page view. Impressions are reported through UA and GTM.
 */
export const addImpressionsGoogle = (
  menuProduct: MenuProduct,
  categoryName: string,
  location: Location,
  index: number,
) => {
  const product = buildCommerceProduct(menuProduct, categoryName, location);

  if (!product) {
    return;
  }

  product.position = index + 1;
  product.list = deriveProductList(PRODUCT_LOCATION_LABELS.MENU, categoryName);

  if (hasUA()) {
    window.ga(`${GOOGLE_TRACKER_NAME}ec:addImpression`, product);
  }

  if (hasGtm()) {
    // Push into GTM DataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GOOGLE_COMMERCE_EVENTS.productImpression,
      ecommerce: {
        impressions: [product],
      },
    });
  }

  /**
   * No events are reported through gtag for product impressions.
   * Gtag.js expects the category products consolidated under the "view_item_list" event.
   * See addCategoryViewGoogle().
   */
};

/**
 * Google Add Category View pushed through gtag
 */
export const addCategoryViewGoogle = (category: Category, location?: Location) => {
  if (hasGtag()) {
    const categoryProducts = category.products
      .map((menuProduct?: MenuProduct) =>
        //Abuse the existing GTM commerce item translation function
        buildCommerceProduct(menuProduct, category.name, location),
      )
      .map((commerceProduct: ICommerceProduct | null): Gtag.Item | null =>
        commerceProduct ? translateCommerceProductToGTagItem(commerceProduct) : null,
      )
      .filter((item: Gtag.Item | null) => item);

    if (categoryProducts.length) {
      window.gtag('event', 'view_item_list', {
        item_list_id: category.global_id,
        item_list_name: category.name,
        items: categoryProducts,
      });
    }
  }
};

/**
 * Google Click Product Events are reported through UA, GTM, Gtag.js
 * @param menuProduct
 * @param categoryName
 * @param location
 * @param label
 * @param index
 */
export const clickProductEventsGoogle = (
  menuProduct: MenuProduct,
  categoryName: string,
  location: Location,
  label: PRODUCT_LOCATION_LABELS,
  index: number,
) => {
  const product = buildCommerceProduct(menuProduct, categoryName, location);

  if (!product) {
    return;
  }

  product.position = index + 1;

  fireGaCommerceProductEvent(
    GOOGLE_COMMERCE_ACTIONS.click,
    product,
    deriveProductList(label, categoryName),
    location,
    GOOGLE_COMMERCE_EVENTS.productClick,
  );

  /**
   * Google Analytics
   */
  const action =
    label === PRODUCT_LOCATION_LABELS.CROSS_SELL
      ? gaActions.clickCrossSelllItem
      : gaActions.clickMenuItem;

  fireGaEvent(gaCats.order, action, { label: menuProduct.name });
};

/**
 * Google Add Product Events pushed through UA, GTM, Gtag.js
 * - Commerce does not account for updating an existing basket item
 *
 * @param menuProduct
 * @param location
 * @param label
 * @param menu
 */
export const viewProductEventsGoogle = (
  menuProduct: MenuProduct,
  location: Location,
  label: PRODUCT_LOCATION_LABELS,
  menu: Category[],
) => {
  /**
   * Google Analytics
   */
  fireGaEvent(gaCats.order, gaActions.viewProductDetail, {
    label: menuProduct.name,
  });

  /**
   * Google Commerce
   */
  const menuProductWithCategory = sdkUtils.findMenuItemById(menuProduct.id, menu);

  if (menuProductWithCategory) {
    const categoryName: string = menuProductWithCategory.category.name;

    fireGaCommerceProductEvent(
      GOOGLE_COMMERCE_ACTIONS.detail,
      buildCommerceProduct(menuProduct, categoryName, location),
      deriveProductList(label, categoryName),
      location,
      GOOGLE_COMMERCE_EVENTS.productView,
    );
  }
};

/**
 * Google Add Product Events sent through all three methods
 */
export const addProductEventsGoogle = (
  item: BasketItem,
  location: Location,
  basketMenu: Category[],
  label: PRODUCT_LOCATION_LABELS,
) => {
  /**
   * Google Commerce
   */

  // Find product in basket menu
  const menuProductWithCategory = sdkUtils.findMenuItemById(item.product.id, basketMenu);

  if (!menuProductWithCategory) {
    return;
  }

  const categoryName = item.product?.category_label ?? '';

  // Fire GC Events, only if we are not modifying a basket product
  if (label !== PRODUCT_LOCATION_LABELS.BASKET) {
    const product = buildCommerceProduct(menuProductWithCategory, categoryName, location, item);

    fireGaCommerceProductEvent(
      GOOGLE_COMMERCE_ACTIONS.add,
      product,
      deriveProductList(label, categoryName),
      location,
      GOOGLE_COMMERCE_EVENTS.addToCart,
    );
  }

  /**
   * Google Analytics
   */
  // Derive GA Action from product labels
  let eventAction: string = gaActions.addToBasket;
  if (label === PRODUCT_LOCATION_LABELS.BASKET) {
    eventAction = gaActions.editItem;
  } else if (label === PRODUCT_LOCATION_LABELS.CROSS_SELL) {
    eventAction = gaActions.addCrossSell;
  }

  // Fire GA Event
  fireGaEvent(gaCats.order, eventAction, { label: item.product.name });
};

/**
 * Google Remove Products sent through all three methods
 */
export const removeProductEventsGoogle = (
  item: BasketItem,
  location: Location,
  basketMenu: Category[],
) => {
  const menuItem = sdkUtils.findMenuItemById(item.product.id, basketMenu);

  const categoryName =
    menuItem?.category.name ?? item?.product?.category_label ?? PRODUCT_LOCATION_LABELS.BASKET;

  const product = buildCommerceProduct(menuItem, categoryName, location, item);

  /**
   * Google Commerce
   */
  fireGaCommerceProductEvent(
    GOOGLE_COMMERCE_ACTIONS.remove,
    product,
    deriveProductList(PRODUCT_LOCATION_LABELS.BASKET),
    location,
    GOOGLE_COMMERCE_EVENTS.removeFromCart,
  );

  /**
   * Google Analytics
   */
  fireGaEvent(gaCats.order, gaActions.removeItem, {
    label: item.product?.name,
  });
};

/**
 * GA Commerce Product Events
 *
 * Limited actions: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#action-types
 *
 */
const fireGaCommerceProductEvent = (
  action: string,
  product: ICommerceProduct | null | undefined,
  list: string,
  location: Location,
  event?: string,
) => {
  if (!product) {
    return;
  }

  /**
   * Google Commerce
   */
  if (hasUA()) {
    window.ga(`${GOOGLE_TRACKER_NAME}ec:addProduct`, product);
    window.ga(`${GOOGLE_TRACKER_NAME}ec:setAction`, action, { list });
  }

  if (hasGtm()) {
    // Push into GTM DataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ecommerce: {
        [action]: {
          actionField: { list },
          products: [product],
        },
      },
    });
  }

  //Make sure the event is also sent to gtag with the correct name and format
  if (hasGtag() && commerceToGTagMap.has(event ?? action)) {
    const translatedEventName = commerceToGTagMap.get(event ?? action);
    const gtagItem = translateCommerceProductToGTagItem(product);

    switch (translatedEventName) {
      case 'view_item':
      case 'select_item':
        window.gtag('event', translatedEventName, {
          item_list_id: list,
          item_list_name: list,
          items: [gtagItem],
        });
        break;
      case 'add_to_cart':
        window.gtag('event', translatedEventName, {
          currency: determineCurrencyCodeFromLocation(location),
          value: gtagItem.price,
          items: [gtagItem],
        });
        break;
      case 'remove_from_cart':
        window.gtag('event', translatedEventName, {
          items: [gtagItem],
        });
        break;
    }
  }
};

/**
 * Fire Generic Commerce Checkout Step
 */
export const fireGACommerceStep = (step: number, stepOption?: string) => {
  // @ts-expect-error `step` can't index `GOOGLE_COMMERCE_CHECKOUT_STEPS`.
  const option = stepOption ?? GOOGLE_COMMERCE_CHECKOUT_STEPS[step];

  /**
   * Google Commerce
   */
  if (hasUA()) {
    window.ga(`${GOOGLE_TRACKER_NAME}ec:setAction`, 'checkout', {
      step,
      option,
    });
  }

  if (hasGtm()) {
    // Push into GTM DataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GOOGLE_COMMERCE_EVENTS.checkout,
      ecommerce: {
        checkout: {
          actionField: {
            step,
            option,
          },
        },
      },
    });
  }

  /**
   * Google Analytics
   */
  fireGaEvent(gaCats.checkout, option, { label: `Step: ${step}` });
};

/**
 * Push begin checkout event through gtag
 * @param basket
 * @param basketMenu
 * @param coupon
 */
export const fireGACommerceViewCartEvent = (
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
) => {
  if (hasGtag()) {
    window.gtag(
      'event',
      'view_cart',
      buildCommerceBaseCheckoutEventPayload(basket, basketMenu, coupon),
    );
  }
};

/**
 * Push begin checkout event through gtag
 * @param basket
 * @param basketMenu
 * @param coupon
 */
export const fireGACommerceBeginCheckoutEvent = (
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
) => {
  if (hasGtag()) {
    window.gtag(
      'event',
      'begin_checkout',
      buildCommerceBaseCheckoutEventPayload(basket, basketMenu, coupon),
    );
  }
};

/**
 * Push add shipping info event (delivery address is entered) through gtag
 * @param basket
 * @param basketMenu
 * @param coupon
 * @param shippingMethod
 */
export const fireGACommerceAddShippingInfoEvent = (
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
  shippingMethod?: string,
) => {
  if (hasGtag()) {
    window.gtag('event', 'add_shipping_info', {
      ...buildCommerceBaseCheckoutEventPayload(basket, basketMenu, coupon),
      ...{ shipping_tier: shippingMethod },
    });
  }
};

/**
 * Push add select promo event (coupon code added or reward selected) through gtag
 * @param basket
 * @param basketMenu
 * @param coupon
 */
export const fireGACommerceSelectPromotionEvent = (
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
) => {
  if (hasGtag()) {
    window.gtag(
      'event',
      'select_promotion',
      buildCommerceBasePromoEventPayload(basket, basketMenu, coupon),
    );
  }
};

/**
 * Push add payment info event (payment method is selected during checkout) through gtag
 * @param basket
 * @param basketMenu
 * @param paymentMethod
 * @param coupon
 */
export const fireGACommerceAddPaymentInfoEvent = (
  basket: IBasketState,
  basketMenu: Category[],
  paymentMethod?: string,
  coupon?: string,
) => {
  if (hasGtag()) {
    window.gtag('event', 'payment_info_applied', {
      ...buildCommerceBaseCheckoutEventPayload(basket, basketMenu, coupon),
      ...{ payment_type: paymentMethod },
    });
  }
};

/**
 * Fire Commerce Purchase Event through all three methods
 */
export function fireGACommercePurchaseEvent(
  orderDetails: OrderDetails,
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
) {
  const { content, location }: { content: BasketOrder; location: Location } = basket;

  // Assemble purchase object
  const purchaseObject = {
    id: orderDetails.order_data.order.order_number,
    affiliation: `Store: ${location.location_id ?? ''} - ${location.label}`,
    revenue: toDollars(
      orderDetails.order_data.order.total + (orderDetails.order_data.order?.promo_discount ?? 0),
    ),
    tax: toDollars(orderDetails.order_data.order.sales_tax),
    delivery: toDollars(basket.checkoutBasket?.delivery_fee ?? 0),
    coupon,
  };

  // Assemble products array
  const products = buildCommerceProductListFromBasketItems(
    content.basket_items,
    basketMenu,
    location,
  );

  if (hasUA()) {
    // Fire Commerce Purchase Event
    window.ga(`${GOOGLE_TRACKER_NAME}ec:setAction`, 'purchase', purchaseObject);
  }

  if (hasGtm()) {
    // Push purchaseObject into dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: GOOGLE_COMMERCE_EVENTS.purchase,
      ecommerce: {
        purchase: {
          actionField: purchaseObject,
          products,
        },
      },
    });
  }

  // Conditionally send gtag purchase event if gtag is defined
  if (hasGtag()) {
    const gtagPurchaseObject: Gtag.EventParams = {
      transaction_id: purchaseObject.id,
      value: parseFloat(purchaseObject.revenue),
      currency: determineCurrencyCodeFromLocation(basket.checkoutBasket.location),
      tax: parseFloat(purchaseObject.tax),
      shipping: parseFloat(purchaseObject.delivery),
      coupon: purchaseObject.coupon,
      items: products.map((product) => translateCommerceProductToGTagItem(product)),
    };

    window.gtag('event', 'purchase', gtagPurchaseObject);
  }
}

export function fireGACommerceLoginEvent(loginMethod: string) {
  if (hasGtag()) {
    window.gtag('event', 'login', {
      method: loginMethod,
    });
  }
}

/**
 * Build a basic gtag commerce event payload that contains the currency, value, coupon, and items
 * @param basket
 * @param basketMenu
 * @param coupon
 */
function buildCommerceBaseCheckoutEventPayload(
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
): Gtag.EventParams {
  return {
    currency: determineCurrencyCodeFromLocation(basket?.location),
    value: parseFloat(toDollars(basket?.checkoutBasket?.subtotal ?? 0)),
    coupon: !coupon ? undefined : coupon,
    items: buildCommerceProductListFromBasketItems(
      basket?.content?.basket_items ?? [],
      basketMenu,
      basket.location,
    ).map((product) => translateCommerceProductToGTagItem(product)),
  };
}

/**
 * Build a basic gtag commerce event payload that contains the added promo code and basket items
 * @param basket
 * @param basketMenu
 * @param coupon
 */
function buildCommerceBasePromoEventPayload(
  basket: IBasketState,
  basketMenu: Category[],
  coupon?: string,
): Gtag.EventParams {
  const items: Gtag.Item[] = buildCommerceProductListFromBasketItems(
    basket?.content?.basket_items ?? [],
    basketMenu,
    basket.location,
  ).map((product) => translateCommerceProductToGTagItem(product));

  return {
    promotions: [
      {
        promotion_id: coupon,
      },
    ],
    items: items.filter((product) => !product),
  };
}

/**
 * Build a basic gtag commerce event payload that contains the currency, value, coupon, and items contained in a basket
 * @param basketItems
 * @param basketMenu
 * @param location
 */
function buildCommerceProductListFromBasketItems(
  basketItems: BasketItem[],
  basketMenu: Category[],
  location: Location,
): ICommerceProduct[] {
  // Assemble products array
  const products: ICommerceProduct[] = [];
  basketItems.forEach((item) => {
    const menuProduct = sdkUtils.findMenuItemById(item.product.id, basketMenu);

    if (menuProduct) {
      const product = buildCommerceProduct(menuProduct, menuProduct?.category.name, location, item);

      if (product) {
        products.push(product);
      }
    }
  });

  return products;
}

/**
 * Determines the currency code to report based on the location.
 * @param location: Location | undefined | null
 * @return string | undefined
 */
function determineCurrencyCodeFromLocation(location?: Location): string | undefined {
  if (!location) {
    return undefined;
  }

  switch (location?.country.toUpperCase()) {
    case 'US':
    case 'USA':
      return 'USD';
    case 'CA':
    case 'CAN':
      return 'CAD';
    default:
      return undefined; //Default to undefined instead of incorrectly reporting USD in analytics data
  }
}
