import { type WebConfig } from '@koala/sdk';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { type RootState } from '@/types/app';
import { derivePageNameFromPath } from '@/utils/global';
import { interpolateIntoString } from '@/utils/stringHelpers';

interface Props {
  config: WebConfig;
}

export function MetaDefault({ config }: Props) {
  const { organization } = useSelector((state: RootState) => state.app.organization);
  let pageName = '';

  if (typeof window !== 'undefined') {
    pageName = derivePageNameFromPath(window.location.pathname);
  }

  const stringsToInterpolate = {
    brandName: organization.label,
  };
  const seoTitle = interpolateIntoString(config.seo.title, stringsToInterpolate);
  const seoDescription = interpolateIntoString(config.seo.description, stringsToInterpolate);

  return (
    <Head>
      <title>{`${pageName}${seoTitle}`}</title>
      <meta name="description" content={seoDescription} key="description" />
      <meta property="og:description" content={seoDescription} key="og:description" />
      <meta name="twitter:description" content={seoDescription} key="twitter:description" />
      <meta property="og:title" content={`${pageName}${seoTitle}`} key="og:title" />
      <meta name="twitter:title" content={`${pageName}${seoTitle}`} key="twitter:title" />
      <meta property="og:image" content={config.seo.image} key="og:image" />
      <meta name="twitter:image" content={config.seo.image} key="twitter:image" />
    </Head>
  );
}
