import { type AnyAction } from 'redux';
import actions from './actions';
import { type RootState } from '@/types/app';
import { type IAuthState } from '@/types/auth';

export const initialState: IAuthState = {
  loading: false,
  params: null,
  error: false,
  guestUser: null,
  emailVerificationToggle: false,
};

export const auth = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SIGN_UP_REQUEST:
    case actions.LOGOUT:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.LOGIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        params: action.params,
      });
    case actions.AUTH_SUCCESS:
    case actions.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.AUTH_FAILURE:
    case actions.LOGOUT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.SIGN_UP_FAILURE:
      return Object.assign({}, state, {
        error: true,
        loading: false,
      });
    case actions.RESET_PASSWORD:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.RESET_PASSWORD_FAIL:
      return Object.assign({}, state, {
        loading: false,
        error: true,
      });
    case actions.CHECKOUT_AS_GUEST:
      return Object.assign({}, state, {
        loading: false,
        guestUser: action.payload,
      });
    case actions.TOGGLE_EMAIL_VERIFICATION:
      return Object.assign({}, state, {
        emailVerificationToggle: action.toggleEmailVerification,
        loading: false,
      });
    default:
      return state;
  }
};

export const authSliceSelector = (state: RootState) => state.app.auth;
