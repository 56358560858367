import { CONVEYANCE_TYPES, InvalidBasket, MenuGetError, sdkUtils, sdkStorage } from '@koala/sdk';
import {
  createBasketFromOrderId,
  removeBasketItem,
  addBasketItem,
  updateBasketItem,
  getMenu,
} from '@koala/sdk/v4';
import Router from 'next/router';
import { type Task } from 'redux-saga';
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  type SagaReturnType,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import globalActions from '../global/actions';
import { locationsActions } from '../locations/actions';
import meActions from '../me/actions';
import menuActions from '../menu/actions';
import actions from './actions';
// import { BASKET_ERROR, BASKET_MESSAGE } from './messages';
import { addProductEventsGoogle } from '@/analytics/commerce/google';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { BASKET_ORDER_KEYS, ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { ROUTES } from '@/constants/routes';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { type IBasketFulfillment } from '@/types/fulfillment';
import { getOrigin } from '@/utils';
import { patchProductOptions, removeInvalidItemsFromCart } from '@/utils/basket';
import { isBreakpointSizeMin } from '@/utils/breakpoints';
import { getDaysBetweenTwoDates, subtractTime } from '@/utils/dates';
import { fireGenericOrderEventsHandler } from '@/utils/events';
import { prepareErrorMessage } from '@/utils/global';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { getLocationId } from '@/utils/locations';

/**
 * Initialize and verify localStorage baskets to ensure it is in-sync with redux
 * and can work with a fresh menu and a fresh location
 */
function* initializeLocalStorageBasket() {
  const basket = sdkStorage.basket.get();

  const sevenDaysAgo = subtractTime(null, 7, 'days');

  // Destroy any basket that's existed for more than 7 days
  if (basket && getDaysBetweenTwoDates(basket.createdAt, sevenDaysAgo) < 0) {
    yield put(actions.destroyBasket());
    fireGaEvent(gaCats.global, gaActions.basketExpired);
    return;
  }

  // Handle existing non-stale baskets
  const basketLocationId = basket?.location?.id;
  if (basketLocationId) {
    // @TODO: abstract this into a function
    let wantedAt: string | undefined = undefined;

    if (basket.checkoutBasket?.wanted_at) {
      wantedAt = basket.checkoutBasket.wanted_at;
    } else if (basket?.fulfillment) {
      // @ts-expect-error: address is an object
      if (basket.fulfillment.address?.time_wanted) {
        // @ts-expect-error: address is an object
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        wantedAt = basket.fulfillment.address.time_wanted;
      } else if (basket.fulfillment?.time_wanted) {
        // @ts-expect-error: time_wanted is a string in this case
        wantedAt = basket.fulfillment.time_wanted;
      }
    }

    yield all([
      // 1. Sync the localStorage basket with redux to avoid showing an empty state
      put(actions.hydrateBasket()),
      // 2. Sync the localStorage menu with the basket menu in redux
      put(menuActions.hydrateBasketMenu()),
    ]);
    yield all([
      // 3. Fetch a fresh menu to refresh the localStorage basket and ensure all the items are valid
      //    TODO we could avoid re-fetching the menu if we could verify the localStorage menu is fresh
      //    but to do that we need to add a `createdAt` date to the localStorage menu first
      // @ts-expect-error
      put(menuActions.refreshBasketMenu(basketLocationId, wantedAt)),
      // 4. Fetch a fresh location to refresh the localStorage basket and ensure our location data is accurate
      // @ts-expect-error
      put(locationsActions.refreshBasketLocation(basketLocationId)),
    ]);
    // 5. finally, we sync with redux again to reflect the new the menu & location
    yield put(actions.hydrateBasket());
    // 6. Fire some analytics events
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.CART_PERSISTED);
    if ((basket.fulfillment as IBasketFulfillment)?.type) {
      fireGaEvent(gaCats.global, gaActions.basketFulfillmenSet, {
        label: (basket.fulfillment as IBasketFulfillment)?.type,
      });
    }
  }
}

//display toast banner when itens are added to basket (blocks other buttons)
// function* addItemToastSaga(action: ReturnType<typeof actions.addItemToast>) {
//   if (action.item) {
//     let msg =
//       action.item.quantity > 1
//         ? `${action.item.quantity} ${BASKET_MESSAGE.addItemsToastSaga}`
//         : BASKET_MESSAGE.addItemToastSaga;
//     if (typeof action.index === 'number') {
//       msg =
//         action.item.quantity > 1
//           ? `${action.item.quantity} ${BASKET_MESSAGE.updateItemsToastSaga}`
//           : BASKET_MESSAGE.updateItemToastSaga;
//     }

//     yield put(globalActions.displayToast(msg));
//   } else {
//     yield put(globalActions.displayErrorToast(BASKET_ERROR.addItemErrorToastSaga));

//     // KA event
//     fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
//       name: ERROR_MESSAGES.ADD_ITEM_TOAST_ERROR,
//     });
//   }
// }

export function* postAddItemSaga(action: ReturnType<typeof actions.addItem>) {
  const localBasket = sdkStorage.basket.get();
  const client = createHttpClient({ origin: getOrigin(window.location.host) });
  /**
   * If a remote basket has been created with the ordering provider,
   * sync the added/modified item with the remote basket.
   */
  if (localBasket.checkoutBasket?.id) {
    const { checkoutBasket, content } = localBasket;
    // If the item has an index, it's being modified instead of added.
    if (typeof action.index === 'number') {
      const res: SagaReturnType<typeof updateBasketItem> = yield call(
        // @ts-expect-error ensure that action.item.id is defined.
        updateBasketItem,
        {
          basketId: checkoutBasket.id,
          locationId: checkoutBasket.location.id,
          itemId: content.basket_items[action.index].id,
          item: patchProductOptions(action.item),
        },
        { client },
      );
      yield put(actions.success(res));
    } else {
      const res: SagaReturnType<typeof addBasketItem> = yield call(
        addBasketItem,
        {
          basketId: checkoutBasket.id,
          locationId: checkoutBasket.location.id,
          item: patchProductOptions(action.item),
        },
        { client },
      );
      yield all([
        put(actions.success(res)),
        put(actions.basketItemsSyncedWithStore(res.basket_items)),
      ]);
    }
  }
  try {
    // we disable toast notifications for products on mobile devices
    if (isBreakpointSizeMin('medium')) {
      yield put(actions.addItemToast(action.item, action.index ?? undefined));
    }
    yield put(actions.basketItemSuccess(true));
  } catch (e) {
    console.log(e);
  }
}

function* reorderDeliveryBasketSaga(action: ReturnType<typeof actions.reorderBasket>) {
  yield takeLatest(
    [globalActions.DELIVERY_ADDRESS_VALID, conveyanceModeActions.CLEAR_DELIVERY_ADDRESS],
    function* () {
      const state: RootState = yield select();
      const updatedFulfillment =
        state.app.conveyanceMode.address || state.app.basket.fulfillment?.address;

      // Wait for the user to click "Continue" before submitting the reorder.
      const { save } = yield race({
        save: take(conveyanceModeActions.SET_DELIVERY_ADDRESS_CONTINUE),
        cancel: take(globalActions.TOGGLE_FULFILLMENT_MODAL),
      });

      if (save) {
        yield put(
          actions.reorderBasketSubmit(
            action.order,
            action.label,
            updatedFulfillment,
            action.routeToCheckout,
            action.skipUnavailable,
          ),
        );
      }
    },
  );
}

function* reorderBasketSaga(action: ReturnType<typeof actions.reorderBasket>) {
  const state: RootState = yield select();
  try {
    // Determine fulfillment and cue req'd sagas (delivery reordering requires waiting for user input in fulfillment modal)
    if (action.order.order_data.basket.conveyance_type.type === 'delivery') {
      yield put(meActions.toggleReorderReceipt(false));

      yield put(
        globalActions.toggleFulfillmentModal(
          true,
          /** @TODO ensure that `store_location` is non-nullable. */
          // @ts-expect-error
          action.order.store_location,
          CONVEYANCE_TYPES.DELIVERY,
          false,
          null,
          true,
          false,
          true,
        ),
      );

      const deliveryReorderTask: Task = yield fork(reorderDeliveryBasketSaga, action);

      yield take([actions.REORDER_BASKET_SUCCESS, actions.REORDER_BASKET_FAILURE]);
      yield cancel(deliveryReorderTask); // Remove effect on reorder basket success or failure
    } else {
      yield put(
        actions.reorderBasketSubmit(
          action.order,
          action.label,
          /** @TODO allow for a nullable address. */
          // @ts-expect-error
          null,
          action.routeToCheckout,
          action.skipUnavailable,
        ),
      );
    }
  } catch (error) {
    switch (error) {
      case MenuGetError:
        (error as Error).message =
          'There was an error reordering this basket. Unable to retrieve the menu for this location.';
        break;
      case InvalidBasket:
        (error as Error).message =
          `We're sorry, none of the items in this purchase are currently available. Please view the menu to <a href="/store/${getLocationId(
            action.order.location,
            state.app.cmsConfig.webConfig,
          )}/${action.order.location.label}">start a new order</a>.`;
        break;
      default:
        break;
    }

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error reordering this basket.',
      error,
    );

    yield put(globalActions.displayErrorToast(errorResponse.message, true));
    yield put({ type: actions.REORDER_BASKET_FAILURE });
    fireKAnalyticsError(ERROR_MESSAGES.REORDER_ERROR, error, errorResponse);

    // TODO: Currently being used as an urgent generic reorder failure event. To be renamed later
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.REORDER_ERROR, {
      name: action.label,
      details: errorResponse?.error?.error_description,
    });
  }
}

function* reorderBasketSubmitSaga(action: ReturnType<typeof actions.reorderBasketSubmit>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const pastOrder: SagaReturnType<typeof createBasketFromOrderId> = yield call(
      createBasketFromOrderId,
      {
        orderId: action.order.id,
        locationId: action.order.store_location_id,
        skipUnavailable: action.skipUnavailable,
      },
      { client },
    );
    const [categories]: SagaReturnType<typeof getMenu> = yield call(
      getMenu,
      { id: pastOrder.location.id },
      { client },
    );

    const availableBasketItems = sdkUtils.filterAvailableItems(
      // @ts-expect-error
      pastOrder,
      categories,
    );

    // If some products are available and some are not, let user know that unavailable products have been removed and continue on
    if (availableBasketItems.length !== pastOrder.basket_items.length) {
      yield put(
        globalActions.displayErrorToast(
          'Some items are not currently available and have been removed from your basket.',
          false,
        ),
      );
    }

    // Initialize basket and fire KA event
    yield put(
      actions.initBasket(
        // @ts-expect-error
        action.order.store_location,
        categories,
        availableBasketItems,
        action?.address,
      ),
    );
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.FIRST_PRODUCT_ADDED, {
      name: K_ANALYTICS_EVENTS.REORDER,
      details: null,
    });
    fireGenericOrderEventsHandler(
      K_ANALYTICS_EVENTS.REORDER,
      /** @TODO reconcile special_instructions to ensure it's defined. */
      // @ts-expect-error
      action.order.order_data.basket,
      action.order,
      [BASKET_ORDER_KEYS.TRANSACTION, BASKET_ORDER_KEYS.ORDER, BASKET_ORDER_KEYS.BASKET_PRODUCTS],
      action.label,
    );

    yield put(actions.reorderBasketSuccess(action.order, action.routeToCheckout));
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error reordering this basket.',
      error,
    );

    if (errorResponse.error?.error === 'could_not_create_basket_items_unavailable') {
      yield put(meActions.toggleReorderReceipt(true));
      yield put(actions.reorderBasketReviewMissingItems());
    } else {
      yield put(meActions.toggleReorderReceipt(false));
      yield put(globalActions.displayErrorToast(errorResponse.message, true));
    }

    yield put({ type: actions.REORDER_BASKET_FAILURE });
    fireKAnalyticsError(ERROR_MESSAGES.REORDER_ERROR, error, errorResponse);

    // TODO: Currently being used as an urgent generic reorder failure event. To be renamed later
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.REORDER_ERROR, {
      name: action.label,
      details: errorResponse?.error?.error_description,
    });
  }
}

function* reorderBasketSuccessSaga(action: ReturnType<typeof actions.reorderBasketSuccess>) {
  const state: RootState = yield select();
  // Fire ecommerce add to cart events for each product in the basket resulting from the reorder
  (action.order?.order_data?.basket?.basket_items ?? []).forEach((item) =>
    addProductEventsGoogle(
      //@ts-expect-error TODO: update type of function
      item,
      action.order.location,
      state.app.menu.basketMenu,
      PRODUCT_LOCATION_LABELS.MENU,
    ),
  );

  // If we are required to route the user to checkout (ex: Order History reorder flow), route the user to checkout
  if (action.routeToCheckout) {
    void Router.push(ROUTES.CHECKOUT);
    return;
  }

  // If we are not on the store page, route the user to the store page with an open cart
  if (Router.pathname !== ROUTES.STORE) {
    void Router.push(
      `/store/${getLocationId(
        // @ts-expect-error ensure that `store_location` is non-nullable.
        action.order.store_location,
        state.app.cmsConfig.webConfig,
      )}?openCart=true`,
    );
    return;
  }

  // If we are on the store page, open the cart with no additional routing
  // TODO: I don't like messing with the UI here but I would rather not reload the page in this use case
  yield put(globalActions.toggleBasket(true));
  yield put(meActions.toggleReorderReceipt(false));
}

export function* removeItemSaga(action: ReturnType<typeof actions.removeItem>) {
  const localBasket = sdkStorage.basket.get();
  const client = createHttpClient({ origin: getOrigin(window.location.host) });
  /**
   * If a remote basket has been created with the ordering provider,
   * sync the added/modified item with the remote basket.
   */
  if (localBasket.checkoutBasket?.id) {
    const { checkoutBasket } = localBasket;
    const res: SagaReturnType<typeof removeBasketItem> = yield call(
      // @ts-expect-error ensure that action.item.id is defined.
      removeBasketItem,
      {
        basketId: checkoutBasket.id,
        locationId: checkoutBasket.location.id,
        itemId: action.item.id,
      },
      { client },
    );
    yield put(actions.success(res));
  }
}

export function* revalidateBasketItemsSaga(
  action: ReturnType<typeof actions.revalidateBasketItems>,
) {
  // Get current basket contents
  const localBasket = sdkStorage.basket.get();

  // Ensure all items in cart exists on a newly fetched menu
  const validatedItems = removeInvalidItemsFromCart(
    localBasket.content.basket_items,
    action.menuCategories,
  );

  // If the length is different, update the basket with only the valid items
  if (validatedItems.length !== localBasket.content.basket_items.length) {
    yield put(
      actions.revalidateBasketItemsSuccess({
        ...localBasket,
        content: {
          ...localBasket.content,
          basket_items: validatedItems,
        },
      }),
    );
  } else {
    yield put(actions.revalidateBasketItemsSuccess(localBasket));
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.INIT_LOCAL_STORAGE_BASKET, initializeLocalStorageBasket);
  yield takeLatest(actions.ADD_ITEM, postAddItemSaga);
  yield takeLatest(actions.REMOVE_ITEM, removeItemSaga);
  // yield takeLatest(actions.ADD_ITEM_TOAST, addItemToastSaga);
  yield takeLatest(actions.REORDER_BASKET, reorderBasketSaga);
  yield takeLatest(actions.REORDER_BASKET_SUBMIT, reorderBasketSubmitSaga);
  yield takeLatest(actions.REORDER_BASKET_SUCCESS, reorderBasketSuccessSaga);
  yield takeLatest(actions.REVALIDATE_BASKET_ITEMS, revalidateBasketItemsSaga);
}
