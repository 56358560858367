import { type WebConfig, fallbackConfig } from '@koala/sdk';
import { getWebConfig, type BearerToken } from '@koala/sdk/v4';
import { type QueryClient, useQuery } from '@tanstack/react-query';
import { useOrganization } from './organization';
import { queryKeys } from './queries';
import { createHttpClient, createHttpClientWithToken } from '@/services/client';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';

/**
 * A cache supported hook for retrieving the current brand's web configs
 * _powered by react query_
 *
 * @returns `Strings`
 */
export function useConfigs() {
  const { data: orgData } = useOrganization();

  /**
   * Make sure that `useFooterQuery` is prefetched before
   * this component renders, otherwise it will explode!
   * @see __DANGEROUS_EMPTY_REQUEST_ORIGIN
   */
  let origin: string;

  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const { data } = useQuery({
    queryKey: [queryKeys.webConfig],
    queryFn: () => getWebConfig({ client: createHttpClient({ origin }) }),
    enabled: Boolean(orgData?.organization?.id),
    refetchOnWindowFocus: false,
  });

  if (!data) {
    return fallbackConfig;
  }
  return data;
}

/**
 *
 * A cache supported hook for retrieving a slice of the current brand's web configs
 *
 * @param slice
 * @returns `WebConfig[T]`
 */
export function useConfigOverride<T extends keyof WebConfig>(slice: T): WebConfig[T] {
  const data = useConfigs();
  return data[slice];
}

/**
 * A cache supported function for retrieving the current brand's web configs
 * _powered by react query_
 *
 * @returns `Promise<WebConfig>`
 */
export async function getConfigs(
  queryClient: QueryClient,
  origin: string,
  token?: BearerToken | null,
): Promise<WebConfig> {
  // fetch the web config object
  const data = await queryClient.fetchQuery(
    [queryKeys.webConfig],
    () => getWebConfig({ client: createHttpClientWithToken({ origin, token }) }),
    { staleTime: 60000 },
  );

  return data;
}

/**
 * A cache supported function for retrieving a slice of the current brand's web configs
 *
 * @param slice
 * @returns `Promise<WebConfig[T]`
 */
export async function getConfigOverride<T extends keyof WebConfig>(
  slice: T,
  queryClient: QueryClient,
  origin: string,
): Promise<WebConfig[T]> {
  const data = await getConfigs(queryClient, origin);

  return data[slice];
}
