import { CONVEYANCE_TYPES } from '@koala/sdk';
import {
  forgotPassword,
  register,
  resetPassword,
  logout,
  validateLoyaltyToken,
} from '@koala/sdk/v4';
import Router from 'next/router';
import { call, put, takeLatest, select, type SagaReturnType } from 'redux-saga/effects';
import basketActions from '../basket/actions';
import conveyanceModeActions from '../conveyanceMode/actions';
import globalActions from '../global/actions';
import meActions from '../me/actions';
import orderStatusActions from '../orderStatus/actions';
import actions from './actions';
import { fireGACommerceLoginEvent } from '@/analytics/commerce/google';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import {
  CAPTCHA_CONFIG,
  DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD,
  EXTERNAL_AUTH_FEATURES,
} from '@/constants/authentication';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { ROUTES } from '@/constants/routes';
import {
  clearExternalCookie,
  getExternalCookie,
  isExternalUserTokenValid,
  login,
} from '@/services/auth.service';
import { createHttpClient, resetLoyaltyToken } from '@/services/client';
import { type RootState } from '@/types/app';
import { type IUserLogin } from '@/types/authentication';
import { getOrigin } from '@/utils';
import { isAndroidShell } from '@/utils/android';
import { addOptionalPushNotificationToken, getExternalAuthInfoFromState } from '@/utils/auth';
import { assembleDeliveryObjectFromState, getLocationFromState } from '@/utils/checkout';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

function* fetchUserSaga(action: ReturnType<typeof actions.login>) {
  try {
    /** @TODO reconcile `LoyaltyUserRegistrationInfo` and `IUserLogin`. */
    // @ts-expect-error
    const request = addOptionalPushNotificationToken(action.params);

    // Authenticate against the API
    const origin = getOrigin(window.location.host);

    yield call(login, request, origin);

    // Push to the url location that user tried to enter before being
    // authenticated do not do anything if a referrer is not set
    if (action.referrer) {
      /** @TODO ensure that `referrer` is not an array. */
      // @ts-expect-error
      void Router.push(action.referrer);
    }

    // // dispatch AUTH_SUCCESS
    yield put(actions.authSuccess());

    // Fetch User Details
    yield put(meActions.fetchMe());

    yield put(globalActions.generateFeatureBag());

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOGIN, {
      name: Router.pathname,
    });

    // Generic Events
    genericEventHandler(GlobalEvents.AUTH__SIGN_IN_SUCCESS);
    //@TODO: Surface the login SSO method - Apple, Facebook, Google, etc.
    fireGACommerceLoginEvent(action.params.flow_type === 'implicit' ? 'sso' : 'password');
  } catch (error) {
    // Error Notification
    // @todo this isn't of much use since the koala-sdk-js returns a static '401 Need to Refresh or Reauthenticate' message for 401 status.
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'Could not login: ',
      error,
    );
    // TEMP WORKAROUND FOR CAPTCHA LIMITATION
    if (!action.signUpChain) {
      yield put(globalActions.displayErrorToast(errorResponse.message, false));
    } else {
      // If the login call fails after an account has been created successfully,
      // push them to login. This is likely a recaptcha issue
      yield Router.push(ROUTES.LOGIN);
      yield put(globalActions.displayToast('Please login to continue.'));
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
        name: ERROR_MESSAGES.SIGNUP_LOGIN_FAILURE,
      });
    }
    yield put(actions.authFailure());
    yield put(meActions.clearMe());
    // Clear customer/guest data on our Koala Analytics class before logging in
    if (typeof window !== 'undefined') {
      window.KoalaAnalytics.setCustomerData(null);
    }
    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.LOGIN_FAILURE, error, errorResponse);
  }
}

function* createUserSaga(action: ReturnType<typeof actions.signUp>) {
  // Save the captcha response for the login request
  // Required because the API does not support sign up + login in the same request
  /** @TODO improve typing of `action.params`. */
  const loginCaptchaResponse = action.params[CAPTCHA_CONFIG.FIELD_NAME];
  delete action.params[CAPTCHA_CONFIG.FIELD_NAME];

  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const request = addOptionalPushNotificationToken(action.params);

    // Register the user
    yield call(register, request, { client });
    yield put(actions.signUpSuccess());

    const identifier = action.params.email;
    const secret = action.params.password;

    // Login User - global input keys
    const loginPayload: IUserLogin = {
      ...DEFAULT_PASSWORD_LOGIN_EXTRA_PAYLOAD,
      identifier,
      secret,
    };

    // Login User - create the payload with the organizations optional extra payload
    // Pass the captcha_response from sign up
    const payload = {
      ...loginPayload,
      [CAPTCHA_CONFIG.FIELD_NAME]: loginCaptchaResponse,
    };

    // Get external auth feature flags
    const { externalAuthFeatureFlags } = yield select(getExternalAuthInfoFromState);

    // If loyalty provider doesn't require email verification prior to initial login, call the Login User Action
    if (externalAuthFeatureFlags?.[EXTERNAL_AUTH_FEATURES.REQUIRES_EMAIL_VERIFICATION]) {
      yield put(actions.toggleEmailVerification(true));
    } else {
      yield put(actions.login(payload, action.referrer, true));
    }

    // Success Notification
    yield put(globalActions.displayToast('Account successfully created.'));

    // Generic Events
    genericEventHandler(GlobalEvents.AUTH__SIGN_UP_SUCCESS);

    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ACCOUNT_CREATED, {
      name: Router.pathname,
    });
  } catch (error) {
    // Error Notification
    yield put(actions.signUpFailure());

    yield put(actions.toggleEmailVerification(false));

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'Could not create account: ',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.CREATE_ACCOUNT_FAILURE, error, errorResponse);
  }
}

function* reloadUserSaga() {
  // When you reload the page the user appears logged out.
  // This sequence will restore the login state or ensure the
  // token is reset for a new user.
  const origin = getOrigin(window.location.host);
  const externalCookie = getExternalCookie();

  if (externalCookie && !isExternalUserTokenValid()) {
    try {
      const { cookie, provider } = externalCookie;
      const payload = {
        provider,
        flow_type: 'implicit',
        identifier: cookie,
        secret: cookie,
      };

      yield call(login, payload, origin);
    } catch (error) {
      fireKAnalyticsError(ERROR_MESSAGES.RESTART_USER_SESSION_FAILURE, error, {
        message: 'External cookie session restart failed',
        // @ts-expect-error
        error,
      });
    } finally {
      yield call(clearExternalCookie);
    }
  }

  if (validateLoyaltyToken(origin)) {
    try {
      // Fetch User Details
      yield put(meActions.fetchMe());
    } catch (error) {
      // Clear user
      yield put(meActions.clearMe());
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
        prepareErrorMessage,
        'It looks like there was an error restarting your user session.',
        error,
      );
      yield put(globalActions.displayErrorToast(errorResponse.message, true));

      // KA event
      fireKAnalyticsError(ERROR_MESSAGES.RESTART_USER_SESSION_FAILURE, error, errorResponse);
    }
  }
}

function* doLogoutSaga(action: ReturnType<typeof actions.logout>) {
  try {
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });

    yield call(logout, { client });
    yield resetLoyaltyToken({ origin });

    // Clear user
    yield put(meActions.clearMe());

    // Clear user specific data from cart
    yield put(basketActions.depersonalizeBasket());

    // Clear things like rewards from cart
    yield put(orderStatusActions.destroyOrder());

    const state: RootState = yield select();
    const conveyanceMode = state.app.conveyanceMode.type;

    // On logout, clear ID and default (user-only properties) from delivery address
    if (conveyanceMode === CONVEYANCE_TYPES.DELIVERY) {
      const deliveryAddress = assembleDeliveryObjectFromState(state);
      if (deliveryAddress?.id) {
        const location = getLocationFromState(state);
        const loggedOutAddress = Object.assign({}, deliveryAddress);
        delete loggedOutAddress.id;
        delete loggedOutAddress.default;

        /**
         * @TODO reconcile IConveyanceDeliveryAddress and
         * the SDK `DeliveryAddress` types.
         */
        yield put(
          conveyanceModeActions.setDeliveryAddress(
            // @ts-expect-error
            loggedOutAddress,
            location,
            false,
          ),
        );
      }
    }

    // Clear customer data on our Koala Analytics class
    if (typeof window !== 'undefined') {
      window.KoalaAnalytics.setCustomerData(null);
    }

    // Fire Generic Event
    genericEventHandler(GlobalEvents.AUTH__CLICK_LOGOUT, {
      name: Router.pathname,
    });

    // Clear basket data
    yield put(basketActions.destroyBasket());

    // Success
    yield put({ type: actions.LOGOUT_SUCCESS });

    // Push to homepage
    if (!action.cancelRedirect) {
      if (isAndroidShell()) {
        yield Router.push(ROUTES.ANDROID);
      } else {
        yield Router.push(ROUTES.HOMEPAGE);
      }
    }
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error logging you out. Please try again',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // Stop loading
    yield put({ type: actions.LOGOUT_FAILURE });

    // If error results from a stored address, record the address ID
    const state: RootState = yield select();
    const deliveryAddress = assembleDeliveryObjectFromState(state);
    let additionalDetails = '';
    if (deliveryAddress?.id) {
      additionalDetails = `Stored Address ID: ${deliveryAddress.id}`;
    }

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.LOGOUT_FAILURE, error, errorResponse, additionalDetails);
  }
}

function* forgotPasswordSaga(action: ReturnType<typeof actions.forgotPassword>) {
  // sends email
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(forgotPassword, action.email, { client });

    // Notify success
    yield put(
      globalActions.displayToast(
        'Please check your email for instructions on how to reset your password.',
      ),
    );
    // Stop loading state
    yield put(actions.forgotPasswordSuccess());
  } catch (error) {
    yield put(actions.forgotPasswordFail());
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'It looks like there was an error processing your request. ',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FORGOT_PASSWORD_FAILURE, error, errorResponse);
  }
}

function* resetPasswordSaga(action: any) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    yield call(resetPassword, action.params, { client });
    yield put({ type: actions.RESET_PASSWORD_SUCCESS });
    yield put(globalActions.displayToast('Your password has been successfully reset.'));
    yield Router.push(ROUTES.LOGIN);
  } catch (error) {
    yield put({ type: actions.RESET_PASSWORD_FAIL });
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'Failed to reset password. ',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.PASSWORD_RESET_FAILURE, error, errorResponse);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.RELOAD_USER, reloadUserSaga);
  yield takeLatest(actions.LOGIN_REQUEST, fetchUserSaga);
  yield takeLatest(actions.SIGN_UP_REQUEST, createUserSaga);
  yield takeLatest(actions.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(actions.RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(actions.LOGOUT, doLogoutSaga);
}
