import {
  type Meta,
  getLocation,
  getLocationByBrandId,
  type Location,
  getMenu,
} from '@koala/sdk/v4';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';

interface LocationDetailRequest {
  id: number;
  lastUpdatedAt?: string;
}

export function fetchLocation(
  { id, lastUpdatedAt }: LocationDetailRequest,
  { client }: Meta,
): Promise<{ data: Location }> {
  return getLocation(
    {
      id,
      meta: {
        include: ['operating_hours', 'attributes', 'delivery_hours', 'business_hours'],
      },
      ifModifiedSince: lastUpdatedAt,
    },
    { client },
  );
}

export function fetchLocationByBrandId(
  brand_id: string,
  { client }: Meta,
): Promise<{ data: Location }> {
  return getLocationByBrandId(
    {
      brand_id,
      meta: {
        include: ['operating_hours', 'attributes', 'delivery_hours', 'business_hours'],
      },
    },
    { client },
  );
}

interface MenuRequest {
  id: number;
  wantedAt?: string;
}

export function fetchMenu({ id, wantedAt }: MenuRequest) {
  const client = createHttpClient({
    origin: getOrigin(window.location.host),
  });

  return getMenu({ id, wantedAt }, { client });
}
