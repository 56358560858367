import {
  type Basket,
  type BasketOrder,
  type Category,
  type Location,
  type MenuProduct,
  type OrderDetails,
} from '@koala/sdk';

const commerceActions = {
  // Product Events
  COMMERCE_ITEM_IMPRESSION: '/koala/commerce/COMMERCE_ITEM_IMPRESSION',
  COMMERCE_VIEW_CATEGORY: '/koala/commerce/COMMERCE_VIEW_CATEGORY',
  COMMERCE_ITEM_CLICK: '/koala/commerce/COMMERCE_ITEM_CLICK',
  COMMERCE_ITEM_CROSS_SELL_CLICK: '/koala/commerce/COMMERCE_ITEM_CROSS_SELL_CLICK',

  // Checkout Events
  COMMERCE_CHECKOUT_BASKET_STAGED: '/koala/commerce/COMMERCE_CHECKOUT_BASKET_STAGED',
  COMMERCE_CHECKOUT_PURCHASE: '/koala/commerce/COMMERCE_CHECKOUT_PURCHASE',

  // Item Impression
  commerceItemImpression: (item: MenuProduct, categoryName: string, index: number) => ({
    type: commerceActions.COMMERCE_ITEM_IMPRESSION,
    item,
    categoryName,
    index,
  }),
  // View Category
  commerceCategoryView: (category: Category, location?: Location) => ({
    type: commerceActions.COMMERCE_VIEW_CATEGORY,
    category,
    location,
  }),
  // Item Click
  commerceItemClick: (item: MenuProduct, categoryName: string, index: number) => ({
    type: commerceActions.COMMERCE_ITEM_CLICK,
    item,
    categoryName,
    index,
  }),
  // Cross Sell Item Click
  commerceItemCrossSellClick: (item: MenuProduct, index: number) => ({
    type: commerceActions.COMMERCE_ITEM_CROSS_SELL_CLICK,
    item,
    index,
  }),
  // Checkout Basket Staged Event
  commerceCheckoutBasketStaged: (
    basketContent: BasketOrder,
    checkoutBasket: Basket,
    conveyanceType: string,
  ) => ({
    type: commerceActions.COMMERCE_CHECKOUT_BASKET_STAGED,
    basketContent,
    checkoutBasket,
    conveyanceType,
  }),
  // Checkout Complete Order Event
  commerceCheckoutPurchase: (orderDetails: OrderDetails) => ({
    type: commerceActions.COMMERCE_CHECKOUT_PURCHASE,
    orderDetails,
  }),
};

export default commerceActions;
