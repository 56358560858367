import { call, select, takeLatest } from 'redux-saga/effects';
import {
  addCategoryViewGoogle,
  addImpressionsGoogle,
  addProductEventsGoogle,
  clickProductEventsGoogle,
  fireGACommerceAddPaymentInfoEvent,
  fireGACommercePurchaseEvent,
  fireGACommerceStep,
  GOOGLE_COMMERCE_CHECKOUT_STEPS,
  removeProductEventsGoogle,
  viewProductEventsGoogle,
} from '@/analytics/commerce/google';
import {
  addProductEventsKoala,
  basketStagedEventsKoala,
  completeOrderEventsKoala,
  removeProductEventsKoala,
} from '@/analytics/commerce/koala';
import { pageViewEventHandler } from '@/analytics/events';
import { BranchAnalytics } from '@/analytics/integrations/branch';
import { FacebookAnalytics } from '@/analytics/integrations/facebook';
import { PRODUCT_LOCATION_LABELS } from '@/constants/checkout';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import basketActions from '@/redux/basket/actions';
import commerceActions from '@/redux/commerce/actions';
import customizeActions from '@/redux/customize/actions';
import orderStatusActions from '@/redux/orderStatus/actions';
import paymentActions from '@/redux/payment/actions';
import { type RootState } from '@/types/app';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

/**
 * Generic Commerce Error Event
 */
const fireCommerceError = (details: string) => {
  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
    name: ERROR_MESSAGES.ERROR_LOGGING_COMMERCE_EVENT,
    details,
  });
};

/**
 * Add Product Impressions
 */
function* commerceItemImpressionSaga(
  action: ReturnType<typeof commerceActions.commerceItemImpression>,
) {
  try {
    /**
     * Google Commerce
     */
    const state: RootState = yield select();
    const location = state.app.locations.detail;

    if (location) {
      yield call(addImpressionsGoogle, action.item, action.categoryName, location, action.index);
    }
  } catch (error) {
    fireCommerceError(`Set Impression - ${(error as Error).message}`);
  }
}

/**
 * View Category
 */
function* commerceViewCategorySaga(
  action: ReturnType<typeof commerceActions.commerceCategoryView>,
) {
  try {
    /**
     * Google Commerce
     */
    yield call(addCategoryViewGoogle, action.category, action.location);
  } catch (error) {
    fireCommerceError(`Set Impression - ${(error as Error).message}`);
  }
}

/**
 * Menu Item Clicks
 */
function* commerceItemClickSaga(action: ReturnType<typeof commerceActions.commerceItemClick>) {
  try {
    const state: RootState = yield select();

    /**
     * Google
     */
    yield call(
      clickProductEventsGoogle,
      action.item,
      action.categoryName,
      state.app.locations.detail ?? state.app.basket.location, //Prioritize the current location to correctly track when a user is clicking products in a location without transferring their basket
      PRODUCT_LOCATION_LABELS.MENU,
      action.index,
    );
  } catch (error) {
    fireCommerceError(`Click Menu Item - ${(error as Error).message}`);
  }
}

/**
 * Cross-sell Item Clicks
 */
function* commerceItemCrossSellClickSaga(
  action: ReturnType<typeof commerceActions.commerceItemCrossSellClick>,
) {
  try {
    const state: RootState = yield select();

    /**
     * Google
     */
    yield call(
      clickProductEventsGoogle,
      action.item,
      PRODUCT_LOCATION_LABELS.CROSS_SELL, //Use cross sell as category name
      state.app.locations.detail ?? state.app.basket.location, //Prioritize the current location to correctly track when a user is clicking products in a location without transferring their basket
      PRODUCT_LOCATION_LABELS.CROSS_SELL,
      action.index,
    );
  } catch (error) {
    fireCommerceError(`Click Cross-Sell Item - ${(error as Error).message}`);
  }
}

/**
 * View Item Detail
 */
function* commerceItemViewSaga(action: ReturnType<typeof customizeActions.setProduct>) {
  try {
    const state: RootState = yield select();
    const { product, label } = action.payload;

    /**
     * Google
     */
    // User use basket location if we're viewing a basket item, otherwise use menu location
    yield call(
      viewProductEventsGoogle,
      product,
      // Prioritize the current location to correctly track when a user is clicking products in a location without transferring their basket
      state.app.locations.detail ?? state.app.basket.location,
      label,
      action.payload.menuCategories,
    );

    /**
     * Page Views
     */
    yield call(pageViewEventHandler);
  } catch (error) {
    fireCommerceError(`View Item - ${(error as Error).message}`);
  }
}

/**
 * Add Item to Basket
 */
function* commerceItemAddSaga(action: ReturnType<typeof basketActions.addItem>) {
  try {
    const state: RootState = yield select();
    const { content, location } = state.app.basket;
    const basketMenu = state.app.menu.basketMenu;

    /**
     * Google
     */
    yield call(addProductEventsGoogle, action.item, location, basketMenu, action.label);

    /**
     * Branch
     */
    yield call(BranchAnalytics.addProduct, action.item, location);

    /**
     * Koala
     */
    yield call(addProductEventsKoala, content);
  } catch (error) {
    fireCommerceError(`Add Item - ${(error as Error).message}`);
  }
}

/**
 * Remove Item from
 */
function* commerceItemRemoveSaga(action: ReturnType<typeof basketActions.removeItem>) {
  try {
    const state: RootState = yield select();
    const { content, location } = state.app.basket;
    const basketMenu = state.app.menu.basketMenu;

    /**
     * Google
     */
    yield call(removeProductEventsGoogle, action.item, location, basketMenu);

    /**
     * Koala
     */
    yield call(removeProductEventsKoala, content);
  } catch (error) {
    fireCommerceError(`Remove Item - ${(error as Error).message}`);
  }
}

/**
 * Checkout Step Two: User Details Set
 */
function* checkoutStepTwoSaga() {
  try {
    /**
     * Google
     */
    const state: RootState = yield select();
    const userType = state.app.me.data?.id ? 'User' : 'Guest';
    yield call(fireGACommerceStep, 2, userType);
  } catch (error) {
    fireCommerceError(`${GOOGLE_COMMERCE_CHECKOUT_STEPS[2]} - ${(error as Error).message}`);
  }
}

/**
 * Checkout Step Three: Basket Staged
 */
function* checkoutStepThreeSaga(
  action: ReturnType<typeof commerceActions.commerceCheckoutBasketStaged>,
) {
  try {
    /**
     * Google Commerce
     */
    yield call(fireGACommerceStep, 3);

    /**
     * Koala
     */
    yield call(
      basketStagedEventsKoala,
      action.basketContent,
      action.checkoutBasket,
      action.conveyanceType,
    );
  } catch (error) {
    fireCommerceError(`${GOOGLE_COMMERCE_CHECKOUT_STEPS[3]} - ${(error as Error).message}`);
  }
}

/**
 * Checkout Step Four: Payment Set
 */
function* checkoutStepFourSaga() {
  try {
    /**
     * Google
     */
    const state: RootState = yield select();

    if (state.app.payment?.paymentType?.type) {
      yield call(fireGACommerceStep, 4, state.app.payment.paymentType.type);
      yield call(
        fireGACommerceAddPaymentInfoEvent,
        state.app.basket,
        state.app.menu.basketMenu,
        state.app.payment.paymentType.type,
        state.app.promoCode.applied,
      );
    }

    /**
     * Branch
     */
    yield call(BranchAnalytics.paymentSet);
  } catch (error) {
    fireCommerceError(`${GOOGLE_COMMERCE_CHECKOUT_STEPS[4]} - ${(error as Error).message}`);
  }
}

/**
 * Checkout Step Five: Order Submission Staged
 */
function* checkoutStepFiveSaga() {
  /**
   * Google
   */
  yield call(fireGACommerceStep, 5);
}

/**
 * Checkout Step Six: Order Receipt Received
 */
function* checkoutStepSixSaga(action: ReturnType<typeof commerceActions.commerceCheckoutPurchase>) {
  const state: RootState = yield select();

  // Each service below uses its own try/catch to isolate errors and
  // ensure that an analytics failure in one service does not block the others

  /**
   * Google
   */
  try {
    yield call(fireGACommerceStep, 6);
    yield call(
      fireGACommercePurchaseEvent,
      action.orderDetails,
      state.app.basket,
      state.app.menu.basketMenu,
      state.app.promoCode.applied,
    );
  } catch (error) {
    fireCommerceError(
      `[Google] ${GOOGLE_COMMERCE_CHECKOUT_STEPS[6]} - ${(error as Error).message}`,
    );
  }

  /**
   * Koala
   */
  try {
    yield call(completeOrderEventsKoala, state, action.orderDetails);
  } catch (error) {
    fireCommerceError(`[Koala] ${GOOGLE_COMMERCE_CHECKOUT_STEPS[6]} - ${(error as Error).message}`);
  }

  /**
   * Facebook
   */
  try {
    yield call(FacebookAnalytics.purchase, action.orderDetails);
  } catch (error) {
    fireCommerceError(
      `[Facebook] ${GOOGLE_COMMERCE_CHECKOUT_STEPS[6]} - ${(error as Error).message}`,
    );
  }

  /**
   * Branch
   */
  try {
    yield call(BranchAnalytics.purchase, action.orderDetails, state);
  } catch (error) {
    fireCommerceError(
      `[Branch] ${GOOGLE_COMMERCE_CHECKOUT_STEPS[6]} - ${(error as Error).message}`,
    );
  }
}

export default function* rootSaga() {
  // Product Events
  yield takeLatest(commerceActions.COMMERCE_ITEM_IMPRESSION, commerceItemImpressionSaga);
  yield takeLatest(commerceActions.COMMERCE_VIEW_CATEGORY, commerceViewCategorySaga);
  yield takeLatest(commerceActions.COMMERCE_ITEM_CLICK, commerceItemClickSaga);
  yield takeLatest(commerceActions.COMMERCE_ITEM_CROSS_SELL_CLICK, commerceItemCrossSellClickSaga);
  yield takeLatest(customizeActions.SET_PRODUCT_TO_CUSTOMIZE, commerceItemViewSaga);
  yield takeLatest(basketActions.ADD_ITEM, commerceItemAddSaga);
  yield takeLatest(basketActions.REMOVE_ITEM, commerceItemRemoveSaga);

  // Checkout Events
  yield takeLatest(orderStatusActions.INITIALIZE_ORDER, checkoutStepTwoSaga);
  yield takeLatest(commerceActions.COMMERCE_CHECKOUT_BASKET_STAGED, checkoutStepThreeSaga);
  yield takeLatest(paymentActions.BILLING_ACCOUNT_SET, checkoutStepFourSaga);
  yield takeLatest(paymentActions.CREDIT_CARD_SET, checkoutStepFourSaga);
  yield takeLatest(paymentActions.PAY_IN_STORE_SET, checkoutStepFourSaga);
  yield takeLatest(orderStatusActions.ORDER_SUBMISSION_START, checkoutStepFiveSaga);
  yield takeLatest(commerceActions.COMMERCE_CHECKOUT_PURCHASE, checkoutStepSixSaga);
}
