import type * as Stitches from '@stitches/react';
import { Box } from '../box';
import { Base, Field } from './base';
import { useConfigOverride } from '@/features/configs/configs';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface Props extends React.HTMLAttributes<HTMLSelectElement> {
  label: string;
  name: string;
  children: React.ReactNode;
  required?: boolean;
  error?: string;
  css?: Stitches.CSS;
}

function Icon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M19.5 9L12 16.5L4.5 9" fill="none" stroke="currentColor" />
    </svg>
  );
}

export function Select({ label, name, required = false, error, children, ...otherProps }: Props) {
  const baseProps = { label, name, required, error };
  const { background_color, border_color, border_radius, font } = useConfigOverride('forms');

  return (
    <Base {...baseProps}>
      <Field
        as="select"
        id={name}
        required={required}
        aria-label={label}
        css={{
          backgroundColor: background_color ?? 'transparent',
          borderColor: border_color ?? '$shade300',
          borderRadius: `${Number(border_radius)}px`,
          ...getFontStyles(font, ['color', 'font_family', 'font_weight', 'font_style']),
        }}
        {...otherProps}
      >
        {children}
      </Field>

      <Box
        css={{
          display: 'flex',
          position: 'absolute',
          right: '$4',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 10,
        }}
      >
        <Icon />
      </Box>
    </Base>
  );
}
