import { getLocation } from '@koala/sdk/v4';
import { useQuery } from '@tanstack/react-query';
import { createHttpClient } from '@/services/client';
import { __DANGEROUS_EMPTY_REQUEST_ORIGIN, getOrigin } from '@/utils';

export function useLocation(id: number | undefined) {
  let origin: string;

  if (typeof window !== 'undefined') {
    origin = getOrigin(window.location.host);
  } else {
    origin = __DANGEROUS_EMPTY_REQUEST_ORIGIN;
  }

  const client = createHttpClient({ origin });
  const locationId = id ?? 0;

  return useQuery({
    queryKey: ['location', { id: locationId }],
    queryFn: () =>
      getLocation(
        {
          id: locationId,
          meta: {
            include: ['operating_hours', 'attributes', 'delivery_hours', 'business_hours'],
          },
        },
        { client },
      ),
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
    staleTime: 3600000, // 60 minutes
  });
}
