import { type WebConfig } from '@koala/sdk';

const androidFonts = [
  'Merriweather',
  'Playfair Display',
  'Libre Baskeville',
  'Roboto Slab',
  'Patrick Hand',
  'Indie Flower',
  'Roboto',
  'Open Sans',
  'Lato',
  'Montserrat',
  'Oswald',
  'Source Sans Pro',
  'Raleway',
  'Nunito Sans',
  'Work Sans',
  'Inter',
  'Poppins',
  'Barlow',
];

// function checkConfigForFonts(property: object | string | number) {
//   if (typeof property === "object") {
//     Object.values(property).flatMap((subProperty) =>
//       checkConfigForFonts(subProperty)
//     );
//   } else {
//     return androidFonts.filter((font) => String(property).includes(font));
//   }
// }

type NormalizedFonts = Record<
  string,
  {
    weights: string[];
  }
>;

interface Font {
  family: string;
  weight: string;
}

export function getSafeFontName(font: string) {
  return font.replace(' ', '+');
}

export function buildStringFromWeights(weights: string[]) {
  const sortedWeights = weights.sort();
  const nonItalicWeights = sortedWeights.map((value) => `0,${value}`).join(';');
  const italicWeights = sortedWeights.map((value) => `1,${value}`).join(';');

  return `${nonItalicWeights};${italicWeights}`;
}

export function buildStringFromFonts(fonts: Font[]) {
  let normalizedFonts: NormalizedFonts = {};

  fonts.forEach((font) => {
    if (font.family in normalizedFonts) {
      normalizedFonts = {
        ...normalizedFonts,
        [font.family]: {
          weights: normalizedFonts[font.family].weights.concat([font.weight]),
        },
      };
    } else {
      normalizedFonts = {
        ...normalizedFonts,
        [font.family]: {
          weights: [font.weight],
        },
      };
    }
  });

  return Object.entries(normalizedFonts)
    .map(
      ([fontName, font]) =>
        `family=${getSafeFontName(fontName)}:ital,wght@${buildStringFromWeights(font.weights)}`,
    )
    .join('&');
}

export function getGoogleFontsCssUrl(webConfig: WebConfig) {
  let matchingFonts: Font[] = [];

  let key: keyof WebConfig;
  for (key in webConfig) {
    const properties = webConfig[key];

    for (const subKey in properties) {
      // @ts-expect-error: see next line
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const subProperties = properties[subKey];

      if (subProperties && typeof subProperties === 'object') {
        if (
          'font_family' in subProperties &&
          'font_weight' in subProperties &&
          'font_style' in subProperties
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const family = String(subProperties.font_family)
            .replace(/\,( )?(sans-)?serif/g, '')
            .replace(/"/g, '');
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
          const weight = String(subProperties.font_weight);

          if (androidFonts.includes(family)) {
            matchingFonts = matchingFonts.concat([
              {
                family,
                weight,
              },
            ]);
          }
        }
      }
    }
  }

  matchingFonts = matchingFonts
    .map((font) => JSON.stringify(font))
    .filter((value, index, array) => array.indexOf(value) === index)
    .map((font) => JSON.parse(font) as Font);

  if (matchingFonts.length) {
    const fontFamilies = buildStringFromFonts(matchingFonts);

    return `https://fonts.googleapis.com/css2?${fontFamilies}&display=swap`;
  }
}

// WARNING: This function can only be used on the client side
// In our case, we are calling it in a `useEffect` within the `_app.tsx` file: apps/web-ordering/pages/_app.tsx
export function maybeReloadGoogleFonts(url: string) {
  const stylesheet = document.getElementById('google-font-url');

  // google fonts are not always used
  if (!stylesheet) {
    return;
  }

  // if the url is the same, we don't need to do anything
  if (stylesheet.getAttribute('href') === url) {
    return;
  }

  stylesheet.setAttribute('href', url);
}
