import {
  type Location,
  type BasketItem,
  type MenuProduct,
  type TagItem,
  sdkStorage,
} from '@koala/sdk';
import get from 'lodash/get';
import { nanoid } from 'nanoid';
import {
  type IGlobalEventContext,
  type IGlobalEvents,
  type GlobalEvents,
} from '@/analytics/events/constants';
import { BUILD_VERSION, ENV } from '@/constants/envConfig';
import { K_ANALYTICS_EVENTS, K_ANALYTICS_PLATFORMS, LOG_EVENTS } from '@/constants/events';
import { SESSION_STORAGE_KEYS } from '@/constants/storageKeys';
import { fireKoalaAnalyticsEvent } from '@/services/analytics.service';
import {
  type IEventDetails,
  type IKARequest,
  type IKARequestCustomerData,
  type IKARequestEventData,
  type IKARequestOrganizationData,
  type IKARequestSourceData,
} from '@/types/analytics';
import { type IPreparedErrorResponse } from '@/types/errors';
import { isAndroidShell } from '@/utils/android';
import { deriveErrorMessage, type PreparedErrorMessage } from '@/utils/global';

// Generic Events
const KoalaEvents: IGlobalEvents = {
  LOCATIONS__VIEW_ALL_LOCATIONS: {
    eventName: K_ANALYTICS_EVENTS.VIEW_ALL_LOCATIONS,
  },
  LOCATIONS__SEARCH_LOCATIONS: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_SEARCH,
  },
  LOCATIONS__TOGGLE_MOBILE_DISPLAY: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_VIEW_TOGGLE,
  },
  LOCATIONS__TRIGGER_DELIVERY_MODAL: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_CTA,
  },
  LOCATIONS__PRIMARY_LOCATION_CTA: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_CTA,
  },
  LOCATIONS__FAVORITE_LOCATION_CTA: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_FAVORITE_CTA,
  },
  LOCATIONS__LOCATION_SEARCH_NOT_FOUND: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_SEARCH_NOT_FOUND,
  },
  LOCATION_RECOMMENDATIONS_SURFACED: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_RECOMMENDATIONS_SURFACED,
  },
  GENERIC__CTA: {
    eventName: K_ANALYTICS_EVENTS.CTA,
  },
  AUTH__CLICK_LOGOUT: {
    eventName: K_ANALYTICS_EVENTS.LOGOUT,
  },
  ACCOUNT__ADD_FAVORITE_LOCATION: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_FAVORITED,
  },
  ACCOUNT__REMOVE_FAVORITE_LOCATION: {
    eventName: K_ANALYTICS_EVENTS.LOCATION_UNFAVORITED,
  },
  ACCOUNT__STORED_ADDRESS_DELETED: {
    eventName: K_ANALYTICS_EVENTS.STORED_ADDRESS_DELETED,
  },
  STORE__MENU_VIEW: {
    eventName: K_ANALYTICS_EVENTS.MENU_VIEW,
  },
  STORE__CART_VIEW: {
    eventName: K_ANALYTICS_EVENTS.CART_VIEW,
  },
  STORE__PRODUCT_VIEW: {
    eventName: K_ANALYTICS_EVENTS.PRODUCT_VIEW,
  },
  STORE__BASKET_RECONCILIATION: {
    eventName: K_ANALYTICS_EVENTS.BASKET_RECONCILIATION,
  },
  CHECKOUT__CHECKOUT_VIEW: {
    eventName: K_ANALYTICS_EVENTS.CHECKOUT_VIEW,
  },
  ARRIVAL__CONFIRM_ARRIVAL_SUCCESS: {
    eventName: K_ANALYTICS_EVENTS.CONFIRM_ARRIVAL_SUCCESS,
  },
  BASKET__CUSTOMIZED: {
    eventName: K_ANALYTICS_EVENTS.BASKET_CUSTOMIZED,
  },
  DELIVERY__TOGGLE: {
    eventName: K_ANALYTICS_EVENTS.DELIVERY_TOGGLE,
  },
  VIEW__REWARDS: {
    eventName: K_ANALYTICS_EVENTS.VIEW_REWARDS,
  },
  VIEW__ORDER_HISTORY: {
    eventName: K_ANALYTICS_EVENTS.VIEW_ORDER_HISTORY,
  },
  VIEW__FAVORITES: {
    eventName: K_ANALYTICS_EVENTS.VIEW_FAVORITES,
  },
  VIEW__STORE_LOCATOR: {
    eventName: K_ANALYTICS_EVENTS.VIEW_STORE_LOCATOR,
  },
  VIEW__INBOX: {
    eventName: K_ANALYTICS_EVENTS.VIEW_INBOX,
  },
  VIEW__DISPATCH_TRACKING: {
    eventName: K_ANALYTICS_EVENTS.VIEW_DISPATCH_TRACKING,
  },
  WANTED_AT_SELECTED: {
    eventName: K_ANALYTICS_EVENTS.WANTED_AT_SELECTED,
  },
};

/**
 * Koala
 */
export class GenericKoalaAnalyticsWrapper {
  /**
   * Page View Event
   */
  static pageView(path: string) {
    if (window.KoalaAnalytics) {
      window.KoalaAnalytics.fireScreenEvent(path);
    }
  }

  /**
   * Generic Events
   */
  static genericEvent(globalEvent: GlobalEvents, context: IGlobalEventContext) {
    if (!KoalaEvents[globalEvent]) {
      return;
    }

    /** @TODO ensure that the event name is defined. */
    // @ts-expect-error
    fireKAnalyticsEvent(KoalaEvents[globalEvent].eventName, {
      name: context.name,
      details: context.details,
    });
  }
}

/**
 * Initialize Koala Analytics, create a new user session ID,
 * and add KA to the window object
 *
 * @returns void
 */
export const initializeKoalaAnalytics = (orgId: number, brandName: string) => {
  if (typeof window !== 'undefined' && !window.KoalaAnalytics) {
    let sessionId = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_ID);
    let sessionStart = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.SESSION_START);

    const newSession = !sessionId;

    // If no session exists, create one
    if (newSession) {
      sessionId = nanoid();
      sessionStart = new Date().toISOString();
      initializeAppSession(sessionId, sessionStart);
    }

    // Ensure the KoalaAnalytics class is always created on app load
    window.KoalaAnalytics = new KoalaAnalytics(
      /** @TODO rework control flow so TS knows that `sessionId` is defined. */
      // @ts-expect-error
      sessionId,
      sessionStart,
      orgId,
      brandName,
    );
    if (newSession) {
      window.KoalaAnalytics.fireSessionStartEvent();
    } else {
      // If a session exists, but Koala analytics hasn't been instantiated
      // it likely means the user has refreshed the page
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.LOG, {
        name: LOG_EVENTS.SESSION_PERSISTED,
      });
    }

    // Consolidated pageview events
    GenericKoalaAnalyticsWrapper.pageView(window.location.pathname);
  }
};

/**
 * Initialize the application session with a supplied session ID
 *
 * @param sessionId string
 * @param sessionStart string
 * @returns void
 */
const initializeAppSession = (sessionId: string, sessionStart: string): void => {
  // Set the session id
  window.sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_ID, sessionId);
  window.sessionStorage.setItem(SESSION_STORAGE_KEYS.SESSION_START, sessionStart);
};

/**
 * safelyAccessClass
 *
 */
const safelyAccessClass = () => {
  if (typeof window === 'undefined') {
    console.warn('Window is not defined');
    return false;
  }

  if (!window.KoalaAnalytics) {
    console.warn('KoalaAnalytics is not defined');
    return false;
  }

  return true;
};

/**
 * coerceBasketProducts
 *
 */
export const coerceBasketProductsForKA = (basketProducts: BasketItem[]) => {
  return basketProducts.map((basketItem: BasketItem) => ({
    id: basketItem.product.id,
    global_id: basketItem.product.global_id,
    name: basketItem.product.name,
    quantity: basketItem.quantity,
    cost: basketItem.product.cost,
    cross_sell_id: basketItem.product.cross_sell_id || null,
    selected_options: basketItem.options.map((option) => ({
      id: option.id,
      global_id: option.global_option_id,
      total_cost_cents: option.cost,
      quantity: option.quantity,
      name: option.name,
    })),
    category_id: basketItem.product.category_id,
    category_label: basketItem.product.category_label,
    filter_tags: basketItem.product.filter_tags?.map((tag: TagItem) => ({
      id: String(tag?.id),
      label: tag?.label,
    })),
  }));
};

/**
 * Formats KA error object for `ERROR` event
 * @TODO improve `error` argument typing.
 */
const prepareKAErrorObject = (error: any, errorResponse: IPreparedErrorResponse) => {
  const errorType = errorResponse?.error?.error || null;
  const errorDescription = deriveErrorMessage(errorResponse?.error) || error?.message;
  const koalaRequestId = error?.headers?.map?.['x-request-id'] || null;

  return {
    type: errorType,
    message: errorDescription,
    koala_request_id: koalaRequestId,
    status_code: error.status,
  };
};

/*
 * Fires the ERROR event with an error object
 */
export const fireKAnalyticsError = (
  message: string,
  error: unknown,
  response: PreparedErrorMessage,
  additionalDetails?: string,
) => {
  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
    name: message,
    error: prepareKAErrorObject(error, response),
    details: additionalDetails,
  });
};

/**
 * fireKAnalyticsEvent
 *
 */
export const fireKAnalyticsEvent = (
  type: string,
  eventDetails?: IEventDetails,
  additionalData?: object,
  additionalEventData?: object,
  breadcrumbs?: string[],
) => {
  if (safelyAccessClass()) {
    const newEventDetails = Object.assign({}, eventDetails, {
      name: get(eventDetails, 'name', type),
      details: get(eventDetails, 'details', null),
    });
    window.KoalaAnalytics.fireEvent(
      type,
      newEventDetails,
      additionalData,
      additionalEventData,
      breadcrumbs,
    );
  }
};

interface DisplayedUpsell {
  action: string;
  product: {
    id: string;
    global_id: string;
    name: string;
  };
}

/**
 * fireKoalaUpsellEvent
 *
 */
export const fireKAnalyticsUpsellEvent = (upsellId: string, upsellProducts: MenuProduct[]) => {
  if (!upsellProducts || !upsellProducts.length) {
    return;
  }

  // Assemble upsells presented array
  const displayedUpsells: DisplayedUpsell[] = [];
  upsellProducts.forEach((upsellOffer) => {
    displayedUpsells.push({
      action: 'display',
      product: {
        id: upsellOffer.id,
        global_id: upsellOffer.global_id,
        name: upsellOffer.name,
      },
    });
  });

  if (displayedUpsells.length) {
    // Grab global IDs from displayedUpsells
    const upsellGlobalIDsArray: string[] = [];
    displayedUpsells.map((entry) => upsellGlobalIDsArray.push(entry.product.global_id));

    // Join array into a string
    const upsellGlobalIDsString = upsellGlobalIDsArray.join(', ');

    // KA Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.CROSS_SELL_PRESENTED, {
      name: upsellId,
      details: upsellGlobalIDsString,
    });
  }
};

/**
 * Koala Analytics
 */
class KoalaAnalytics {
  menuProductOptions: object | null;

  private sessionId: string;
  private sessionStart: string;
  private platform: K_ANALYTICS_PLATFORMS;
  private env: string;
  private name: string;
  private orgId: number;
  private orgLabel: string;
  private customerId: number | null;
  private customerFirstName: string | null;
  private customerLastName: string | null;
  private customerPhone: string | null;
  private customerEmail: string | null;
  private customerOptIn: boolean | null;
  private userAgent: string;
  private debugBreadcrumbs: string[];

  /**
   * Constructor, starts the storage implementation.
   */
  constructor(sessionId: string, sessionStart: string, orgId: number, brandName: string) {
    this.sessionId = sessionId;
    this.sessionStart = sessionStart;
    this.env = ENV;
    this.orgId = orgId;
    this.orgLabel = brandName;
    this.userAgent = window.navigator.userAgent;
    this.name = this.detectName();
    this.platform = this.detectPlatform();

    // Instantiate customer data
    this.customerId = null;
    this.customerFirstName = null;
    this.customerLastName = null;
    this.customerPhone = null;
    this.customerEmail = null;
    this.customerOptIn = null;

    // Instantiate menu product options
    this.menuProductOptions = null;

    // Instantiate breadcrumbs
    this.debugBreadcrumbs = [];
  }

  fireSessionStartEvent = () => {
    // Fire the session start event
    this.fireEvent(K_ANALYTICS_EVENTS.SESSION_START, {
      name: window.location.pathname,
      details: null,
    });
  };

  fireScreenEvent = (name: string) => this.fireEvent(K_ANALYTICS_EVENTS.SCREEN, { name });

  /*
   * Set customer data as soon as we get it
   */
  setCustomerData = (customerData: IKARequestCustomerData) => {
    // If we have customer data, set it
    if (customerData) {
      const { id, first_name, last_name, phone, email, opt_in } = customerData;

      this.customerId = id;
      this.customerFirstName = first_name;
      this.customerLastName = last_name;
      this.customerPhone = phone;
      this.customerEmail = email;
      this.customerOptIn = opt_in;
    } else {
      // else clear customer data
      this.customerId = null;
      this.customerFirstName = null;
      this.customerLastName = null;
      this.customerPhone = null;
      this.customerEmail = null;
      this.customerOptIn = null;
    }
  };

  /**
   * Set menu product options whenever we fetch a menu
   * @TODO improve typing of `optionsObject`.
   */
  setMenuProductOptions = (optionsObject: Record<string, any>) =>
    (this.menuProductOptions = optionsObject);

  /*
   * Push breadcrumbs into the analytics class
   */
  pushDebugBreadcrumb = (breadcrumb: string) => this.debugBreadcrumbs.push(breadcrumb);

  /**
   * retrieveLocation
   *
   */
  retrieveLocation() {
    // Attempt to retrieve the location id from the current order, if one exists
    // We don't write this to the class in case a user changes their order location
    const koalaOrder = sdkStorage.basket.get();

    if (koalaOrder?.location) {
      return {
        id: (koalaOrder.location as Location)?.id?.toString(),
        label: (koalaOrder.location as Location)?.label,
      };
    }

    return null;
  }

  /**
   * fireEvent
   *
   */
  fireEvent(
    type: string,
    eventDetails: IEventDetails,
    additionalData?: object,
    additionalEventData?: object,
  ) {
    // Derive location id
    const locationId = get(this.retrieveLocation(), 'id', null);
    const locationLabel = get(this.retrieveLocation(), 'label', null);

    // Set base event object
    const eventData: IKARequestEventData = {
      type,
      data: eventDetails,

      // Pass debug_breadcrumbs on error events
      debug_breadcrumbs: type === K_ANALYTICS_EVENTS.ERROR ? this.debugBreadcrumbs : [],
    };

    // Set organization data
    const organizationData: IKARequestOrganizationData = {
      label: this.orgLabel,
      id: this.orgId,
    };

    // Set customer details
    const customerData: IKARequestCustomerData = {
      id: this.customerId,
      first_name: this.customerFirstName,
      last_name: this.customerLastName,
      phone: this.customerPhone,
      email: this.customerEmail,
      opt_in: this.customerOptIn,
    };

    // Set source data
    const sourceData: IKARequestSourceData = {
      id: this.userAgent,
      name: this.name,
      session: {
        started_at: this.sessionStart,
        id: this.sessionId,
        referrer: document?.referrer,
      },
      type: this.platform,
      location_id: locationId,
      location_label: locationLabel,
      build_version: BUILD_VERSION,
      env: this.env,
    };

    // Set base data object
    const data = {
      date: new Date().toISOString(),
      event: Object.assign({}, eventData, {
        ...additionalEventData,
      }),
      organization: organizationData,
      customer: customerData,
      source: sourceData,
    };

    // Assemble final event payload
    const koalaEvent: IKARequest = {
      data: [
        Object.assign({}, data, {
          ...additionalData,
        }),
      ],
    };

    fireKoalaAnalyticsEvent(koalaEvent).catch(console.log);
  }

  private detectName = (): string => {
    return isAndroidShell() ? 'koala-ordering-android-shell' : 'koala-ordering-webapp';
  };

  private detectPlatform = (): K_ANALYTICS_PLATFORMS => {
    // Detect if we are running inside an Android app as a web-view
    if (isAndroidShell()) {
      return K_ANALYTICS_PLATFORMS.ANDROID;
    }

    // the original mobile-detect implemenation's `isPhoneSized` method checked if the window was less than 600px
    // so we do the same, but with a css media query
    return window.matchMedia('(max-width: 600px)').matches
      ? K_ANALYTICS_PLATFORMS.MOBILE_WEB
      : K_ANALYTICS_PLATFORMS.DESKTOP_WEB;
  };
}

export default KoalaAnalytics;
