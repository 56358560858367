import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { DeliveryDetails } from '@/features/menu-availability/delivery-details';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface GFOModalProps {
  onClose: () => void;
}

const ModalBackground = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
`;

const SwitchButton = styled.button(({ theme }) => ({
  marginTop: '20px',
  background: theme.global.primary_active_color,
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '5px',
  cursor: 'pointer',
}));

const ContinueButton = styled(SwitchButton)``;

const StyledErrorMessage = styled.span(({ theme }) => ({
  color: 'red',
  ...getFontStyles(
    theme.text.primary_font,
    ['font_size', 'font_style', 'font_family', 'font_weight', 'line_height'],
    {
      font_size: (value) => {
        if (typeof value === 'number') {
          // shrink the size to 75% of the primary font size
          // but ensure a minimum of .75rem (12pt)
          return `max(0.75rem, ${value * 0.75}px)`;
        }

        return value;
      },
    },
  ),
}));

const GFOModal: React.FC<GFOModalProps> = ({ onClose }) => {
  const { address } = useSelector(selectConveyance);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <DeliveryDetails />
        <ContinueButton
          onClick={address ? onClose : () => setErrorMessage('Please enter a delivery address')}
        >
          Continue
        </ContinueButton>
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        <SwitchButton onClick={onClose}>Switch to Pickup</SwitchButton>
      </ModalContent>
    </ModalBackground>
  );
};

export default GFOModal;
