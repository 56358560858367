import { type AnyAction } from 'redux';
import actions from './actions';
import { type ITimeslotsState } from '@/types/timeslots';

export const initialState: ITimeslotsState = {
  basketTimes: [],
  locationTimes: [],
  wantedAt: null,
  loading: false,
  error: false,
};

export const timeslots = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_HANDOFF_TIMES_BY_LOCATION:
    case actions.FETCH_HANDOFF_TIMES_BY_BASKET:
      return Object.assign({}, state, {
        loading: true,
      });
    case actions.SET_WANTED_AT:
      return Object.assign({}, state, {
        wantedAt: action.wantedAt,
        error: action.error || initialState.error,
      });
    case actions.FETCH_HANDOFF_TIMES_SUCCESS:
      return Object.assign({}, state, {
        [action.timeslotType]: action.timeslots,
        loading: false,
      });
    case actions.FETCH_HANDOFF_TIMES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
      });
    case actions.CLEAR_HANDOFF_TIMES:
      return initialState;
    default:
      return state;
  }
};
