import { getOrderDeliveryStatus } from '@koala/sdk/v4';
import { call, put, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import { deliveryStatusActions as actions, deliveryStatusActionTypes as types } from './actions';
import globalActions from '@/redux/global/actions';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import * as ErrorReporter from '@/utils/errorReporter';
import { prepareErrorMessage } from '@/utils/global';

export function* fetchDeliveryStatusByOrderNumberSaga(
  action: ReturnType<typeof actions.fetchDeliveryStatusByOrderNumber>,
) {
  try {
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });
    const response: SagaReturnType<typeof getOrderDeliveryStatus> = yield call(
      getOrderDeliveryStatus,
      action.order_number!,
      { client },
    );

    // Write menu into redux
    yield put(actions.fetchDeliveryStatusByOrderNumberSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchDeliveryStatusByOrderNumberFail(error));
    // Error Notification
    ErrorReporter.captureException(error);

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching order delivery status by external order id',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    // TODO: Introduce analytic event in separate effort
  }
}

export default function* rootSaga() {
  yield takeLatest(
    types.FETCH_DELIVERY_STATUS_BY_ORDER_NUMBER,
    fetchDeliveryStatusByOrderNumberSaga,
  );
}
