import { type LoyaltyUserRegistrationInfo, type PasswordResetInfo } from '@koala/sdk';
import { type IUserLogin } from '@/types/authentication';
import { type IUserInfo } from '@/types/checkout';

const authActions = {
  RELOAD_USER: '/koala/auth/RELOAD_USER',
  LOGIN_REQUEST: '/koala/auth/LOGIN_REQUEST',
  SOCIAL_LOGIN_REQUEST: '/koala/auth/SOCIAL_LOGIN_REQUEST',
  AUTH_FAILURE: '/koala/auth/AUTH_FAILURE',
  AUTH_SUCCESS: '/koala/auth/AUTH_SUCCESS',
  LOGOUT: '/koala/auth/LOGOUT',
  LOGOUT_SUCCESS: '/koala/auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE: '/koala/auth/LOGOUT_FAILURE',
  SIGN_UP_REQUEST: '/koala/auth/SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: '/koala-auth/SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: '/koala-auth/SIGN_UP_FAILURE',
  RESET_PASSWORD: '/koala-auth/RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: '/koala-auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL: '/koala-auth/RESET_PASSWORD_FAIL',
  FORGOT_PASSWORD: 'koala-auth/FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'koala/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAIL: 'koala/FORGOT_PASSWORD_FAIL',
  CHECKOUT_AS_GUEST: 'koala/CHECKOUT_AS_GUEST',
  TOGGLE_EMAIL_VERIFICATION: '/koala/auth/TOGGLE_EMAIL_VERIFICATION',

  reloadUser: () => ({
    type: authActions.RELOAD_USER,
  }),
  login: (params: IUserLogin, referrer: string | string[] | null, signUpChain?: boolean) => ({
    type: authActions.LOGIN_REQUEST,
    params,
    referrer,
    signUpChain,
  }),
  signUp: (params: LoyaltyUserRegistrationInfo, referrer: string | string[] | null) => ({
    type: authActions.SIGN_UP_REQUEST,
    params,
    referrer,
  }),
  signUpSuccess: () => ({
    type: authActions.SIGN_UP_SUCCESS,
  }),
  signUpFailure: () => ({
    type: authActions.SIGN_UP_FAILURE,
  }),
  logout: (cancelRedirect?: boolean) => ({
    type: authActions.LOGOUT,
    cancelRedirect,
  }),
  authFailure: () => ({
    type: authActions.AUTH_FAILURE,
  }),
  authSuccess: () => ({
    type: authActions.AUTH_SUCCESS,
  }),
  resetPassword: (params: PasswordResetInfo) => ({
    type: authActions.RESET_PASSWORD,
    params,
  }),
  resetPasswordSuccess: () => ({
    type: authActions.RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordFail: () => ({
    type: authActions.RESET_PASSWORD_FAIL,
  }),
  forgotPassword: (email: string) => ({
    type: authActions.FORGOT_PASSWORD,
    email,
  }),
  forgotPasswordSuccess: () => ({
    type: authActions.FORGOT_PASSWORD_SUCCESS,
  }),
  forgotPasswordFail: () => ({
    type: authActions.FORGOT_PASSWORD_FAIL,
  }),
  checkoutAsGuest: (payload: IUserInfo | null) => ({
    type: authActions.CHECKOUT_AS_GUEST,
    payload,
  }),
  toggleEmailVerification: (toggleEmailVerification: boolean) => ({
    type: authActions.TOGGLE_EMAIL_VERIFICATION,
    toggleEmailVerification,
  }),
};

export default authActions;
