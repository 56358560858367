import { type Location, type OrderDetails } from '@koala/sdk';
import { toDollars } from '@/utils/basket';

/**
 * Facebook
 */
const hasFacebook = () => typeof window.fbq !== 'undefined';
export class FacebookAnalytics {
  /**
   * Page View Event
   */
  static pageView() {
    if (!hasFacebook()) {
      return;
    }

    window.fbq('track', 'PageView');
  }

  /**
   * Commerce Events
   */
  static purchase(orderDetails: OrderDetails) {
    if (!hasFacebook()) {
      return;
    }

    window?.fbq('track', 'Purchase', {
      value: toDollars(orderDetails.order_data.order.total),
      currency: FacebookAnalytics.determineCurrencyCodeFromLocation(orderDetails?.location),
    });
  }

  /**
   * Determines the currency code to report based on the location's 2-letter or 3-letter country code.
   * @param location: Location | undefined | null
   * @return string | undefined
   */
  static determineCurrencyCodeFromLocation(
    location: Location | null | undefined,
  ): string | undefined {
    if (!location) {
      return undefined;
    }

    switch (location?.country.toUpperCase()) {
      case 'US':
      case 'USA':
        return 'USD';
      case 'CA':
      case 'CAN':
        return 'CAD';
      default:
        return undefined; //Default to undefined instead of incorrectly reporting USD in analytics data
    }
  }
}
