import { CONVEYANCE_TYPES } from '@koala/sdk';
import { API_CONVEYANCE_TYPES } from '@/constants/events';
import { FALLBACK_FEATURE_BAG, FEATURE_FLAGS } from '@/constants/features';
import { ALLOWED_URL_PARAMETER_KEYS, LOCKED_FLOW_CONVEYANCE_TYPES } from '@/constants/global';
import { REORDER_APP_VIEWS } from '@/constants/reorders';
import { LOYALTY_ROUTES } from '@/constants/routes';
import { type AppState } from '@/types/app';
import { type IOrderDetailsState } from '@/types/orderDetails';
import { allowedLoyaltyRoutes } from '@/utils/loyalty';

interface FeatureHandler {
  isFeatureAllowed: (featureFlag: FEATURE_FLAGS) => boolean;
}

/**
 * Provides methods to determine whether or not a feature should be enabled or disabled.
 *
 * @IMPLEMENTATION-TODO
 * As of 12/21/21: Without upgraded Next/React/Redux packages, we cannot yet use the following
 * alternatives to requiring global state in the constructor:
 * - useSelector()
 * - useStore()
 * - useReducer()
 * - react-redux context
 * - exporting store from 'redux/store' (SSR limitations and also this is a dirty way of doing this)
 */
export class KoalaFeatureHandler implements FeatureHandler {
  private readonly globalState: AppState;

  constructor(globalState: AppState) {
    this.globalState = globalState;
  }

  /**
   * Determine if a conveyance type is a locked conveyance flow
   *
   * @param conveyanceType API_CONVEYANCE_TYPES
   * @returns boolean
   */
  private isLockedConveyanceFlow = (conveyanceType: API_CONVEYANCE_TYPES): boolean => {
    const handoffParam =
      // @ts-expect-error: TODO: 'string' can't be used to index type '{}'
      this.globalState.global.routerStatus.persistentParameters?.[
        ALLOWED_URL_PARAMETER_KEYS.HANDOFF
      ] || '';
    return handoffParam === conveyanceType && LOCKED_FLOW_CONVEYANCE_TYPES.includes(conveyanceType);
  };

  /**
   * Determine if a feature flag is enabled or not
   *
   * @param featureFlag FEATURE_FLAGS
   * @returns boolean
   */
  isFeatureAllowed(featureFlag: FEATURE_FLAGS): boolean {
    const hasPastOrders = this.globalState.me?.pastOrders?.length > 0;
    const isDineInFlow = this.isLockedConveyanceFlow(API_CONVEYANCE_TYPES.DINEIN);
    const loyaltyDriverID = this.globalState.organization.organization?.loyalty_driver_id;
    const androidReordersEnabled =
      this.globalState.cmsConfig.webConfig.android_features?.reorders ?? false;
    const androidReordersView =
      this.globalState.cmsConfig.webConfig.android_features?.reorders_view ?? false;
    const loggedIn = this.globalState.me?.data ?? false;

    switch (featureFlag) {
      case FEATURE_FLAGS.GLOBAL__LOCATIONS_PAGE:
        // Hide the locations page when it is disabled or if the user is in a locked conveyance flow and should not change locations
        const locationsDisabled =
          this.globalState.cmsConfig.webConfig.locations.disable_locations_page;

        return !(isDineInFlow || locationsDisabled);
      case FEATURE_FLAGS.STORE__FULFILLMENT_TOGGLE:
        // Hide the fulfillment toggle if the user is in a delivery-only flow or a locked dine_in flow
        const locationDetail = this.globalState.locations.detail;
        const deliveryOnlyOrNoDelivery =
          !locationDetail?.supports_delivery || locationDetail?.is_delivery_only;

        return !(deliveryOnlyOrNoDelivery ?? isDineInFlow);
      case FEATURE_FLAGS.STORE__MENU_SEARCH:
        return this.globalState.cmsConfig.webConfig.menu.menu_search;

      case FEATURE_FLAGS.STORE__DIETARY_PREFERENCES:
        return (
          this.globalState.cmsConfig.webConfig.menu.dietary_preferences_enabled &&
          this.globalState.allergens?.allergens.length > 0
        );

      case FEATURE_FLAGS.ANDROID__REORDER:
        return hasPastOrders && !isDineInFlow && androidReordersEnabled;

      case FEATURE_FLAGS.ANDROID__REORDER_LIST:
        return (
          hasPastOrders &&
          !isDineInFlow &&
          androidReordersEnabled &&
          androidReordersView === REORDER_APP_VIEWS.LIST
        );

      case FEATURE_FLAGS.ANDROID__REORDER_CTA:
        return (
          hasPastOrders &&
          !isDineInFlow &&
          androidReordersEnabled &&
          androidReordersView === REORDER_APP_VIEWS.CTA
        );

      case FEATURE_FLAGS.ME__REORDER:
        const reordersEnabled = this.globalState.cmsConfig.webConfig.accounts.reorders;
        return hasPastOrders && !isDineInFlow && reordersEnabled;

      case FEATURE_FLAGS.ACCOUNT__REWARDS:
        return allowedLoyaltyRoutes(loyaltyDriverID).includes(LOYALTY_ROUTES.REWARDS);

      case FEATURE_FLAGS.ACCOUNT__INBOX:
        return allowedLoyaltyRoutes(loyaltyDriverID).includes(LOYALTY_ROUTES.INBOX);

      case FEATURE_FLAGS.ACCOUNT__ORDER_HISTORY:
        // Hide these features if the user is in a locked dine_in flow
        return !isDineInFlow;
      case FEATURE_FLAGS.ACCOUNT__FAVORITES:
        // Hide these features if the user is in a locked dine_in flow
        return !isDineInFlow;
      case FEATURE_FLAGS.CHECKOUT__HANDOFF_DETAILS:
        // Hide these features if the user is in a locked dine_in flow
        return !isDineInFlow;
      case FEATURE_FLAGS.CHECKOUT__WANTED_AT_TIME:
        // Hide these features if the user is in a locked dine_in flow
        return !isDineInFlow;
      case FEATURE_FLAGS.ANDROID__FAVORITES:
        return loggedIn && !allowedLoyaltyRoutes(loyaltyDriverID).includes(LOYALTY_ROUTES.INBOX);
      case FEATURE_FLAGS.CHECKOUT__HANDOFF_DINE_IN_INSTRUCTIONS:
        // Hide this feature if the user is not in a locked dine_in flow and
        // if the conveyance mode is not dine in or the location does NOT support table numbers
        const isDineInConveyance = this.globalState.conveyanceMode.type === CONVEYANCE_TYPES.DINEIN;
        const supportsTableNumbers = this.globalState.basket.location.supports_dine_in_table_number;
        if (!supportsTableNumbers) {
          return false;
        }
        return isDineInFlow || isDineInConveyance;
      case FEATURE_FLAGS.CHECKOUT__ESCAPE_LOCKED_CONVEYANCE:
        // Only enable this feature if the user is in a locked dine_in flow
        return isDineInFlow;
      case FEATURE_FLAGS.CART__CHANGE_LOCATION:
        // Hide the change location CTA in the cart if there is no string present
        return this.globalState.cmsConfig.strings.cart_checkout.change_location_cta ? true : false;
      case FEATURE_FLAGS.ACCOUNT__FAVORITE_LOCATIONS_ENABLED:
        const supportsSingleFavoriteLocations =
          !!this.globalState.organization?.external_auth?.features?.supportsSingleFavoriteLocations;
        const isFavoriteLocationEnabled =
          this.globalState.cmsConfig.webConfig.accounts.favorite_location !== 'disabled';

        return supportsSingleFavoriteLocations && isFavoriteLocationEnabled;
      case FEATURE_FLAGS.DELIVERY_STATUS_ENABLED: {
        const orderDetails: IOrderDetailsState = this.globalState?.orderDetails;
        const conveyanceType =
          orderDetails?.order_data?.basket?.conveyance_type?.type?.toLowerCase();
        const isDeliveryConveyanceType =
          conveyanceType === API_CONVEYANCE_TYPES.DELIVERY.toLowerCase();
        const isDispatchTrackingEnabled =
          !!orderDetails?.order_number &&
          isDeliveryConveyanceType &&
          this.globalState.cmsConfig.webConfig.dispatch_tracking.dispatch_tracking_enabled;

        return isDispatchTrackingEnabled;
      }

      default:
        // return individual flag default/fallback state or false if no fallback is provided
        return FALLBACK_FEATURE_BAG[featureFlag] || false;
    }
  }
}
