import { type LoyaltyUser, type LoyaltyUserRegistrationInfo, type Location } from '@koala/sdk';
import { validateLoyaltyToken } from '@koala/sdk/v4';
import { SIGNUP_OPTIONAL_FIELDS } from '@/constants/authentication';
import { type RootState } from '@/types/app';
import { type IUserInfo } from '@/types/checkout';
import { AuthProviderLoyaltyIDs, type IAppleResponseJWT } from '@/types/constants/global_config';
import { getOrigin } from '@/utils';
import { isAndroidShell, getAndroidShellPushNotificationToken } from '@/utils/android';
import * as ErrorReporter from '@/utils/errorReporter';

export const isUserLoggedInForCheckout = (me: LoyaltyUser | null, guestUser: IUserInfo | null) => {
  return Boolean(me?.id) || Boolean(guestUser);
};

export const userLoggedIn = () => {
  return validateLoyaltyToken(getOrigin(window.location.host));
};

export const isUser = (user: LoyaltyUser | IUserInfo): user is LoyaltyUser => {
  return !!(user as LoyaltyUser)?.id;
};

export const phoneNumberFormatter = (phone: string) => {
  phone = phone.replace(/[^\d]/g, '');

  if (phone.length === 10) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return null;
};

export const getSignupOptionalFields = (loyaltyDriverId: number | null): string[] => {
  switch (loyaltyDriverId) {
    case AuthProviderLoyaltyIDs.PAYTRONIX:
    case AuthProviderLoyaltyIDs.SPENDGO:
      return [
        SIGNUP_OPTIONAL_FIELDS.OPT_IN,
        SIGNUP_OPTIONAL_FIELDS.BIRTHDAY,
        SIGNUP_OPTIONAL_FIELDS.TERMS_AND_CONDITIONS,
      ];
    case AuthProviderLoyaltyIDs.PUNCHH:
      return [
        SIGNUP_OPTIONAL_FIELDS.TERMS_AND_CONDITIONS,
        SIGNUP_OPTIONAL_FIELDS.BIRTHDAY,
        SIGNUP_OPTIONAL_FIELDS.REFERRAL_CODE,
      ];
    default:
      return [SIGNUP_OPTIONAL_FIELDS.ZIP_CODE, SIGNUP_OPTIONAL_FIELDS.TERMS_AND_CONDITIONS];
  }
};

export const getExtraSignupPayload = (
  loyaltyDriverId: number | null,
): Partial<LoyaltyUserRegistrationInfo> => {
  switch (loyaltyDriverId) {
    // Punchh V2
    case 2:
      return {
        signup_channel: isAndroidShell() ? 'MobileEmail' : 'WebEmail',
      };
    default:
      return {};
  }
};

/* Fetch feature flags for use in sagas */
export const getExternalAuthInfoFromState = (state: RootState) => {
  return {
    externalAuthFeatureFlags: state.app.organization.external_auth?.features,
  };
};

export const addOptionalPushNotificationToken = (
  values: LoyaltyUserRegistrationInfo,
): LoyaltyUserRegistrationInfo => {
  return {
    ...values,
    ...getAndroidShellPushNotificationToken(),
  };
};

export const getParsedJwt = (token: string): IAppleResponseJWT | undefined => {
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/atob
    // Disabling eslint for current line as atob is valid way of parsing token in browser environment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    ErrorReporter.captureException(e);
    return;
  }
};

export const getUserFullName = (user: LoyaltyUser | IUserInfo | null) => {
  if (user) {
    return `${user.first_name} ${user.last_name}`;
  }

  return '';
};

interface TrackedUser {
  name: string | undefined;
  email: string;
  locationsCount?: number | undefined;
  employee?: boolean;
}

/* Add custom user properties to Heap */
export const identifyUser = (
  user: LoyaltyUser | IUserInfo,
  userId: number,
  locations: Location[],
) => {
  const loggedInUser: TrackedUser = {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email ?? '',
  };
  if (user.email?.includes('@chowly')) {
    loggedInUser.employee = true;
  } else {
    loggedInUser.locationsCount = locations?.length;
    loggedInUser.employee = false;
  }

  window.heap.identify(String(userId));
  window.heap.addUserProperties(loggedInUser);
  return;
};
