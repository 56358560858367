import { getAllergens } from '@koala/sdk/v4';
import { call, put, type SagaReturnType, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';

export function* fetchAllergensSaga() {
  try {
    const origin = getOrigin(window.location.host);
    const client = createHttpClient({ origin });
    const response: SagaReturnType<typeof getAllergens> = yield call(
      getAllergens,
      { perPage: 15 },
      { client },
    );
    // Success
    yield put(actions.allergensSuccess(response));
  } catch (error) {
    yield put(actions.allergensFail());
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ALLERGENS, fetchAllergensSaga);
}
