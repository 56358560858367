import {
  type CONVEYANCE_TYPES,
  type DeliveryAddress,
  type Location,
  type LocationSearchQuery,
} from '@koala/sdk';
import { type MagicBoxParams } from '@/utils/magicBox';

export interface ILocationsAction {
  id?: number;
  lastUpdatedAt?: string;
  params?: LocationSearchQuery;
  results?: Location[];
  magicBox?: MagicBoxParams;
  previousAction?: string;
  address?: DeliveryAddress;
  type: locationActionTypes;
  conveyanceType?: CONVEYANCE_TYPES;
  location?: Location;
  list?: Location[];
  newList?: Location[];
  meta?: unknown;
  detail?: Location;
}

export enum locationActionTypes {
  FETCH_LOCATIONS = '/koala/locations/FETCH_LOCATIONS',
  FETCH_MORE_LOCATIONS = '/koala/locations/FETCH_MORE_LOCATIONS',
  FETCH_ALL_LOCATIONS = '/koala/locations/FETCH_ALL_LOCATIONS',
  FETCH_LOCATION = '/koala/locations/FETCH_LOCATION',
  FETCH_LOCATION_IF_MODIFIED = '/koala/locations/FETCH_LOCATION_IF_MODIFIED',
  FETCH_LOCATION_NOT_MODIFIED = '/koala/locations/FETCH_LOCATION_NOT_MODIFIED',
  HYDRATE_LOCATION = '/koala/locations/HYDRATE_LOCATION',
  LOCATIONS_FAIL = '/koala/locations/LOCATIONS_FAIL',
  LOCATIONS_SUCCESS = '/koala/locations/LOCATIONS_SUCCESS',
  LOCATION_SUCCESS = '/koala/locations/LOCATION_SUCCESS',
  MORE_LOCATIONS_SUCCESS = '/koala/locations/MORE_LOCATIONS_SUCCESS',
  ALL_LOCATIONS_SUCCESS = '/koala/locations/ALL_LOCATIONS_SUCCESS',
  SEARCH_LOCATIONS = '/koala/locations/SEARCH_LOCATIONS',
  SEARCH_LOCATIONS_SUCCESS = '/koala/locations/SEARCH_LOCATIONS_SUCCESS',
  SET_ACTIVE_LOCATION = '/koala/locations/SET_ACTIVE_LOCATION',
  CLEAR_LOCATIONS = '/koala/locations/CLEAR_LOCATIONS',
  REFRESH_BASKET_LOCATION = '/koala/locations/REFRESH_BASKET_LOCATION',
  SEARCH_DELIVERY_COVERAGE = '/koala/locations/SEARCH_DELIVERY_COVERAGE',
  SEARCH_DELIVERY_COVERAGE_SUCCESS = '/koala/locations/SEARCH_DELIVERY_COVERAGE_SUCCESS',
  SEARCH_DELIVERY_COVERAGE_FAILURE = '/koala/locations/SEARCH_DELIVERY_COVERAGE_FAILURE',
  SEARCH_DELIVERY_COVERAGE_NO_RESULTS = '/koala/locations/SEARCH_DELIVERY_COVERAGE_NO_RESULTS',
  CLEAR_DELIVERY_SEARCH_ERRORS = '/koala/locations/CLEAR_DELIVERY_SEARCH_ERRORS',
  SHOW_DELIVERY_ADDRESS_INVALID_ERROR = '/koala/locations/SHOW_DELIVERY_ADDRESS_INVALID_ERROR',
  SHOW_GOOGLE_ADDRESS_NOT_SELECTED_ERROR = '/koala/locations/SHOW_GOOGLE_ADDRESS_NOT_SELECTED_ERROR',
}

export const locationsActions = {
  fetchLocations: (magicBox: MagicBoxParams): ILocationsAction => ({
    magicBox,
    type: locationActionTypes.FETCH_LOCATIONS,
  }),
  fetchMoreLocations: (magicBox: MagicBoxParams): ILocationsAction => ({
    magicBox,
    type: locationActionTypes.FETCH_MORE_LOCATIONS,
  }),
  fetchAllLocations: (magicBox: MagicBoxParams, results?: Location[]): ILocationsAction => ({
    magicBox,
    results,
    type: locationActionTypes.FETCH_ALL_LOCATIONS,
  }),
  fetchLocation: (id: number): ILocationsAction => ({
    id,
    type: locationActionTypes.FETCH_LOCATION,
  }),
  fetchLocationIfModified: (id: number, lastUpdatedAt: string): ILocationsAction => ({
    type: locationActionTypes.FETCH_LOCATION_IF_MODIFIED,
    id,
    lastUpdatedAt,
  }),
  fetchLocationNotModified: (): ILocationsAction => ({
    type: locationActionTypes.FETCH_LOCATION_NOT_MODIFIED,
  }),
  hydrateLocation: (location: Location) => ({
    location,
    type: locationActionTypes.HYDRATE_LOCATION,
  }),
  searchLocations: (
    magicBox: MagicBoxParams,
    params?: LocationSearchQuery,
    previousAction?: string,
    conveyanceType?: CONVEYANCE_TYPES,
  ): ILocationsAction => ({
    params,
    magicBox,
    previousAction,
    type: locationActionTypes.SEARCH_LOCATIONS,
    conveyanceType,
  }),
  setActiveLocation: (id: number): ILocationsAction => ({
    id,
    type: locationActionTypes.SET_ACTIVE_LOCATION,
  }),
  clearLocations: (): ILocationsAction => ({
    type: locationActionTypes.CLEAR_LOCATIONS,
  }),
  refreshBasketLocation: (id: number): ILocationsAction => ({
    type: locationActionTypes.REFRESH_BASKET_LOCATION,
    id,
  }),
  searchDeliveryCoverage: (address: DeliveryAddress): ILocationsAction => ({
    type: locationActionTypes.SEARCH_DELIVERY_COVERAGE,
    address,
  }),
  clearDeliverySearchErrors: (): ILocationsAction => ({
    type: locationActionTypes.CLEAR_DELIVERY_SEARCH_ERRORS,
  }),
  showDeliveryAddressInvalidError: (): ILocationsAction => ({
    type: locationActionTypes.SHOW_DELIVERY_ADDRESS_INVALID_ERROR,
  }),
  showGoogleAddressNotSelectedError: (): ILocationsAction => ({
    type: locationActionTypes.SHOW_GOOGLE_ADDRESS_NOT_SELECTED_ERROR,
  }),
  searchLocationsSuccess: (
    list?: Location[],
    meta?: unknown,
    params?: LocationSearchQuery,
  ): ILocationsAction => ({
    type: locationActionTypes.SEARCH_LOCATIONS_SUCCESS,
    list,
    meta,
    params,
  }),
  fetchLocationsSuccess: (
    list?: Location[],
    meta?: unknown,
    params?: LocationSearchQuery,
  ): ILocationsAction => ({
    type: locationActionTypes.LOCATIONS_SUCCESS,
    list,
    meta,
    params,
  }),
  searchLocationsFail: (): ILocationsAction => ({
    type: locationActionTypes.LOCATIONS_FAIL,
  }),
  fetchAllLocationsSuccess: (list?: Location[]): ILocationsAction => ({
    type: locationActionTypes.ALL_LOCATIONS_SUCCESS,
    list,
  }),
  fetchMoreLocationsSuccess: (
    newList?: Location[],
    meta?: unknown,
    params?: LocationSearchQuery,
  ): ILocationsAction => ({
    type: locationActionTypes.MORE_LOCATIONS_SUCCESS,
    newList,
    meta,
    params,
  }),
  fetchLocationSuccess: (detail?: Location): ILocationsAction => ({
    type: locationActionTypes.LOCATION_SUCCESS,
    detail,
  }),
  searchDeliveryCoverageSuccess: (): ILocationsAction => ({
    type: locationActionTypes.SEARCH_DELIVERY_COVERAGE_SUCCESS,
  }),
  searchDeliveryCoverageNoResults: (): ILocationsAction => ({
    type: locationActionTypes.SEARCH_DELIVERY_COVERAGE_NO_RESULTS,
  }),
  searchDeliveryCoverageFail: (): ILocationsAction => ({
    type: locationActionTypes.SEARCH_DELIVERY_COVERAGE_FAILURE,
  }),
};
