import { type DeliveryAddress } from '@koala/sdk/v4';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SavedAddresses } from './saved-addresses';
import { GoogleAutocomplete } from '@/components/autocomplete';
import StringAccessor from '@/components/cmsConfig/stringAccessor';
import { Box } from '@/components/ui';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { useLocation } from '@/features/locations/service';
import { useSavedAddresses } from '@/features/loyalty/addresses';
import {
  addressCheckDeliveryCoverage,
  addressSetTimeWantedFields,
} from '@/features/menu-availability/address.service';
import { useSelector, useDispatch } from '@/redux';
import { getFontStyles } from '@/redux/cmsConfig/utils';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import { selectConveyance } from '@/redux/conveyanceMode/reducer';
import globalActions from '@/redux/global/actions';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

export function DeliveryDetails() {
  const [error, setError] = useState(false);
  const [editing, setEditing] = useState(false);
  const { data } = useSavedAddresses();
  const { location, time_wanted, address } = useSelector(selectConveyance);
  const { data: locationDetails, isSuccess: locationSuccess } = useLocation(location);
  const dispatch = useDispatch();
  const savedAddress = Boolean(data?.data.length);

  useEffect(() => {
    if (address?.street_address && address?.city && editing) {
      setEditing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.city, address?.street_address]);

  if (!locationSuccess) {
    return null;
  }

  if (address?.street_address && !editing) {
    let streetAddress = address.street_address;

    if (address.street_address_2) {
      streetAddress = `${streetAddress} ${address.street_address_2}`;
    }

    return (
      <AddressDetails>
        <Address>
          <h2>Delivering to</h2>
          <p>
            {streetAddress}, {address.city}
          </p>
        </Address>

        <EditAddress
          onClick={() => {
            setEditing(true);
          }}
        >
          Change delivery address
        </EditAddress>
      </AddressDetails>
    );
  }

  return (
    <>
      <GoogleAutocomplete
        onUpdate={async (address) => {
          const fullAddress: DeliveryAddress = {
            ...address,
            ...addressSetTimeWantedFields(time_wanted),
          };

          try {
            const response = await addressCheckDeliveryCoverage(
              locationDetails.data.id,
              fullAddress,
            );

            if (response.can_deliver) {
              setError(false);

              dispatch(conveyanceModeActions.setDeliveryAddress(fullAddress, locationDetails.data));
            } else {
              setError(true);

              // KA event
              fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
                name: ERROR_MESSAGES.CHECK_DELIVERY_COVERAGE_ERROR,
                details: response.message,
              });
            }
          } catch (error) {
            dispatch(
              globalActions.displayErrorToast(
                'There was an error validating your delivery address. Please try again in a few minutes.',
                true,
              ),
            );

            // KA event
            const errorResponse = await prepareErrorMessage(null, error);

            fireKAnalyticsError(
              ERROR_MESSAGES.VALIDATE_DELIVERY_ADDRESS_ERROR,
              error,
              errorResponse,
            );
          }
        }}
      />

      {!error && !savedAddress && (
        <StyledAutocompleteInstructions>
          <StringAccessor accessor="locations.delivery_search_instructional_text" html={true} />
        </StyledAutocompleteInstructions>
      )}

      {/* No Delivery locations returned from API  */}
      {error && (
        <StyledAutocompleteInstructions error={true}>
          <StringAccessor accessor="locations.delivery_search_no_results_text" />
        </StyledAutocompleteInstructions>
      )}

      <Box
        css={{
          marginTop: '1rem',
        }}
      >
        <SavedAddresses
          value={address}
          onUpdate={(address) => {
            const fullAddress: DeliveryAddress = {
              ...address,
              ...addressSetTimeWantedFields(time_wanted),
            };

            dispatch(conveyanceModeActions.setDeliveryAddress(fullAddress, locationDetails?.data));
          }}
        />
      </Box>
    </>
  );
}

const AddressDetails = styled.div({});

const Address = styled.address(({ theme }) => ({
  fontStyle: 'normal',

  'h2, p': {
    font: 'inherit',
    margin: 0,
    padding: 0,
  },

  h2: {
    opacity: 0.6,
    ...getFontStyles(
      theme.header.font,
      ['font_size', 'font_style', 'font_family', 'font_weight', 'line_height'],
      {
        font_size: (value) => {
          if (typeof value === 'number') {
            // shrink the size to 87.5% of the primary font size
            // but ensure a minimum of .875rem (14pt)
            return `max(0.875rem, ${value * 0.875}px)`;
          }

          return value;
        },
      },
    ),
    ...getFontStyles(theme.modals.font, ['color']),
  },

  p: {
    ...getFontStyles(theme.header.font, [
      'font_size',
      'font_style',
      'font_family',
      'font_weight',
      'line_height',
    ]),
    ...getFontStyles(theme.modals.font, ['color']),
  },
}));

const EditAddress = styled.button(({ theme }) => ({
  all: 'unset',
  outline: 'revert',
  display: 'block',
  color: theme.global.primary_active_color,
  fontWeight: 700,
  margin: '1rem 0 0',
  ...getFontStyles(theme.header.font, ['font_family', 'font_size', 'font_style'], {
    font_size: (value) => {
      if (typeof value === 'number') {
        // shrink the size to 87.5% of the primary font size
        // but ensure a minimum of .875rem (14pt)
        return `max(0.875rem, ${value * 0.875}px)`;
      }

      return value;
    },
  }),

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledAutocompleteInstructions = styled.p<{ error?: boolean }>(
  ({ theme, error = false }) => ({
    fontSize: '.75rem',
    margin: '.75rem 0 0',
    textAlign: 'left',
    ...getFontStyles(
      theme.text.primary_font,
      ['font_size', 'font_style', 'font_family', 'font_weight', 'line_height'],
      {
        font_size: (value) => {
          if (typeof value === 'number') {
            // shrink the size to 75% of the primary font size
            // but ensure a minimum of .75rem (12pt)
            return `max(0.75rem, ${value * 0.75}px)`;
          }

          return value;
        },
      },
    ),
    ...getFontStyles(theme.modals.font, ['color'], {
      color: (value) => {
        return error ? theme.global.error_color : value;
      },
    }),
  }),
);
