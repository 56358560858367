import { CONVEYANCE_TYPES } from '@koala/sdk';
import { listLocations, getDeliveryCoverage } from '@koala/sdk/v4';
import orderBy from 'lodash/orderBy';
import { call, put, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import basketActions from '../basket/actions';
import conveyanceModeActions from '../conveyanceMode/actions';
import globalActions from '../global/actions';
import { locationsActions as actions, locationActionTypes as actionTypes } from './actions';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { createHttpClient } from '@/services/client';
import { fetchLocation } from '@/services/locations.service';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { isValidZip } from '@/utils/locations';

function* fetchAllLocationsSaga(action: ReturnType<typeof actions.fetchAllLocations>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const queryArgs = {
      paginate: false,
    };

    const response: SagaReturnType<typeof listLocations> = yield call(
      listLocations,
      action.magicBox ? { ...action.magicBox, ...queryArgs } : queryArgs,
      { client },
    );

    // Success
    const locations = action.results ? action.results.concat(response.data) : response.data;
    yield put(actions.fetchAllLocationsSuccess(locations));

    // Set default active location index
    yield put(actions.setActiveLocation(locations[0]?.id));
  } catch (error) {
    console.log(error);
    yield put(actions.searchLocationsFail());
  }
}

function* fetchLocationsSaga(action: ReturnType<typeof actions.fetchLocations>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    /** @TODO ensure that `magicBox` is defined. */
    // @ts-expect-error
    if (action.magicBox.page > 1) {
      /** @TODO ensure that `magicBox` is defined. */
      // @ts-expect-error
      yield put(actions.fetchMoreLocations(action.magicBox));

      // Stop locations loading
      yield put(actions.searchLocationsFail());
      return;
    }

    const response: SagaReturnType<typeof listLocations> = yield call(
      /** @TODO ensure that `magicBox` is defined. */
      // @ts-expect-error
      listLocations,
      action.magicBox,
      { client },
    );

    // Success
    yield put(actions.fetchLocationsSuccess(response.data, response.meta, action.params));

    // Set default active location index
    yield put(actions.setActiveLocation(response.data[0]?.id));
  } catch (error) {
    yield put(actions.searchLocationsFail());
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_LOCATIONS_FAILURE, error, errorResponse);
  }
}

function* fetchMoreLocationsSaga(action: ReturnType<typeof actions.fetchMoreLocations>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof listLocations> = yield call(
      /** @TODO ensure that `magicBox` is defined. */
      // @ts-expect-error
      listLocations,
      action.magicBox,
      { client },
    );

    // Success
    yield put(actions.fetchMoreLocationsSuccess(response.data, response.meta, action.params));
  } catch (error) {
    yield put(actions.searchLocationsFail());
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_MORE_LOCATIONS_FAILURE, error, errorResponse);
  }
}

export function* searchLocationsSaga(action: ReturnType<typeof actions.searchLocations>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    let search: Record<string, unknown> = {};

    if (action.params?.address) {
      search = {
        address: action.params.address,
      };
    } else if (action.params?.latitude && action.params?.longitude) {
      search = {
        latitude: action.params.latitude,
        longitude: action.params.longitude,
      };
    } else {
    }

    const response: SagaReturnType<typeof listLocations> = yield call(
      listLocations,
      {
        ...action.magicBox,
        search,
        distance: action?.params?.distance ?? 10, // default to 10 miles
      },
      { client },
    );

    const sortedLocations = orderBy(response.data, 'distance_miles', 'asc');

    // If we have results or we've already performed a no results search
    if (response.data.length || action.previousAction) {
      // Success
      yield put(actions.searchLocationsSuccess(sortedLocations, response.meta, action.params));

      // Set default active location index
      yield put(actions.setActiveLocation(response.data[0]?.id));

      // KA event
      // NOTE: it only handles US zip codes.
      const inputtedAddress = action.params?.address ?? '';
      const isZip = isValidZip(inputtedAddress);
      const details = isZip
        ? `zip_code; ${sortedLocations.length}`
        : sortedLocations?.length?.toString();

      genericEventHandler(GlobalEvents.LOCATIONS__SEARCH_LOCATIONS, {
        name: action.conveyanceType,
        details,
      });
    } else {
      // If we have no results and have no already re-searched with an expanded radius
      const expandedParams = Object.assign({}, action.params, {
        distance: 25,
      });

      yield put(
        /** @TODO ensure that `magicBox` is defined. */
        // @ts-expect-error
        actions.searchLocations(action.magicBox, expandedParams, action.type),
      );
    }
  } catch (error) {
    if ((error as Response).status === 404) {
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
        prepareErrorMessage,
        null,
        error,
      );
      // Error Notification
      yield put(
        globalActions.displayErrorToast('Oops. Please enter a valid city, zip or address.'),
      );

      // KA event
      fireKAnalyticsError(ERROR_MESSAGES.INVALID_ZIP_ERROR, error, errorResponse);
    } else {
      // Error Notification
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
        prepareErrorMessage,
        'Oops. Search locations had a problem.',
        error,
      );
      yield put(globalActions.displayErrorToast(errorResponse.message, true));

      // KA event
      fireKAnalyticsError(ERROR_MESSAGES.SEARCH_LOCATION_ERROR, error, errorResponse);
    }

    yield put(actions.searchLocationsFail());
  }
}

function* fetchLocationSaga(action: ReturnType<typeof actions.fetchLocation>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof fetchLocation> = yield call(
      /** @TODO ensure that `id` is defined. */
      // @ts-expect-error
      fetchLocation,
      { id: action.id, lastUpdatedAt: action.lastUpdatedAt },
      { client },
    );

    // Success
    yield put(actions.fetchLocationSuccess(response.data));
  } catch (error) {
    // @ts-expect-error: error is unknown
    if (error?.status === 304) {
      yield put(actions.fetchLocationNotModified());
    } else {
      yield put(actions.searchLocationsFail());
      // Error Notification
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
        prepareErrorMessage,
        'Oops. Fetch location had a problem.',
        error,
      );
      yield put(globalActions.displayErrorToast(errorResponse.message, true));

      // KA event
      fireKAnalyticsError(ERROR_MESSAGES.FETCH_LOCATION_ERROR, error, errorResponse);
    }
  }
}

function* hydrateLocationSaga(action: ReturnType<typeof actions.hydrateLocation>) {
  yield put({ type: actionTypes.LOCATION_SUCCESS, detail: action.location });
}

function* refreshBasketLocationSaga(action: ReturnType<typeof actions.refreshBasketLocation>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof fetchLocation> = yield call(
      /** @TODO ensure that `id` is defined. */
      // @ts-expect-error
      fetchLocation,
      { id: action.id },
      { client },
    );

    // Success
    yield put(basketActions.rehydrateLocalStorageLocation(response.data));
  } catch (error) {
    console.log('Rehydrating local storage location failed');
  }
}

export function* searchDeliveryCoverageSaga(
  action: ReturnType<typeof actions.searchDeliveryCoverage>,
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });

    const response: SagaReturnType<typeof getDeliveryCoverage> = yield call(
      /** @TODO ensure that `address` is defined. */
      // @ts-expect-error
      getDeliveryCoverage,
      action.address,
      { client },
    );
    const location = response.data;
    if (location) {
      // Fire KA Event
      const isDeliveryAddressStored = !!action.address?.id;
      const eventDetails: string = isDeliveryAddressStored
        ? 'Stored address'
        : 'Non-stored address';
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.DELIVERY_ADDRESS_VALIDATED, {
        name: action.location?.label,
        details: eventDetails,
      });

      // Set delivery address on redux and then redirect to the store page.
      yield put(
        conveyanceModeActions.setDeliveryAddress(
          /** @TODO ensure that `address` is defined. */
          // @ts-expect-error
          action.address,
          location,
          false,
          true,
        ),
      );

      // Success
      yield put(actions.searchDeliveryCoverageSuccess());

      // KA event
      genericEventHandler(GlobalEvents.LOCATIONS__SEARCH_LOCATIONS, {
        name: CONVEYANCE_TYPES.DELIVERY,
      });
    }
  } catch (error) {
    // Failure
    yield put(actions.searchDeliveryCoverageFail());
    if ((error as Response).status === 404) {
      // Error Message
      yield put(actions.searchDeliveryCoverageNoResults());

      // KA event
      genericEventHandler(GlobalEvents.LOCATIONS__LOCATION_SEARCH_NOT_FOUND, {
        name: CONVEYANCE_TYPES.DELIVERY,
      });
    } else {
      // Error Notification
      const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
        prepareErrorMessage,
        null,
        error,
      );
      fireKAnalyticsError(ERROR_MESSAGES.SEARCH_DELIVERY_COVERAGE_ERROR, error, errorResponse);
    }
  }
}

export default function* rootSaga() {
  yield takeLatest(actionTypes.FETCH_LOCATIONS, fetchLocationsSaga);
  yield takeLatest(actionTypes.FETCH_ALL_LOCATIONS, fetchAllLocationsSaga);
  yield takeLatest(actionTypes.FETCH_LOCATION, fetchLocationSaga);
  yield takeLatest(actionTypes.FETCH_LOCATION_IF_MODIFIED, fetchLocationSaga);
  yield takeLatest(actionTypes.HYDRATE_LOCATION, hydrateLocationSaga);
  yield takeLatest(actionTypes.SEARCH_LOCATIONS, searchLocationsSaga);
  yield takeLatest(actionTypes.FETCH_MORE_LOCATIONS, fetchMoreLocationsSaga);
  yield takeLatest(actionTypes.REFRESH_BASKET_LOCATION, refreshBasketLocationSaga);
  yield takeLatest(actionTypes.SEARCH_DELIVERY_COVERAGE, searchDeliveryCoverageSaga);
}
