import * as React from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '@/types/app';
import { safelyGetString } from '@/utils/stringHelpers';

interface Props {
  // From Parent
  accessor: string;
  tag?: string | React.FC;
  html?: boolean;
  dataObj?: object;
  disableDataObjEncode?: boolean;
}

const StringAccessor = ({
  accessor,
  html,
  tag,
  dataObj,
  disableDataObjEncode,
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const Element: any = tag;

  const strings = useSelector((state: RootState) => state.app.cmsConfig.strings);
  const str = safelyGetString(strings, accessor, dataObj, disableDataObjEncode); // At worst, this will be an empty string.

  // Ensure we have what we need before returning any JSX
  if (!accessor || !str) {
    return null;
  }

  // See if we need to return HTML
  if (html) {
    return Element ? (
      <Element dangerouslySetInnerHTML={{ __html: str }} />
    ) : (
      <span dangerouslySetInnerHTML={{ __html: str }} />
    );
  }

  // Otherwise, let's try and return a fragment
  return Element ? <Element>{str}</Element> : <>{str}</>;
};

export default StringAccessor;
