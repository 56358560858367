import { takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { K_ANALYTICS_EVENTS } from '@/constants/events';
import { fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

function* setActiveTagSaga(action: ReturnType<typeof actions.setActiveTag>) {
  fireKAnalyticsEvent(K_ANALYTICS_EVENTS.MASTER_CATEGORY_SELECTED, {
    name: action.label,
  });
}

export default function* rootSaga() {
  yield takeLatest(actions.SET_ACTIVE_TAG, setActiveTagSaga);
}
