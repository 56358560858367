import { type DeliveryAddress } from '@koala/sdk';
import { checkDeliveryCoverage } from '@koala/sdk/v4';
import { DELIVERY_TIME_WANTED_MODES, type DeliveryModes } from '@/constants/global';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';

/**
 * Takes the location and a valid address object and checks
 * if the location can delivery to that address. If the response
 * is successful, an object with a key of `can_deliver` is returned as a boolean
 */
export async function addressCheckDeliveryCoverage(location: number, address: DeliveryAddress) {
  const client = createHttpClient({
    origin: getOrigin(window.location.host),
  });

  const response = await checkDeliveryCoverage(location, address, { client });

  return response.data;
}

/**
 * Normalizes the required time wanted fields, including a null or ISO timestamp
 * for `time_wanted` (null is used in place of asap), along with the mode.
 */
export function addressSetTimeWantedFields(timeWanted: string | undefined): {
  time_wanted: string | null;
  time_wanted_mode: DeliveryModes;
} {
  // if `timeWanted` is "asap" or undefined, its considered to be "asap"
  const isASAP = timeWanted === DELIVERY_TIME_WANTED_MODES.ASAP || timeWanted === undefined;

  return {
    time_wanted: isASAP ? null : timeWanted,
    time_wanted_mode: isASAP ? DELIVERY_TIME_WANTED_MODES.ASAP : DELIVERY_TIME_WANTED_MODES.ADVANCE,
  };
}
