import { type MenuInStorage } from '@koala/sdk';
import { type AnyAction } from 'redux';
import basketActions from '../basket/actions';
import actions from './actions';
import { type RootState } from '@/types/app';
import { type IMenuState } from '@/types/menu';
import { setMenuInStorage } from '@/utils/menu';

export const initialState: IMenuState = {
  // menu associated with a localStorage basket, if one exists
  basketMenu: [],
  basketMenuLoading: false,
  navigationStatus: '',
  requestedNavigationUpdate: '',
  loading: undefined,
};

export const menu = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_BASKET_MENU_FROM_STORAGE:
      return Object.assign({}, state, {
        basketMenu: action.menuCategories,
      });

    // Cases that set the menu associated with the current basket in redux
    case actions.FETCH_BASKET_MENU:
      return Object.assign({}, state, {
        basketMenuLoading: true,
      });
    case actions.SET_BASKET_MENU:
    case basketActions.INIT_NEW_BASKET:
    case basketActions.REPLACE_BASKET:
      // Set either the compressed menu or the normal menu, depending on the config
      const menu: MenuInStorage = {
        compressed: true,
        categories: action.compressedCategories,
      };

      // Set menu in session storage
      setMenuInStorage(menu);

      return Object.assign({}, state, {
        basketMenu: action.menuCategories,
        basketMenuLoading: false,
      });
    case actions.FETCH_BASKET_MENU_ERROR:
      return Object.assign({}, state, {
        basketMenuLoading: false,
      });
    case actions.PUSH_NAVIGATION_STATUS:
      return {
        ...state,
        navigationStatus: action.categoryId,
      };
    case actions.REQUEST_NAVIGATION_UPDATE:
      return {
        ...state,
        requestedNavigationUpdate: action.categoryId,
      };
    default:
      return state;
  }
};

export const selectMenuSlice = (state: RootState) => state.app.menu;
