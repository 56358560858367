import { HYDRATE } from 'next-redux-wrapper';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { type AnyAction, combineReducers } from 'redux';
import formReducer from 'redux-form/lib/reducer';
import { allergens } from './allergens/reducer';
import { arrivalStatus } from './arrivalStatus/reducer';
import { auth } from './auth/reducer';
import { basket } from './basket/reducer';
import { basketRewards } from './basketRewards/reducer';
import { paymentHandler } from './checkout/paymentHandler/reducer';
import { cmsConfig } from './cmsConfig/reducer';
import { conveyanceMode } from './conveyanceMode/reducer';
import { customize } from './customize/reducer';
import { deliveryStatus } from './deliveryStatus/reducer';
import { global } from './global/reducer';
import { locations } from './locations/reducer';
import { me } from './me/reducer';
import { menu } from './menu/reducer';
import { orderDetails } from './orderDetails/reducer';
import { orderStatus } from './orderStatus/reducer';
import { organization } from './organization/reducer';
import { payment } from './payment/reducer';
import { paymentTypes } from './paymentTypes/reducer';
import { promoCode } from './promoCode/reducer';
import { tags } from './tags/reducer';
import { timeslots } from './timeslots/reducer';
import { upsells } from './upsells/reducer';
import { type AppState, type RootState } from '@/types/app';

const combinedReducers = combineReducers<RootState>({
  app: combineReducers<AppState>({
    allergens,
    arrivalStatus,
    auth,
    // TODO: Reconcile SDK & Store basket state.
    // @ts-expect-error: Reconcile SDK & Store basket state.
    basket,
    basketRewards,
    cmsConfig,
    conveyanceMode,
    customize,
    deliveryStatus,
    global,
    locations,
    me,
    menu,
    orderDetails,
    orderStatus,
    organization,
    payment,
    paymentHandler,
    paymentTypes,
    promoCode,
    tags,
    timeslots,
    upsells,
  }),
  form: formReducer,
  loadingBar: loadingBarReducer,
});

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type === HYDRATE) {
    /**
     * next-redux-wrapper will override the entire store when
     * navigating between serverSideProp powered routes
     */

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const nextState = {
      ...state,
      ...action.payload,
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return nextState;
  } else {
    return combinedReducers(state, action);
  }
};

// Combines all reducers to a single reducer function
export default rootReducer;
