import { addRewards, removeReward, getAppliedRewards, getBasketRewards } from '@koala/sdk/v4';
import { call, put, select, takeLatest, type SagaReturnType, all } from 'redux-saga/effects';
import actions from './actions';
import { BASKET_REWARDS_ERROR_STATUS } from '@/constants/basketRewards';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { LOYALTY_FEATURES } from '@/constants/loyalty';
import basketActions from '@/redux/basket/actions';
import orderStatusActions from '@/redux/orderStatus/actions';
import { createHttpClient } from '@/services/client';
import { type RootState } from '@/types/app';
import { OrderStatusMessages } from '@/types/orderStatus';
import { getOrigin } from '@/utils';
import { getIdsFromState } from '@/utils/checkout';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';
import { getLoyaltyInfoFromState } from '@/utils/loyalty';

/** Get basket rewards */
export function* getRewardsSaga() {
  yield put(orderStatusActions.basketRewardsGet());

  try {
    const state: RootState = yield select();
    const organization = state.app.organization;

    // If loyalty is enabled
    if (organization.organization.loyalty_driver_id) {
      // Only fetch available rewards if the loyalty provider supports it
      const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
        yield select(getLoyaltyInfoFromState);
      if (!loyaltyFeatureFlags?.[LOYALTY_FEATURES.GET_AVAILABLE_REWARDS]) {
        return;
      }

      const { basketId, locationId } = getIdsFromState(state);
      const client = createHttpClient({
        origin: getOrigin(window.location.host),
      });
      const [availableRewards, appliedRewards]: [
        SagaReturnType<typeof getBasketRewards>,
        SagaReturnType<typeof getAppliedRewards>,
      ] = yield all([
        call(getBasketRewards, { basketId, locationId }, { client }),
        call(getAppliedRewards, { basketId, locationId }, { client }),
      ]);

      yield all([
        put(actions.setAvailableRewards(availableRewards)),
        put(actions.setAppliedRewards(appliedRewards)),
      ]);
    }
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_REWARDS_ERROR, error, errorResponse);
  }
}

/**
 * Add basket rewards
 *
 */
export function* addRewardSaga(action: ReturnType<typeof actions.addReward>) {
  yield put(orderStatusActions.basketRewardsAdd());
  yield put(actions.setPendingReward(action?.reward?.id));
  try {
    const state: RootState = yield select();
    const { basketId, locationId } = getIdsFromState(state);

    // Get loyalty feature flags
    const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
      yield select(getLoyaltyInfoFromState);

    // Check if loyalty provider supports applying/destroying a reward or getting all applied rewards
    if (
      !loyaltyFeatureFlags?.[LOYALTY_FEATURES.APPLY_REWARDS] ||
      !loyaltyFeatureFlags?.[LOYALTY_FEATURES.GET_APPLIED_REWARDS] ||
      !loyaltyFeatureFlags?.[LOYALTY_FEATURES.DESTROY_REWARD]
    ) {
      return;
    }

    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof addRewards> = yield call(
      addRewards,
      {
        basketId,
        rewardCodes: [action.reward.id.toString()],
        locationId,
        validate: true,
      },
      { client },
    );
    yield put(basketActions.success(response));
    const appliedRewards: SagaReturnType<typeof getAppliedRewards> = yield call(
      getAppliedRewards,
      { basketId, locationId },
      { client },
    );

    // If validation works, apply the reward
    yield put(actions.setAppliedRewards(appliedRewards));

    // Fire event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.REWARD_APPLIED, {
      name: action.reward.label,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.BASKET_REWARDS_ADDING_ERROR,
      error,
    );

    if (errorResponse?.error?.error === BASKET_REWARDS_ERROR_STATUS.UNAVAILABLE) {
      yield put(actions.setUnavailableReward(action.reward));
    } else {
      yield put(orderStatusActions.orderStatusMessageErrorSet(errorResponse.message));
    }

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.ADD_REWARDS_FAILURE, error, errorResponse);
  }

  // Reset order status
  yield put(actions.setPendingReward(null));
  yield put(orderStatusActions.orderPending());
}

export function* removeRewardSaga(action: ReturnType<typeof actions.removeReward>) {
  yield put(orderStatusActions.basketRewardsRemove());

  try {
    const state: RootState = yield select();
    const { basketId, locationId } = getIdsFromState(state);

    // Get loyalty feature flags
    const { loyaltyFeatureFlags }: ReturnType<typeof getLoyaltyInfoFromState> =
      yield select(getLoyaltyInfoFromState);

    // Check if loyalty provider supports destroying a reward or getting all applied rewards
    if (
      !loyaltyFeatureFlags?.[LOYALTY_FEATURES.GET_APPLIED_REWARDS] ||
      !loyaltyFeatureFlags?.[LOYALTY_FEATURES.DESTROY_REWARD]
    ) {
      return;
    }

    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    yield call(
      removeReward,
      { basketId, rewardCode: action.reward.id.toString(), locationId },
      { client },
    );

    const appliedRewards: SagaReturnType<typeof getAppliedRewards> = yield call(
      getAppliedRewards,
      { basketId, locationId },
      { client },
    );
    yield put(actions.setAppliedRewards(appliedRewards));

    // Success
    yield put(orderStatusActions.orderPending());
    yield put(actions.resetUnavailableRewards());

    // Silently validate basket
    yield put(orderStatusActions.validateOrder(basketId, locationId));

    // Events
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.REWARD_REMOVED, {
      name: action.reward.label,
    });
  } catch (error) {
    // Error Notification
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      OrderStatusMessages.BASKET_REWARDS_REMOVING_ERROR,
      error,
    );
    yield put(orderStatusActions.orderStatusMessageErrorSet(errorResponse.message));

    // KA event
    fireKAnalyticsError(ERROR_MESSAGES.REMOVE_REWARDS_FAILURE, error, errorResponse);
  }

  yield put(orderStatusActions.orderPending());
}

function* resetRewardSaga() {
  const state: RootState = yield select();

  const { rewardsApplied } = state.app.basketRewards;

  yield rewardsApplied.forEach((reward) => {
    put(actions.removeReward(reward));
  });
}

export default function* rootSaga() {
  yield takeLatest(actions.GET_REWARDS, getRewardsSaga);
  yield takeLatest(actions.ADD_REWARD, addRewardSaga);
  yield takeLatest(actions.REMOVE_REWARD, removeRewardSaga);
  yield takeLatest(actions.RESET_BASKET_REWARDS, resetRewardSaga);
}
