import { type Basket, type BasketOrder, type OrderDetails, type RewardItem } from '@koala/sdk';
import {
  API_CONVEYANCE_TYPES,
  BASKET_ORDER_KEYS,
  ERROR_MESSAGES,
  K_ANALYTICS_EVENTS,
} from '@/constants/events';
import { type RootState } from '@/types/app';
import { type IPromoCodeState } from '@/types/promoCode';
import { getISODateInUTC } from '@/utils/dates';
import { fireGenericOrderEventsHandler } from '@/utils/events';
import { coerceBasketProductsForKA, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

/**
 * Koala Add Products
 * - FIRST_PRODUCT_ADDED
 * - BASKET_UPDATE
 *
 */
export const addProductEventsKoala = (basketOrder: BasketOrder) => {
  let kaEvent: string = K_ANALYTICS_EVENTS.BASKET_UPDATE;

  // KA Event if we are adding first item in basket
  if (basketOrder.basket_items.length === 1) {
    kaEvent = K_ANALYTICS_EVENTS.FIRST_PRODUCT_ADDED;
  }

  fireGenericOrderEventsHandler(kaEvent, basketOrder, null, [BASKET_ORDER_KEYS.BASKET_PRODUCTS]);
};

/**
 * Koala Remove Products
 * - BASKET_UPDATE
 *
 */
export const removeProductEventsKoala = (basketOrder: BasketOrder) => {
  fireGenericOrderEventsHandler(K_ANALYTICS_EVENTS.BASKET_UPDATE, basketOrder, null, [
    BASKET_ORDER_KEYS.BASKET_PRODUCTS,
  ]);
};

/**
 * completeOrderEventsKoala
 *
 * Passes slightly modified data, needs to be separate function from generic handler
 */
export const basketStagedEventsKoala = (
  basketContent: BasketOrder,
  checkoutBasket: Basket,
  conveyanceType: string,
) => {
  try {
    // Koala Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.BASKET_STAGED, {
      order: {
        promo_discount: checkoutBasket?.promo_discount,
        promo_code_applied: false, // will always be false at time of BASKET_STAGED
        reward_applied: false, // will always be false at time of BASKET_STAGED
        id: checkoutBasket?.id,
        order_number: null,
        ready_time: null,
        subtotal: checkoutBasket?.subtotal,
        sales_tax: [
          {
            label: 'Tax',
            tax_cents: checkoutBasket?.sales_tax,
          },
        ],
        ordered_at: null,
        total: checkoutBasket?.total,
        // @ts-expect-error `conveyanceType` can't index API_CONVEYANCE_TYPES.
        conveyance_type: API_CONVEYANCE_TYPES[conveyanceType.toUpperCase()],
      },
      basket_products: coerceBasketProductsForKA(basketContent.basket_items),
    });
  } catch (e) {
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.BASKET_STAGED_EVENTS_ERROR,
      details: (e as Error).message,
    });
  }
};

/**
 * completeOrderEventsKoala
 *
 * Takes nextProps and distributes the data across
 * Google analytics, Google Commerce, and Koala Analytics events
 */
export const completeOrderEventsKoala = (state: RootState, orderDetails: OrderDetails) => {
  try {
    const basketContent: BasketOrder = state.app.basket.content;
    const promoCode: IPromoCodeState = state.app.promoCode;
    const rewards: RewardItem[] = state.app.basketRewards.rewardsApplied;
    const table_number =
      // @ts-expect-error `table_tent_number` could exist if conveyance_type is `dine_in`.
      orderDetails.order_data.basket.conveyance_type.table_tent_number || null;

    // Koala Event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ORDER_SUBMITTED, {
      details: (table_number && `Table number: ${table_number}`) || null,
      transaction: {
        id: orderDetails.id, // is this correct?
        time_taken: null, // optional
        type: orderDetails.order_data.payment?.map((payment) => payment.type),
      },
      order: {
        promo_discount: orderDetails.order_data.order.promo_discount,
        promo_code_applied: !!promoCode.applied,
        reward_applied: !!rewards.length,
        id: orderDetails.order_id,
        order_number: orderDetails.order_data.order.order_number,
        ready_time: getISODateInUTC(orderDetails.order_data.order.ready_at),
        subtotal: orderDetails.order_data.order.subtotal,
        sales_tax: [
          {
            label: 'Tax',
            tax_cents: orderDetails.order_data.order.sales_tax,
          },
        ],
        ordered_at: getISODateInUTC(orderDetails.order_data.order.ordered_at),
        total: orderDetails.order_data.order.total,
        conveyance_type: orderDetails.order_data.basket.conveyance_type.type,
        table_tent_number:
          // @ts-expect-error `table_tent_number` could exist if conveyance_type is `dine_in`.
          orderDetails.order_data.basket.conveyance_type.table_tent_number,
        wanted_at: orderDetails.order_data.basket.wanted_at,
      },
      basket_products: coerceBasketProductsForKA(basketContent.basket_items),
    });
  } catch (e) {
    // KA event
    fireKAnalyticsEvent(K_ANALYTICS_EVENTS.ERROR, {
      name: ERROR_MESSAGES.SUBMIT_EVENTS_ERROR,
      details: (e as Error).message,
    });
  }
};
