import { type Strings, type WebConfig } from '@koala/sdk';
import { useSelector } from '@/redux';

export function useWebConfig() {
  return useSelector((state) => state.app.cmsConfig.webConfig);
}

export function useConfigOverride<T extends keyof WebConfig>(slice: T) {
  const config = useWebConfig();
  return config[slice];
}

export function useStrings() {
  return useSelector((state) => state.app.cmsConfig.strings);
}

export function useStringsOverride<T extends keyof Strings>(slice: T) {
  const strings = useStrings();
  return strings[slice];
}
