import { getSupportedPaymentTypes } from '@koala/sdk/v4';
import { call, put, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import actions from './actions';
import { ERROR_MESSAGES } from '@/constants/events';
import orderStatusActions from '@/redux/orderStatus/actions';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError } from '@/utils/koalaAnalytics';

/**
 * Get payment
 *
 */
function* paymentTypesGetSaga(action: ReturnType<typeof actions.paymentTypesGet>) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getSupportedPaymentTypes> = yield call(
      getSupportedPaymentTypes,
      { locationId: action.locationId, basketId: action.basketId },
      { client },
    );
    yield put(actions.paymentTypesGetSuccess(response));
    yield put(orderStatusActions.orderPending());
  } catch (error) {
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.PAYMENT_TYPES_GET_ERROR, error, errorResponse);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.PAYMENT_TYPES_GET, paymentTypesGetSaga);
}
