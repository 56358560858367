import { sdkUtils, type BasketOrder, type Category, type OrderDetails } from '@koala/sdk';
import get from 'lodash/get';
import { BASKET_ORDER_KEYS } from '@/constants/events';
import { type IEventDetails } from '@/types/analytics';
import { getISODateInUTC } from '@/utils/dates';
import { coerceBasketProductsForKA, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

// Creates `transaction` object used in `fireGenericOrderEventsHandler` below
const transactionHandler = (orderDetails: OrderDetails) => {
  return {
    id: orderDetails.id, // is this correct?
    card_brand: orderDetails.order_data.order.payment_card_type,
    last_four_digits: orderDetails.order_data.order.payment_card,
    time_taken: null, // optional
  };
};

// Creates `order` object used in `fireGenericOrderEventsHandler` below
const orderHandler = (orderDetails: OrderDetails) => {
  return {
    promo_discount: orderDetails.order_data.order.promo_discount,
    id: orderDetails.order_id,
    order_number: orderDetails.order_data.order.order_number,
    ready_time: getISODateInUTC(orderDetails.order_data.order.ready_at),
    subtotal: orderDetails.order_data.order.subtotal,
    sales_tax: [
      {
        label: 'Tax',
        tax_cents: orderDetails.order_data.order.sales_tax,
      },
    ],
    ordered_at: getISODateInUTC(orderDetails.order_data.order.ordered_at),
    total: orderDetails.order_data.order.total,
    conveyance_type: orderDetails.order_data.basket.conveyance_type.type,
  };
};

/*
Use this method for any events that pass the generic `transaction`, `order`, and `basket_products` objects with NO modifications
*/
export const fireGenericOrderEventsHandler = (
  eventName: string,
  basketContent: BasketOrder,
  orderDetails: OrderDetails | null,
  dataObjects?: string[],
  details?: string,
) => {
  try {
    const customizedDataObject: IEventDetails = {};
    // Build customized data object based on the keys we pass in
    dataObjects?.map((entry) => {
      switch (entry) {
        case BASKET_ORDER_KEYS.TRANSACTION:
          // @ts-expect-error string can't index `customizedDataObject`.
          customizedDataObject[entry] = transactionHandler(orderDetails);
          break;
        case BASKET_ORDER_KEYS.ORDER:
          // @ts-expect-error string can't index `customizedDataObject`.
          customizedDataObject[entry] = orderHandler(orderDetails);
          break;
        case BASKET_ORDER_KEYS.BASKET_PRODUCTS:
          // @ts-expect-error string can't index `customizedDataObject`.
          customizedDataObject[entry] = coerceBasketProductsForKA(basketContent.basket_items);
          break;
      }
    });

    // If we have optional 'details', include them here
    if (details) {
      customizedDataObject.details = details;
    }

    // Koala Event with passing our customized fields
    fireKAnalyticsEvent(eventName, customizedDataObject);
  } catch (e) {
    console.log('Error dispatching checkout events.');
    console.log(e);
  }
};

// Loop through the options recursively every time we fetch a menu and set the object on the KA class
export const objectifyOptions = (menuCategories: Category[]) => {
  // Only run if we're on the client
  if (typeof window === 'undefined') {
    return;
  }

  const optionsObject = sdkUtils.objectifyOptionsMenuLevel(menuCategories);

  // Set our new optionObjects on our Koala Analytics class
  if (Object.keys(optionsObject).length && 'KoalaAnalytics' in window) {
    window.KoalaAnalytics.setMenuProductOptions(optionsObject);
  }
};

// Find specific value from an array for Analytics
export const safelyGetValueForKA = (
  dataArray: object[],
  keyToCompare1: string,
  keyToCompare2: string | number,
  keyToReturn: string,
) => {
  // Find entry from the array using our two keys to compare - i.e. IDs
  const newEntry = dataArray.find(
    // @ts-expect-error string can't index `entry` object.
    (entry) => entry[keyToCompare1].toString() === keyToCompare2.toString(),
  );

  if (newEntry) {
    // If entry is found, search for the key we want to return - i.e. label
    return get(newEntry, `${keyToReturn}`, null);
  }
  return null;
};
