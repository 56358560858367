export enum InitializationSteps {
  CREATE_BASKET = 'CREATE_BASKET',
  SET_WANTED_AT = 'SET_WANTED_AT',
  SET_CONVEYANCE = 'SET_CONVEYANCE',
  VALIDATE = 'VALIDATE',
}

export enum InitializationErrorTypes {
  THROTTLING = 'throttling',
  HANDLED = 'handled',
  UNHANDLED = 'unhandled',
  LOCK = 'lock',
  PHONE = 'phone',
}

export const INITIALIZATION_ERRORS: Record<string, InitializationErrorTypes> = {
  // THROTTLING
  // GOOP
  'There are lots of orders coming in': InitializationErrorTypes.THROTTLING,
  'please choose another time': InitializationErrorTypes.THROTTLING,
  "There are lots of orders coming in at goop Kitchen - Santa Monica and we can't start your order quite yet. Thank you for your patience and understanding. Please try again later.":
    InitializationErrorTypes.THROTTLING,
  // MOD
  'MOD Pizza is currently experiencing high order volume for this location. Please select a later time.':
    InitializationErrorTypes.THROTTLING,
  // P.F. Changs
  'experiencing a high order volume and cannot immediately start your order. Please select another time for pickup or delivery.':
    InitializationErrorTypes.THROTTLING,
  // Pancheros
  'is experiencing high order volume and cannot immediately start your order.':
    InitializationErrorTypes.THROTTLING,
  'high order volume and cannot prepare your order': InitializationErrorTypes.THROTTLING, // Pancheros - Shelton is experiencing high order volume and cannot prepare your order for 5:45 PM.
  // Scratch
  "We're sorry your order cannot be placed. The kitchen is really busy right now so please choose a time slot at least 30 minutes from now for us to prepare your food.":
    InitializationErrorTypes.THROTTLING,
  // Global
  "Sorry, it looks like your delivery destination is out of range, or there isn't an available driver for the delivery time you selected. Please schedule your delivery for another time or place your order for pickup.":
    InitializationErrorTypes.THROTTLING,
  'Delivery is not currently available to your address. Please try again later or order for Pickup!':
    InitializationErrorTypes.THROTTLING,
  'No delivery services are available to handle this order at this time.':
    InitializationErrorTypes.THROTTLING,
  'Please schedule your order for another time or select another order type.':
    InitializationErrorTypes.THROTTLING,
  'Please schedule your order for another time.': InitializationErrorTypes.THROTTLING,
  'A future date is required.': InitializationErrorTypes.THROTTLING,
  'There was an issue setting your desired ready time.': InitializationErrorTypes.THROTTLING,
  // HANDLED
  // KOALA
  'Please ensure that your delivery zip code is not longer than 6 digits.':
    InitializationErrorTypes.HANDLED,
  // OLO
  'More than one result was returned for this address. Please make this address as specific as possible.':
    InitializationErrorTypes.HANDLED,
  'not available for delivery orders': InitializationErrorTypes.HANDLED,
  'currently unavailable.': InitializationErrorTypes.HANDLED,
  'Choose between': InitializationErrorTypes.HANDLED, // Choose between 1 and 9 options for 'Dressings'
  "Your address is either outside of range or a driver isn't available at this time. Please select another method to receive your order.":
    InitializationErrorTypes.HANDLED,
  'A delivery address is required for this order.': InitializationErrorTypes.HANDLED,
  "We're sorry, some items in your cart are no longer available. Please review your cart and try again.":
    InitializationErrorTypes.HANDLED,
  "We're sorry - you're attempting to order delivery from too far away. You can still place your order for pick-up!":
    InitializationErrorTypes.HANDLED,
  'You have selected an item that is temporarily unavailable for online ordering.':
    InitializationErrorTypes.HANDLED,
  'remove any unavailable items': InitializationErrorTypes.HANDLED, // Apologies, there is a problem on this order. Please update your order to remove any unavailable items to try this order again. "Extra Sweet Chili Dipping Sauce" is not currently available on "5 Dumplings".
  'Only orders above': InitializationErrorTypes.HANDLED, // Only orders above {amount} can be delivered. Your order was not placed and you will not be billed.
  // LOCK
  "Oops, we're having trouble loading this page. Please try again soon":
    InitializationErrorTypes.LOCK,
  'Bad Gateway': InitializationErrorTypes.LOCK,
  'The special instructions must be a string., The location.id field is required when from order id is not present., The basket items field is required when from order id is not present.':
    InitializationErrorTypes.LOCK,
  'Unable to set conveyance': InitializationErrorTypes.LOCK,
  // PHONE
  'The phone number field is required.': InitializationErrorTypes.PHONE,
};

/** In Milliseconds. */
export const DELIVERY_STATUS_POLLING_INTERVAL = 60000;

export const DRIVER_NAME_DEFAULT_VALUE = 'Your Driver';
