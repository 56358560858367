import { type QueryClient } from '@tanstack/react-query';

export type CONFIG_TYPES = 'webConfig' | 'strings';

export type GLOBAL_CONFIG_KEYS = 'fetchClient' | 'fetchServer';

const cmsConfigActions = {
  // Fetch all app configs
  FETCH_CONFIGS: '/koala/cmsConfig/FETCH_CONFIGS',
  FETCH_CONFIGS_SUCCESS: '/koala/cmsConfig/FETCH_CONFIGS_SUCCESS',
  FETCH_CONFIGS_FAILURE: '/koala/cmsConfig/FETCH_CONFIGS_FAILURE',

  // Fetch a single app config
  FETCH_CONFIG: '/koala/cmsConfig/FETCH_CONFIG',

  fetchConfigs: (clientOrServer: GLOBAL_CONFIG_KEYS, queryClient: QueryClient) => ({
    type: cmsConfigActions.FETCH_CONFIGS,
    clientOrServer,
    queryClient,
  }),
  fetchConfigsSuccess: (
    clientOrServer: GLOBAL_CONFIG_KEYS,
    configType: CONFIG_TYPES,
    configContent: any,
  ) => ({
    type: cmsConfigActions.FETCH_CONFIGS_SUCCESS,
    clientOrServer,
    configType,
    configContent,
  }),
  fetchConfigsFailure: (clientOrServer: string) => ({
    type: cmsConfigActions.FETCH_CONFIGS_FAILURE,
    clientOrServer,
  }),
};

export default cmsConfigActions;
