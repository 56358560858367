import { type MenuProduct } from '@koala/sdk';

export const IMAGE_RESIZER = 'https://media.koala.io/resize';

/**
 * Get the correct image source for a product.
 *
 * If image property exists and equal to image_url_1_by_1 that it means that image property is set by provider
 * and we should default to it without resizing.
 *
 * If image property is not equal to image_url_1_by_1 that it means that image property does not exist
 * OR image_url_1_by_1 is set in CMS
 * and should be set as default and resized.
 *
 * @param product Menu or Basket product object
 * @param resizeOptions  Options to pass to the image resizer
 */
export const getProductImageSrc = (product: MenuProduct, resizeOptions: ResizeImageOptions) => {
  const { image, images } = product;
  if (image && images?.image_url_1_by_1 === image) {
    return image;
  } else {
    return resizeImage(images?.image_url_1_by_1, resizeOptions);
  }
};

export const hasProductImage = (product: MenuProduct) => {
  const { image, images } = product;

  return Boolean(image || images?.image_url_1_by_1);
};

interface ResizeImageOptions {
  height?: number;
  width?: number;
  quality?: number;
  format?: 'auto' | 'webp' | 'jpeg' | 'png';
}

/** Take a url and feed it to the image resizer.  */
export function resizeImage(
  url?: string | null,
  { height, width, quality = 85, format = 'auto' }: ResizeImageOptions = {},
): string {
  // Ensure url exists
  if (!url) {
    return '';
  }

  // Don't try to resize vector graphics like SVGs & PDFs
  if (url.endsWith('.svg') || url.endsWith('.pdf')) {
    return url;
  }

  const params = Object.entries({ height, width, quality, format })
    .filter(([_k, v]) => !!v)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');

  return `${IMAGE_RESIZER}?source=${url}&${params}`;
}
