export const CHECKOUT_AUTH_TYPES = {
  DEFAULT: 'default',
  LOGIN: 'login',
  SIGNUP: 'signup',
  GUEST: 'guest',
  USER_INFO: 'user info',
  UNVERIFIED_EMAIL: 'unverified_email',
};

export enum PRODUCT_LOCATION_LABELS {
  BASKET = 'Basket',
  CROSS_SELL = 'Cross-sell',
  MENU = 'Menu',
}
