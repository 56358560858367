import { type TagItem } from '@koala/sdk';

const tagsActions = {
  SET_ACTIVE_TAG: '/koala/tags/SET_ACTIVE_TAG',

  setActiveTag: (tag: TagItem) => ({
    type: tagsActions.SET_ACTIVE_TAG,
    id: tag.id,
    label: tag.label,
  }),
};

export default tagsActions;
