/* eslint-disable import/export */
/** Disabled because we're intentionally overriding `useSelector`. */

import { type TypedUseSelectorHook, useSelector } from 'react-redux';
import { type RootState } from '@/types/app';

// Automatically annotate the useSelector hook with our RootState type.
const typedUseSelector: TypedUseSelectorHook<RootState> = useSelector;

// re-export everything from React-Redux.
export * from 'react-redux';
// ...but override useSelector with our typed version.
export { typedUseSelector as useSelector };
