import { type ArrivalResponse, type OrderDetails } from '@koala/sdk';

const arrivalStatusActions = {
  FETCH_ARRIVAL_STATUS: '/koala/basket/FETCH_ARRIVAL_STATUS',
  FETCH_ARRIVAL_STATUS_SUCCESS: '/koala/basket/FETCH_ARRIVAL_STATUS_SUCCESS',
  FETCH_ARRIVAL_STATUS_FAILURE: '/koala/basket/FETCH_ARRIVAL_STATUS_FAILURE',
  UPDATE_ARRIVAL_STATUS: '/koala/basket/UPDATE_ARRIVAL_STATUS',
  UPDATE_ARRIVAL_STATUS_SUCCESS: '/koala/basket/UPDATE_ARRIVAL_STATUS_SUCCESS',
  UPDATE_ARRIVAL_STATUS_FAILURE: '/koala/basket/UPDATE_ARRIVAL_STATUS_FAILURE',
  CLEAR_ARRIVAL_STATUS: '/koala/basket/CLEAR_ARRIVAL_STATUS',

  fetchArrivalStatus: (orderId: string, label: string) => ({
    type: arrivalStatusActions.FETCH_ARRIVAL_STATUS,
    orderId,
    label,
  }),
  fetchArrivalStatusSuccess: (orderDetails: OrderDetails, label: string) => ({
    type: arrivalStatusActions.FETCH_ARRIVAL_STATUS_SUCCESS,
    orderDetails,
    label,
  }),
  fetchArrivalStatusError: (error: boolean) => ({
    type: arrivalStatusActions.FETCH_ARRIVAL_STATUS_FAILURE,
    error,
  }),
  updateArrivalStatus: (orderId: string, label: string, externalOrder?: boolean) => ({
    type: arrivalStatusActions.UPDATE_ARRIVAL_STATUS,
    orderId,
    label,
    externalOrder,
  }),
  updateArrivalStatusSuccess: (arrivalResponse: ArrivalResponse, label: string) => ({
    type: arrivalStatusActions.UPDATE_ARRIVAL_STATUS_SUCCESS,
    arrivalResponse,
    label,
  }),
  updateArrivalStatusFail: () => ({
    type: arrivalStatusActions.UPDATE_ARRIVAL_STATUS_FAILURE,
  }),
  clearArrivalStatus: () => ({
    type: arrivalStatusActions.CLEAR_ARRIVAL_STATUS,
  }),
};

export default arrivalStatusActions;
