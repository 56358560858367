import {
  type LoyaltyUserUpdateInfo,
  type RewardItem,
  type OrderDetails,
  type RedemptionItem,
} from '@koala/sdk';

const meActions = {
  FETCH_ME: '/koala/me/FETCH_ME',
  ME_SUCCESS: '/koala/me/ME_SUCCESS',
  ME_FAIL: '/koala/me/ME_FAIL',
  CLEAR_ME: 'koala/me/CLEAR_ME',
  ME_STOP_LOADING: 'koala/me/ME_STOP_LOADING',

  UPDATE_ME: '/koala/me/UPDATE_ME',
  UPDATE_ME_SUCCESS: '/koala/me/UPDATE_ME_SUCCESS',
  UPDATE_ME_FAIL: '/koala/me/UPDATE_ME_FAIL',

  FETCH_ME_FAVORITE_LOCATIONS: '/koala/me/FETCH_ME_FAVORITE_LOCATIONS',
  DELETE_ME_FAVORITE_LOCATION_SUCCESS: '/koala/FAVORITE_LOCATION_SUCCESS',
  DELETE_ME_FAVORITE_LOCATION_FAIL: '/koala/FAVORITE_LOCATION_FAIL',
  ME_FAVORITE_LOCATIONS_SUCCESS: '/koala/me/ME_FAVORITE_LOCATIONS_SUCCESS',
  ADD_FAVORITE_LOCATION: '/koala/me/ADD_FAVORITE_LOCATION',
  REMOVE_FAVORITE_LOCATION: '/koala/me/REMOVE_FAVORITE_LOCATION',

  FETCH_ME_FAVORITE_ORDERS: '/koala/me/FETCH_ME_FAVORITE_ORDERS',
  FETCH_ME_FAVORITE_ORDERS_SUCCESS: '/koala/me/FETCH_ME_FAVORITE_ORDERS_SUCCESS',
  DELETE_ME_FAVORITE_ORDER_SUCCESS: '/koala/FAVORITE_ORDER_SUCCESS',
  DELETE_ME_FAVORITE_ORDER_FAIL: '/koala/FAVORITE_ORDER_FAIL',
  ADD_FAVORITE_ORDER: '/koala/me/ADD_FAVORITE_ORDER',
  REMOVE_FAVORITE_ORDER: '/koala/me/REMOVE_FAVORITE_ORDER',
  ADD_ME_FAVORITE_ORDER_SUCCESS: '/koala/FAVORITE_ORDER_SUCCESS',
  ADD_ME_FAVORITE_ORDER_FAIL: '/koala/FAVORITE_ORDER_FAIL',

  FETCH_ME_PAST_ORDERS: '/koala/me/FETCH_ME_PAST_ORDERS',
  FETCH_ME_PAST_ORDERS_SUCCESS: '/koala/me/FETCH_ME_PAST_ORDERS_SUCCESS',

  FETCH_ME_ORDER_DETAILS: '/koala/me/FETCH_ME_ORDER_DETAILS',
  FETCH_ME_ORDER_DETAILS_SUCCESS: '/koala/me/FETCH_ME_ORDER_DETAILS_SUCCESS',
  FETCH_ME_ORDER_DETAILS_FAIL: '/koala/me/FETCH_ME_ORDER_DETAILS_FAIL',
  CLEAR_ME_ORDER_DETAILS: '/koala/me/CLEAR_ME_ORDER_DETAILS',
  TOGGLE_REORDER_RECEIPT: '/koala/me/TOGGLE_REORDER_RECEIPT',

  FETCH_ME_CREDIT_CARDS: '/koala/me/FETCH_ME_CREDIT_CARDS',
  FETCH_ME_CREDIT_CARDS_SUCCESS: '/koala/FETCH_ME_CREDIT_CARDS_SUCCESS',
  FETCH_ME_CREDIT_CARDS_FAIL: '/koala/FETCH_ME_CREDIT_CARDS_FAIL',
  FETCH_ALL_LOCATIONS_CREDIT_CARDS: '/koala/me/FETCH_ALL_LOCATIONS_CREDIT_CARDS',
  FETCH_ALL_LOCATIONS_CREDIT_CARDS_SUCCESS: '/koala/FETCH_ALL_LOCATIONS_CREDIT_CARDS_SUCCESS',
  DELETE_ME_CREDIT_CARD: '/koala/DELETE_ME_CREDIT_CARD',
  DELETE_ME_CREDIT_CARD_SUCCESS: '/koala/CREDIT_CARD_SUCCESS',
  DELETE_ME_CREDIT_CARD_FAIL: '/koala/CREDIT_CARD_FAIL',
  SET_ME_CREDIT_CARDS_LOADING_TO_INIT_STATE: '/koala/me/SET_ME_CREDIT_CARDS_LOADING_TO_INIT_STATE',

  FETCH_OFFERS: '/koala/me/FETCH_OFFERS',
  FETCH_OFFERS_SUCCESS: '/koala/FETCH_OFFERS_SUCCESS',

  FETCH_ADDRESSES: '/koala/me/FETCH_ADDRESSES',
  FETCH_ADDRESSES_SUCCESS: '/koala/FETCH_ADDRESSES_SUCCESS',
  DELETE_ADDRESS: '/koala/me/DELETE_ADDRESS',
  DEFAULT_ADDRESS: '/koala/me/DEFAULT_ADDRESS',
  TOGGLE_STORED_ADDRESSES_MENU: '/koala/me/TOGGLE_STORED_ADDRESSES_MENU',
  SET_ACTIVE_STORED_ADDRESS: '/koala/me/SET_ACTIVE_STORED_ADDRESS',

  APPEND_USER_PHONE: '/koala/me/APPEND_USER_PHONE',

  // Punchh V2 only
  FETCH_LOYALTY_STATE: '/koala/me/FETCH_LOYALTY_STATE',
  FETCH_LOYALTY_STATE_SUCCESS: '/koala/me/FETCH_LOYALTY_STATE_SUCCESS',
  FETCH_REDEEMABLES: '/koala/me/FETCH_REDEEMABLES',
  FETCH_REDEEMABLES_SUCCESS: '/koala/me/FETCH_REDEEMABLES_SUCCESS',
  FETCH_MESSAGES: '/koala/me/FETCH_MESSAGES',
  FETCH_MESSAGES_SUCCESS: '/koala/me/FETCH_MESSAGES_SUCCESS',
  FETCH_REWARDS_HISTORY: '/koala/me/FETCH_REWARDS_HISTORY',
  FETCH_REWARDS_HISTORY_SUCCESS: '/koala/me/FETCH_REWARDS_HISTORY_SUCCESS',
  MARK_MESSAGE_AS_READ: '/koala/me/MARK_MESSAGE_AS_READ',
  MARK_MESSAGE_AS_READ_SUCCESS: '/koala/me/MARK_MESSAGE_AS_READ_SUCCESS',
  DELETE_MESSAGE: '/koala/me/DELETE_MESSAGE',
  DELETE_MESSAGE_SUCCESS: '/koala/me/DELETE_MESSAGE_SUCCESS',
  CREATE_REDEMPTION: '/koala/me/CREATE_REDEMPTION',
  CREATE_REDEMPTION_SUCCESS: '/koala/me/CREATE_REDEMPTION_SUCCESS',
  CREATE_REDEMPTION_FAILURE: '/koala/me/CREATE_REDEMPTION_FAILURE',
  CLEAR_ACTIVE_REDEMPTION: '/koala/me/CLEAR_ACTIVE_REDEMPTION',
  FETCH_REDEMPTIONS: '/koala/me/FETCH_REDEMPTIONS',
  FETCH_REDEMPTIONS_SUCCESS: '/koala/me/FETCH_REDEMPTIONS_SUCCESS',
  TOGGLE_ACCOUNT_DROPDOWN: '/koala/me/TOGGLE_ACCOUNT_DROPDOWN',
  REDEEM_RECEIPT_BARCODE: '/koala/me/REDEEM_RECEIPT_BARCODE',
  REDEEM_RECEIPT_BARCODE_SUCCESS: '/koala/me/REDEEM_RECEIPT_BARCODE_SUCCESS',
  REDEEM_RECEIPT_BARCODE_FAILURE: '/koala/me/REDEEM_RECEIPT_BARCODE_FAILURE',

  fetchMe: () => ({
    type: meActions.FETCH_ME,
  }),
  fetchMeFavoriteLocations: () => ({
    type: meActions.FETCH_ME_FAVORITE_LOCATIONS,
  }),
  fetchMeFavoriteLocationsSuccess: (favoriteLocations: {}[]) => ({
    type: meActions.ME_FAVORITE_LOCATIONS_SUCCESS,
    favoriteLocations,
  }),

  deleteMeFavoriteLocationSuccess: () => ({
    type: meActions.DELETE_ME_FAVORITE_LOCATION_SUCCESS,
  }),
  deleteMeFavoriteLocationFail: () => ({
    type: meActions.DELETE_ME_FAVORITE_LOCATION_FAIL,
  }),
  deleteMeFavoriteOrderSuccess: () => ({
    type: meActions.DELETE_ME_FAVORITE_ORDER_SUCCESS,
  }),
  deleteMeFavoriteOrderFail: () => ({
    type: meActions.DELETE_ME_FAVORITE_ORDER_FAIL,
  }),
  addMeFavoriteOrderSuccess: () => ({
    type: meActions.ADD_ME_FAVORITE_ORDER_SUCCESS,
  }),
  addMeFavoriteOrderFail: () => ({
    type: meActions.ADD_ME_FAVORITE_ORDER_FAIL,
  }),
  fetchMeFavoriteOrders: () => ({
    type: meActions.FETCH_ME_FAVORITE_ORDERS,
  }),
  fetchMeFavoriteOrdersSuccess: (favoriteOrders: {}[]) => ({
    type: meActions.FETCH_ME_FAVORITE_ORDERS_SUCCESS,
    favoriteOrders,
  }),
  fetchMePastOrders: () => ({
    type: meActions.FETCH_ME_PAST_ORDERS,
  }),
  fetchMePastOrdersSuccess: (pastOrders: {}[]) => ({
    type: meActions.FETCH_ME_PAST_ORDERS_SUCCESS,
    pastOrders,
  }),
  fetchMeOrderDetails: (id: string, pastOrders: {}[]) => ({
    type: meActions.FETCH_ME_ORDER_DETAILS,
    id,
    pastOrders,
  }),
  fetchMeOrderDetailsSuccess: (orderDetails: OrderDetails) => ({
    type: meActions.FETCH_ME_ORDER_DETAILS_SUCCESS,
    orderDetails,
  }),
  fetchMeOrderDetailsFail: () => ({
    type: meActions.FETCH_ME_ORDER_DETAILS_FAIL,
  }),
  clearMeOrderDetails: () => ({
    type: meActions.CLEAR_ME_ORDER_DETAILS,
  }),
  toggleReorderReceipt: (show: boolean) => ({
    type: meActions.TOGGLE_REORDER_RECEIPT,
    show,
  }),
  fetchMeCreditCards: (basketId: string) => ({
    type: meActions.FETCH_ME_CREDIT_CARDS,
    basketId,
  }),
  fetchMeCreditCardsSuccess: (cards: any) => ({
    type: meActions.FETCH_ME_CREDIT_CARDS_SUCCESS,
    cards,
  }),
  fetchMeCreditCardsFail: () => ({
    type: meActions.FETCH_ME_CREDIT_CARDS_FAIL,
  }),
  fetchAllLocationsCreditCards: (pastOrders: {}[]) => ({
    type: meActions.FETCH_ALL_LOCATIONS_CREDIT_CARDS,
    pastOrders,
  }),
  fetchAllLocationsCreditCardsSuccess: (allLocationsCards: any) => ({
    type: meActions.FETCH_ALL_LOCATIONS_CREDIT_CARDS_SUCCESS,
    allLocationsCards,
  }),
  deleteMeCreditCard: (id: string) => ({
    type: meActions.DELETE_ME_CREDIT_CARD,
    id,
  }),
  deleteMeCreditCardSuccess: () => ({
    type: meActions.DELETE_ME_CREDIT_CARD_SUCCESS,
  }),
  deleteMeCreditCardFail: () => ({
    type: meActions.DELETE_ME_CREDIT_CARD_FAIL,
  }),
  clearMe: () => ({
    type: meActions.CLEAR_ME,
  }),
  updateMe: (params: Partial<LoyaltyUserUpdateInfo>) => ({
    type: meActions.UPDATE_ME,
    params,
  }),
  updateMeSuccess: () => ({
    type: meActions.UPDATE_ME_SUCCESS,
  }),
  updateMeFail: () => ({
    type: meActions.UPDATE_ME_FAIL,
  }),
  addFavoriteLocation: (id: number) => ({
    id,
    type: meActions.ADD_FAVORITE_LOCATION,
  }),
  removeFavoriteLocation: (id: number, label: string) => ({
    id,
    label,
    type: meActions.REMOVE_FAVORITE_LOCATION,
  }),
  addFavoriteOrder: (id: string) => ({
    id,
    type: meActions.ADD_FAVORITE_ORDER,
  }),
  removeFavoriteOrder: (id: string) => ({
    id,
    type: meActions.REMOVE_FAVORITE_ORDER,
  }),
  fetchOffers: () => ({
    type: meActions.FETCH_OFFERS,
  }),
  fetchOffersSuccess: (rewards: RewardItem[]) => ({
    type: meActions.FETCH_OFFERS_SUCCESS,
    rewards,
  }),
  appendUserPhone: (phone: string) => ({
    type: meActions.APPEND_USER_PHONE,
    phone,
  }),
  fetchAddresses: () => ({
    type: meActions.FETCH_ADDRESSES,
  }),
  deleteAddress: (id: number) => ({
    type: meActions.DELETE_ADDRESS,
    id,
  }),
  defaultAddress: (id: number) => ({
    type: meActions.DEFAULT_ADDRESS,
    id,
  }),
  toggleStoredAddressesMenu: (show: boolean) => ({
    type: meActions.TOGGLE_STORED_ADDRESSES_MENU,
    show,
  }),
  setActiveStoredAddress: (addressId: number) => ({
    type: meActions.SET_ACTIVE_STORED_ADDRESS,
    addressId,
  }),
  // Punchh V2 only
  fetchLoyaltyState: () => ({
    type: meActions.FETCH_LOYALTY_STATE,
  }),
  fetchRedeemables: () => ({
    type: meActions.FETCH_REDEEMABLES,
  }),
  fetchMessages: () => ({
    type: meActions.FETCH_MESSAGES,
  }),
  fetchRewardsHistory: () => ({
    type: meActions.FETCH_REWARDS_HISTORY,
  }),
  markMessageAsRead: (messageId: string) => ({
    type: meActions.MARK_MESSAGE_AS_READ,
    messageId,
  }),
  deleteMessage: (messageId: string) => ({
    type: meActions.DELETE_MESSAGE,
    messageId,
  }),
  createRedemption: (rewardId: string | number, rewardType: string) => ({
    type: meActions.CREATE_REDEMPTION,
    rewardId,
    rewardType,
  }),
  createRedemptionSuccess: (redemption: RedemptionItem, rewardType: string) => ({
    type: meActions.CREATE_REDEMPTION_SUCCESS,
    activeRedemption: redemption,
    rewardType,
  }),
  createRedemptionFailure: (error: unknown, rewardType: string) => ({
    type: meActions.CREATE_REDEMPTION_FAILURE,
    error,
    rewardType,
  }),
  clearActiveRedemption: () => ({
    type: meActions.CLEAR_ACTIVE_REDEMPTION,
  }),
  fetchRedemptions: () => ({
    type: meActions.FETCH_REDEMPTIONS,
  }),
  toggleAccountDropdown: (show: boolean) => ({
    type: meActions.TOGGLE_ACCOUNT_DROPDOWN,
    show,
  }),
  redeemReceiptBarcode: (barcode: string) => ({
    type: meActions.REDEEM_RECEIPT_BARCODE,
    barcode,
  }),
  setMeCreditCardsLoadingToInitState: () => ({
    type: meActions.SET_ME_CREDIT_CARDS_LOADING_TO_INIT_STATE,
  }),
};

export default meActions;
