import { type KoalaEnv, type RequestMeta } from '@koala/sdk';
import * as Sentry from '@sentry/react';
import { type Token } from '@koala/sdk/v4';
import { authExchange } from '@urql/exchange-auth';
import { type SSRExchange } from 'next-urql';
import { useCallback, useEffect, useState } from 'react';
import {
  cacheExchange,
  fetchExchange,
  makeOperation,
  type ClientOptions,
  type Operation,
} from 'urql';
import { getClientToken } from './client';
import { ENV, baseUrl, brandCmsUrl } from '@/constants/envConfig';

interface AuthState {
  token: Token;
}

function contentGetApiUrl(_env: KoalaEnv) {
  return `${baseUrl}/content/graphql`;
  // switch (env) {
  //   case "dev":
  //     return "https://dev.api.koala.io/content/graphql";
  //   case "staging":
  //     return "https://staging.api.koala.io/content/graphql";
  //   case "sandbox":
  //     return "https://sandbox.api.koala.io/content/graphql";
  //   case "production":
  //     return "https://api.koala.io/content/graphql";
  // }
}

export function contentGetCmsUrl() {
  return brandCmsUrl;

  // if (process.env.NODE_ENV === "production") {
  //   switch (ENV) {
  //     case "dev":
  //       return "https://dev.cms.koala.io";
  //     case "staging":
  //       return "https://staging.cms.koala.io";
  //     case "sandbox":
  //       return "https://sandbox.cms.koala.io";
  //     case "production":
  //       return "https://cms.koala.io";
  //   }
  // }

  // return "http://localhost:3001";
}

export function contentSetupAPIRequests(
  ssr: SSRExchange,
  meta: RequestMeta | undefined,
): ClientOptions {
  const url = contentGetApiUrl(ENV);
  return {
    url,
    exchanges: [
      cacheExchange,
      ssr,
      authExchange({
        addAuthToOperation: ({
          authState,
          operation,
        }: {
          authState: AuthState | null;
          operation: Operation;
        }) => {
          if (!authState?.token) {
            return operation;
          }

          const { token_type, access_token } = authState.token;

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {};

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: `${token_type} ${access_token}`,
              },
            },
          });
        },
        getAuth: async ({ authState }) => {
          if (!authState && meta) {
            try {
              const token = await getClientToken({
                origin: meta.headers['x-request-origin'],
              });
              return { token };
            } catch (err) {
              Sentry.setTag(
                'message',
                `Failed to fetch client token on content setup get auth for origin: ${meta.headers['x-request-origin']}`,
              );
              Sentry.captureException(err);
            }
          }
          return null;
        },
      }),
      fetchExchange,
    ],
  };
}

export enum WindowMessageEvents {
  createConnection = 'create_connection',
  requestRefresh = 'request_refresh',
  confirmRefresh = 'confirm_refresh',
  setId = 'set_id',
  receivedId = 'confirm_set_id',
  selectModule = 'select_module',
  highlightModule = 'highlight_module',
}

interface MessageData {
  type: WindowMessageEvents;
  [key: string]: unknown;
}

type Message = MessageData & {
  sendBack: (value: MessageData) => void;
};

/**
 * A reactified way to do window.postMessage events via a custom hook
 *
 * - The hook handles setting up message events and captures them in a local variable
 * - This implementation is based on being embedded via an iframe, so we expose a `sendParentMessage` function that will post back to the window.parent
 * - A dependency list of stateful items affected by the messaging system can be passed in to ensure everything stays up to date
 * ```
 * const { origin, message } = useWindowMessaging(
 *  "https://cms.koala.io",
 *  [someId]
 * );
 * ```
 * @param windowOrigin
 * @param dependencies
 */
export function useWindowMessaging(windowOrigin: string, dependencies: unknown[] = []) {
  const [message, setMessage] = useState<Message>();
  const [origin, setOrigin] = useState(windowOrigin);

  function callback(event: MessageEvent) {
    if (event.origin !== origin) {
      return;
    }

    setMessage({
      ...event.data,
      sendBack: (value: MessageData) => {
        (event.source as WindowProxy).postMessage(value, event.origin);
      },
    });
  }

  const listener = useCallback(callback, [...dependencies, origin]);

  useEffect(function () {
    window.addEventListener('message', listener);

    return function () {
      window.removeEventListener('message', listener);
    };
  });

  return {
    origin,
    message,
    setOrigin,
    sendParentMessage: (value: MessageData) => {
      try {
        if (!origin) {
          throw 'unable to send message';
        }

        window.parent.postMessage(value, origin);
      } catch (error) {
        console.error(error);
      }
    },
  };
}
