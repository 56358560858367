export interface IGlobalEventContext {
  name?: string;
  details?: string;
}

export interface IGlobalEvent {
  eventName: string | null;
  eventCategory?: string;
  eventDetails?: string;
  eventValue?: number;
}

export interface IGlobalEvents {
  ACCOUNT__ADD_FAVORITE_LOCATION?: IGlobalEvent;
  ACCOUNT__REMOVE_FAVORITE_LOCATION?: IGlobalEvent;
  ACCOUNT__STORED_ADDRESS_DELETED?: IGlobalEvent;
  ARRIVAL__CONFIRM_ARRIVAL_SUCCESS?: IGlobalEvent;
  AUTH__CLICK_FORGOT_PASSWORD?: IGlobalEvent;
  AUTH__CLICK_GUEST_CHECKOUT?: IGlobalEvent;
  AUTH__CLICK_LOGOUT?: IGlobalEvent;
  AUTH__CLICK_SIGN_IN?: IGlobalEvent;
  AUTH__CLICK_SIGN_UP?: IGlobalEvent;
  AUTH__SIGN_IN_SUCCESS?: IGlobalEvent;
  AUTH__SIGN_UP_SUCCESS?: IGlobalEvent;
  BASKET__CUSTOMIZED?: IGlobalEvent;
  CHECKOUT__CHECKOUT_VIEW?: IGlobalEvent;
  DELIVERY__TOGGLE?: IGlobalEvent;
  GENERIC__CTA?: IGlobalEvent;
  HEADER__CLOSE_BASKET?: IGlobalEvent;
  HEADER__VIEW_BASKET?: IGlobalEvent;
  LOCATIONS__FAVORITE_LOCATION_CTA?: IGlobalEvent;
  LOCATIONS__LOCATION_SEARCH_NOT_FOUND?: IGlobalEvent;
  LOCATIONS__PRIMARY_LOCATION_CTA?: IGlobalEvent;
  LOCATIONS__SEARCH_LOCATIONS?: IGlobalEvent;
  LOCATIONS__TOGGLE_MOBILE_DISPLAY?: IGlobalEvent;
  LOCATIONS__TRIGGER_DELIVERY_MODAL?: IGlobalEvent;
  LOCATIONS__VIEW_ALL_LOCATIONS?: IGlobalEvent;
  LOCATION_RECOMMENDATIONS_SURFACED?: IGlobalEvent;
  STORE__BASKET_RECONCILIATION?: IGlobalEvent;
  STORE__CART_VIEW?: IGlobalEvent;
  STORE__MENU_VIEW?: IGlobalEvent;
  STORE__PRODUCT_VIEW?: IGlobalEvent;
  VIEW__REWARDS?: IGlobalEvent;
  VIEW__ORDER_HISTORY?: IGlobalEvent;
  VIEW__FAVORITES?: IGlobalEvent;
  VIEW__STORE_LOCATOR?: IGlobalEvent;
  VIEW__INBOX?: IGlobalEvent;
  VIEW__DISPATCH_TRACKING?: IGlobalEvent;
  WANTED_AT_SELECTED?: IGlobalEvent;
}

export enum GlobalEvents {
  ACCOUNT__ADD_FAVORITE_LOCATION = 'ACCOUNT__ADD_FAVORITE_LOCATION',
  ACCOUNT__REMOVE_FAVORITE_LOCATION = 'ACCOUNT__REMOVE_FAVORITE_LOCATION',
  ACCOUNT__STORED_ADDRESS_DELETED = 'ACCOUNT__STORED_ADDRESS_DELETED',
  ARRIVAL__CONFIRM_ARRIVAL_SUCCESS = 'ARRIVAL__CONFIRM_ARRIVAL_SUCCESS',
  AUTH__CLICK_FORGOT_PASSWORD = 'AUTH__CLICK_FORGOT_PASSWORD',
  AUTH__CLICK_GUEST_CHECKOUT = 'AUTH__CLICK_GUEST_CHECKOUT',
  AUTH__CLICK_LOGOUT = 'AUTH__CLICK_LOGOUT',
  AUTH__CLICK_SIGN_IN = 'AUTH__CLICK_SIGN_IN',
  AUTH__CLICK_SIGN_UP = 'AUTH__CLICK_SIGN_UP',
  AUTH__SIGN_IN_SUCCESS = 'AUTH__SIGN_IN_SUCCESS',
  AUTH__SIGN_UP_SUCCESS = 'AUTH__SIGN_UP_SUCCESS',
  BASKET__CUSTOMIZED = 'BASKET__CUSTOMIZED',
  CHECKOUT__CHECKOUT_VIEW = 'CHECKOUT__CHECKOUT_VIEW',
  DELIVERY__TOGGLE = 'DELIVERY__TOGGLE',
  GENERIC__CTA = 'GENERIC__CTA',
  HEADER__CLOSE_BASKET = 'HEADER__CLOSE_BASKET',
  HEADER__VIEW_BASKET = 'HEADER__VIEW_BASKET',
  LOCATIONS__FAVORITE_LOCATION_CTA = 'LOCATIONS__FAVORITE_LOCATION_CTA',
  LOCATIONS__LOCATION_SEARCH_NOT_FOUND = 'LOCATIONS__LOCATION_SEARCH_NOT_FOUND',
  LOCATIONS__PRIMARY_LOCATION_CTA = 'LOCATIONS__PRIMARY_LOCATION_CTA',
  LOCATIONS__SEARCH_LOCATIONS = 'LOCATIONS__SEARCH_LOCATIONS',
  LOCATIONS__TOGGLE_MOBILE_DISPLAY = 'LOCATIONS__TOGGLE_MOBILE_DISPLAY',
  LOCATIONS__TRIGGER_DELIVERY_MODAL = 'LOCATIONS__TRIGGER_DELIVERY_MODAL',
  LOCATIONS__VIEW_ALL_LOCATIONS = 'LOCATIONS__VIEW_ALL_LOCATIONS',
  LOCATION_RECOMMENDATIONS_SURFACED = 'LOCATION_RECOMMENDATIONS_SURFACED',
  STORE__BASKET_RECONCILIATION = 'STORE__BASKET_RECONCILIATION',
  STORE__CART_VIEW = 'STORE__CART_VIEW',
  STORE__MENU_VIEW = 'STORE__MENU_VIEW',
  STORE__PRODUCT_VIEW = 'STORE__PRODUCT_VIEW',
  VIEW__REWARDS = 'VIEW__REWARDS',
  VIEW__ORDER_HISTORY = 'VIEW__ORDER_HISTORY',
  VIEW__FAVORITES = 'VIEW__FAVORITES',
  VIEW__STORE_LOCATOR = 'VIEW__STORE_LOCATOR',
  VIEW__INBOX = 'VIEW__INBOX',
  VIEW__DISPATCH_TRACKING = 'VIEW__DISPATCH_TRACKING',
  WANTED_AT_SELECTED = 'WANTED_AT_SELECTED',
}

export enum EventNames {
  ORDER_NOW = 'Order Now',
  HOW_REWARDS_WORK = 'How Rewards Work',
  UTENSILS_SET = 'Set Basket Utensils',
  CHANGE_LOCATION = 'Change Location Button',
  CHANGE_DELIVERY_ADDRESS = 'Change Delivery Address Button',
  BACK_TO_MENU = 'Back To Menu',
  RESTART_ORDER = 'Restart Order',
  APPLY_NEW_WANTED_TIME = 'Apply New Wanted Time',
  APPEND_USER_PHONE = 'Append User Phone',
  CONFIRM_ARRIVAL = 'Confirm Customer Arrival',
  ANDROID_NAVIGATION = 'Navigation Drawer',
  ANDROID_HOME = 'Welcome screen',
  LOCATIONS_SURFACED_GEOLOCATION = 'GEOLOCATION',
  LOCATIONS_SURFACED_IPADDRESS = 'IP_ADDRESS',
  LOCATIONS_SURFACED_DEFAULT = 'ALL_LOCATIONS',
  DISPATCH_TRACKING_ROUTING_FROM_CHECKOUT = 'DISPATCH_TRACKING_ROUTING_FROM_CHECKOUT',
  DISPATCH_TRACKING_ROUTING_DIRECT_URL = 'DISPATCH_TRACKING_ROUTING_DIRECT_URL',
}
