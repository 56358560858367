import { getOrderSummary } from '@koala/sdk/v4';
import { call, put, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import { orderDetailsActions as actions, orderDetailsActionTypes as types } from './actions';
import globalActions from '@/redux/global/actions';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import * as ErrorReporter from '@/utils/errorReporter';
import { prepareErrorMessage } from '@/utils/global';

export function* fetchOrderDetailsByOrderIdSaga(
  action: ReturnType<typeof actions.fetchOrderDetailsByOrderId>,
) {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getOrderSummary> = yield call(
      // @ts-expect-error ensure that `action.order_id` is defined.
      getOrderSummary,
      action.order_id,
      { client },
    );

    // Write menu into redux
    yield put(actions.fetchOrderDetailsByOrderIdSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchOrderDetailsByOrderIdFail(error));
    // Error Notification
    ErrorReporter.captureException(error);

    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      'There was an error fetching order details external order id',
      error,
    );
    yield put(globalActions.displayErrorToast(errorResponse.message, true));
  }
}

export default function* rootSaga() {
  yield takeLatest(types.FETCH_ORDER_DETAILS_BY_ORDER_ID, fetchOrderDetailsByOrderIdSaga);
}
