import { validateLoyaltyToken, persistToken } from '@koala/sdk/v4';
import { deleteCookie, getCookie } from 'cookies-next';
import { getLoyaltyToken } from '@/services/client';
import { getOrigin } from '@/utils';

export async function login(payload: Record<string, any>, origin: string) {
  const token = await getLoyaltyToken(payload, { origin });

  persistToken({ origin, token });
}

export function isExternalUserTokenValid() {
  return validateLoyaltyToken(getOrigin(window.location.host));
}

export function getExternalCookie() {
  if (getCookie('_paytronix_auth_code')) {
    return {
      cookie: getCookie('_paytronix_auth_code'),
      provider: 'paytronix',
    };
  }

  return null;
}

export function clearExternalCookie() {
  deleteCookie('_paytronix_auth_code');
}
