import {
  type MessageCTA,
  type MessageItem,
  type RedeemableItem,
  type RewardItem,
} from '@koala/sdk';
import { LOYALTY_MESSAGE_TYPES } from '@/constants/loyalty';
import { LOYALTY_ROUTES, ROUTES } from '@/constants/routes';
import { type RootState } from '@/types/app';

/*
Paths come from here: https://docs.google.com/document/d/1ZHSp3060q1ClNJaS5y_AManinMqFrxDOitK2Kv1J7zo/edit
*/
export const routePunchhDeepLinks = (cta: MessageCTA): string => {
  // Find the keyword after the last slash in URL
  const splitURL = cta.url.split('/');
  const keyword = splitURL[splitURL.length - 1];

  switch (keyword) {
    case 'account-history':
      return LOYALTY_ROUTES.ORDER_HISTORY;
    case 'edit-profile':
      return LOYALTY_ROUTES.ACCOUNT;
    case 'invite-friends':
      return LOYALTY_ROUTES.ACCOUNT;
    case 'locations':
      return ROUTES.LOCATIONS;
    case 'menu':
      return ROUTES.LOCATIONS;
    case 'news':
      return LOYALTY_ROUTES.INBOX;
    case 'offers':
      return LOYALTY_ROUTES.REWARDS;
    case 'order':
      return ROUTES.LOCATIONS;
    default:
      return ROUTES.LOCATIONS;
  }
};

/**
 * Calculate number of unread personal messages for counters
 * @TODO restrict `myMessages` to a non-nullable/optional array.
 */
export const getUnreadPersonalMessagesLength = (myMessages?: MessageItem[] | null): number => {
  if (!myMessages) {
    return 0;
  }

  return myMessages
    .filter((message) => message.type === LOYALTY_MESSAGE_TYPES.PERSONAL)
    .filter((personalMsg) => !personalMsg.read_at).length;
};

/*
Calculate active rewards and offers
*/
export const getActiveRewardsAndOffersLength = (
  myRedeemables: RedeemableItem[],
  myOffers: RewardItem[],
  currentPoints: number,
): number => {
  const myAvailableRedeemables = myRedeemables.filter(
    (reward) => reward.points_required <= currentPoints,
  );

  return myAvailableRedeemables.length + myOffers.length;
};

/*
Determine allowed routes based on loyalty driver ID
*/
export const allowedLoyaltyRoutes = (loyaltyDriverId: number | null): string[] => {
  switch (loyaltyDriverId) {
    // Punchh V2
    case 2:
      return [
        LOYALTY_ROUTES.REWARDS,
        LOYALTY_ROUTES.INBOX,
        LOYALTY_ROUTES.FAVORITES,
        LOYALTY_ROUTES.ORDER_HISTORY,
        LOYALTY_ROUTES.ACCOUNT,
        ROUTES.ORDER_DETAIL,
      ];
    // Paytronix
    case 3:
      return [
        LOYALTY_ROUTES.FAVORITES,
        LOYALTY_ROUTES.ORDER_HISTORY,
        LOYALTY_ROUTES.ACCOUNT,
        ROUTES.ORDER_DETAIL,
      ];
    // Spendgo
    case 4:
      return [
        LOYALTY_ROUTES.ORDER_HISTORY,
        LOYALTY_ROUTES.REWARDS,
        LOYALTY_ROUTES.FAVORITES,
        LOYALTY_ROUTES.ACCOUNT,
        ROUTES.ORDER_DETAIL,
      ];
    // Chowly
    case 5:
      return [LOYALTY_ROUTES.REWARDS, LOYALTY_ROUTES.ACCOUNT, ROUTES.ORDER_DETAIL];
    default:
      return [
        LOYALTY_ROUTES.FAVORITES,
        LOYALTY_ROUTES.ORDER_HISTORY,
        LOYALTY_ROUTES.ACCOUNT,
        ROUTES.ORDER_DETAIL,
      ];
  }
};

/* Format the date strings returned by Punchh - TODO make this more global or deprecate */
export const dateFormatter = (dateString: string): string => {
  return 'MM/DD/YYYY'
    .replace('MM', dateString.substr(5, 2))
    .replace('DD', dateString.substr(8, 2))
    .replace('YYYY', dateString.substr(0, 4));
};

/* Fetch feature flags and loyalty driver ID for use in sagas */
export const getLoyaltyInfoFromState = (state: RootState) => {
  return {
    loyaltyDriverId: state.app.organization.organization.loyalty_driver_id,
    loyaltyFeatureFlags: state.app.organization.loyalty?.features,
  };
};
