import type * as Stitches from '@stitches/react';
import { styled } from '@/components/content/stitches';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  align?: 'leading' | 'center' | 'trailing' | 'fill';
  as?: string;
  position?: 'leading' | 'center' | 'trailing' | 'fill' | 'between';
  css?: Stitches.CSS;
  direction?: 'horizontal' | 'vertical';
  gap?: string;
}

export const Stack = ({
  align = 'center',
  position = 'leading',
  children = null,
  css = {},
  direction = 'horizontal',
  gap = '$2',
  ...otherProps
}: Props) => (
  <Container
    align={align}
    position={position}
    direction={direction}
    css={{ gap, ...css }}
    {...otherProps}
  >
    {children}
  </Container>
);

const Container = styled('div', {
  display: 'flex',
  variants: {
    align: {
      leading: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      trailing: {
        alignItems: 'flex-end',
      },
      fill: {
        alignItems: 'stretch',
      },
    },
    position: {
      leading: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      trailing: {
        justifyContent: 'flex-end',
      },
      fill: {
        justifyContent: 'stretch',
      },
      between: {
        justifyContent: 'space-between',
      },
    },
    direction: {
      horizontal: {
        flexDirection: 'row',
      },
      vertical: {
        flexDirection: 'column',
      },
    },
  },
});
