import {
  type CONVEYANCE_TYPES,
  type CurbsideDetails,
  type DeliveryAddress,
  type DineInDetails,
  type DriveInDetails,
  type Location,
} from '@koala/sdk';
import { type API_CONVEYANCE_TYPES } from '@/constants/events';
import { type IConveyanceDeliveryAddress } from '@/types/fulfillment';

interface Pickup {
  type: API_CONVEYANCE_TYPES.PICKUP;
  details: [];
  wantedAt?: string | undefined;
}

interface Drivethru {
  type: API_CONVEYANCE_TYPES.DRIVETHRU;
  details: [];
}

interface Curbside {
  type: API_CONVEYANCE_TYPES.CURBSIDE;
  details: CurbsideDetails;
}

interface DriveIn {
  type: API_CONVEYANCE_TYPES.DRIVEIN;
  details: DriveInDetails;
}

interface DineIn {
  type: API_CONVEYANCE_TYPES.DINEIN;
  details: DineInDetails | []; // [] - when table number is not supported
}

interface Delivery {
  type: API_CONVEYANCE_TYPES.DELIVERY;
  details: IConveyanceDeliveryAddress;
}

type ConveyanceMode = Pickup | Drivethru | Curbside | DriveIn | DineIn | Delivery;

const conveyanceModeActions = {
  CHECK_DELIVERY_ADDRESS: '/koala/conveyanceMode/CHECK_DELIVERY_ADDRESS',
  CHECK_DELIVERY_ADDRESS_SUCCESS: '/koala/conveyanceMode/CHECK_DELIVERY_ADDRESS_SUCCESS',
  CHECK_DELIVERY_ADDRESS_FAILURE: '/koala/conveyanceMode/CHECK_DELIVERY_ADDRESS_FAILURE',
  SET_DELIVERY_ADDRESS: '/koala/conveyanceMode/SET_DELIVERY_ADDRESS',
  SET_DELIVERY_ADDRESS_SUCCESS: '/koala/conveyanceMode/SET_DELIVERY_ADDRESS_SUCCESS',
  DELIVERY_ADDRESS_VALID: '/koala/conveyanceMode/DELIVERY_ADDRESS_VALID',
  DELIVERY_ADDRESS_INVALID: '/koala/conveyanceMode/DELIVERY_ADDRESS_INVALID',
  SET_DELIVERY_ADDRESS_FAILURE: '/koala/conveyanceMode/SET_DELIVERY_ADDRESS_FAILURE',
  SET_DELIVERY_ADDRESS_CONTINUE: '/koala/conveyanceMode/SET_DELIVERY_ADDRESS_CONTINUE',
  CLEAR_DELIVERY_ADDRESS: '/koala/conveyanceMode/CLEAR_DELIVERY_ADDRESS',
  SET_WANTED_TIME: '/koala/conveyanceMode/SET_WANTED_TIME',
  HANDLE_CONVEYANCE_FAILURE: '/koala/conveyanceMode/HANDLE_CONVEYANCE_FAILURE',
  HANDOFF_TYPE_TOGGLE: '/koala/conveyanceMode/HANDOFF_TYPE_TOGGLE',
  HANDOFF_DETAILS_SET: '/koala/conveyanceMode/HANDOFF_DETAILS_SET',
  RESET_LOCKED_CONVEYANCE_MODE: '/koala/conveyanceMode/RESET_LOCKED_CONVEYANCE_MODE',
  CONVEYANCE_MODE_SET: '/koala/conveyanceMode/CONVEYANCE_MODE_SET',

  // Delivery
  checkDeliveryAddress: (
    address: DeliveryAddress,
    location: Location,
    revalidate?: boolean,
    shouldAutoRedirect?: boolean,
  ) => ({
    type: conveyanceModeActions.CHECK_DELIVERY_ADDRESS,
    address,
    location,
    revalidate,
    shouldAutoRedirect,
  }),
  setDeliveryAddress: (
    address: DeliveryAddress,
    location: Location,
    revalidate?: boolean,
    /** Should the user automatically be redirected to the correct store page? */
    shouldAutoRedirect?: boolean,
  ) => ({
    type: conveyanceModeActions.SET_DELIVERY_ADDRESS,
    address,
    location,
    revalidate,
    shouldAutoRedirect,
  }),
  setWantedTime: (wantedAt: string | undefined, locationId: number, type: CONVEYANCE_TYPES) => ({
    type: conveyanceModeActions.SET_WANTED_TIME,
    wantedAt,
    locationId,
    conveyanceType: type,
  }),
  setDeliveryAddressSuccess: (address: DeliveryAddress, fulfillmentModalStoreId: string) => ({
    type: conveyanceModeActions.SET_DELIVERY_ADDRESS_SUCCESS,
    address,
    fulfillmentModalStoreId,
  }),
  // Triggered when the user clicks the "Continue" button after entering their address.
  setDeliveryAddressContinue: () => ({
    type: conveyanceModeActions.SET_DELIVERY_ADDRESS_CONTINUE,
  }),
  clearDeliveryAddress: (locationId: number) => ({
    type: conveyanceModeActions.CLEAR_DELIVERY_ADDRESS,
    locationId,
  }),

  // Curbside & Pickup
  toggleHandoff: (conveyanceType: CONVEYANCE_TYPES) => ({
    type: conveyanceModeActions.HANDOFF_TYPE_TOGGLE,
    conveyanceType,
  }),
  handoffDetailsSet: (
    conveyanceType: string,
    handoffDetails?: CurbsideDetails | { instructions: string } | DineInDetails,
  ) => ({
    type: conveyanceModeActions.HANDOFF_DETAILS_SET,
    conveyanceType,
    handoffDetails,
  }),

  // All
  handleConveyanceFailure: (error: Error, conveyanceType: string) => ({
    type: conveyanceModeActions.HANDLE_CONVEYANCE_FAILURE,
    error,
    conveyanceType,
  }),

  // Reset a locked conveyance mode to pickup
  clearLockedConveyanceMode: () => ({
    type: conveyanceModeActions.RESET_LOCKED_CONVEYANCE_MODE,
  }),
  conveyanceModeSet: (mode: ConveyanceMode, wantedAt?: string, locationId?: number) => ({
    type: conveyanceModeActions.CONVEYANCE_MODE_SET,
    conveyanceType: mode.type,
    conveyanceDetails: mode.details,
    wantedAt,
    locationId,
  }),
};

export default conveyanceModeActions;
