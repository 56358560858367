import { type AnyAction } from 'redux';
import { locationActionTypes as actionTypes } from './actions';
import { LOCATION_VIEW_STATES } from '@/constants/locations';
import { type RootState } from '@/types/app';
import { type ILocationsState } from '@/types/locations';
import { getISODateInUTC } from '@/utils/dates';

export const initialState: ILocationsState = {
  activeLocationId: null,
  error: false,
  // @ts-expect-error
  list: null,
  /**
   * This empty object type is a shim because models are no longer constructed
   * as empty classes. The app doesn't currently handle `undefined` locations
   * even though that should be the _actual_ empty state.
   *
   * @TODO handle `undefined` location initial state gracefully.
   */
  // @ts-expect-error
  detail: {},
  loading: false,
  meta: {
    lastUpdatedAt: null,
    pagination: null,
    retrieved_at: null,
  },
  params: null,
  searchLoading: false,
  moreLocationsLoading: false,
  searchTimestamp: null,
  viewState: LOCATION_VIEW_STATES.SEARCH,
  noDeliveryLocationsFound: false,
  invalidDeliverySearchAddress: false,
  googleAddressNotSelected: false,
};

export const locations = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS:
    case actionTypes.FETCH_LOCATION:
    case actionTypes.FETCH_ALL_LOCATIONS:
    case actionTypes.HYDRATE_LOCATION:
      return Object.assign({}, state, {
        error: false,
        loading: true,
        noDeliveryLocationsFound: false,
        meta: {
          ...state.meta,
          lastUpdatedAt: getISODateInUTC(),
        },
      });
    case actionTypes.SEARCH_LOCATIONS:
    case actionTypes.SEARCH_DELIVERY_COVERAGE:
      return Object.assign({}, state, {
        error: false,
        searchLoading: true,
      });
    case actionTypes.SEARCH_DELIVERY_COVERAGE_NO_RESULTS:
      return Object.assign({}, state, {
        error: true,
        searchLoading: false,
        noDeliveryLocationsFound: true,
      });
    case actionTypes.SHOW_DELIVERY_ADDRESS_INVALID_ERROR:
      return Object.assign({}, state, {
        error: true,
        searchLoading: false,
        invalidDeliverySearchAddress: true,
      });
    case actionTypes.SHOW_GOOGLE_ADDRESS_NOT_SELECTED_ERROR:
      return Object.assign({}, state, {
        error: true,
        searchLoading: false,
        invalidDeliverySearchAddress: false,
        googleAddressNotSelected: true,
      });
    case actionTypes.SEARCH_DELIVERY_COVERAGE_SUCCESS:
    case actionTypes.SEARCH_DELIVERY_COVERAGE_FAILURE:
      return Object.assign({}, state, {
        error: false,
        searchLoading: false,
        noDeliveryLocationsFound: false,
      });
    case actionTypes.FETCH_MORE_LOCATIONS:
      return Object.assign({}, state, {
        error: false,
        moreLocationsLoading: true,
      });
    case actionTypes.LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        list: action.list,
        loading: false,
        meta: action.meta,
        searchTimestamp: new Date(),
        viewState: LOCATION_VIEW_STATES.SEARCH,
      });
    case actionTypes.ALL_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        list: action.list,
        loading: false,
        meta: action.meta,
        searchTimestamp: new Date(),
        viewState: LOCATION_VIEW_STATES.VIEW_ALL,
      });
    case actionTypes.MORE_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        list: [...state.list, ...action.newList],
        moreLocationsLoading: false,
        meta: action.meta,
        searchTimestamp: new Date(),
        viewState: LOCATION_VIEW_STATES.SEARCH,
      });
    case actionTypes.SEARCH_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        list: action.list,
        searchLoading: false,
        meta: action.meta,
        params: action.params,
        searchTimestamp: new Date(),
        viewState: LOCATION_VIEW_STATES.SEARCH,
      });
    case actionTypes.LOCATION_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        detail: action.detail,
        loading: false,
      });
    case actionTypes.LOCATIONS_FAIL:
      return Object.assign({}, state, {
        error: true,
        loading: false,
        searchLoading: false,
      });

    case actionTypes.SET_ACTIVE_LOCATION:
      return Object.assign({}, state, {
        activeLocationId: action.id,
      });

    case actionTypes.CLEAR_DELIVERY_SEARCH_ERRORS:
      return Object.assign({}, state, {
        noDeliveryLocationsFound: false,
        invalidDeliverySearchAddress: false,
        googleAddressNotSelected: false,
      });
    case actionTypes.CLEAR_LOCATIONS:
      return Object.assign({}, initialState, {
        detail: state.detail,
      });
    default:
      return state;
  }
};

export const locationsSliceSelector = (state: RootState) => state.app.locations;
