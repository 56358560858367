import { useCombobox } from 'downshift';
import { type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useConfigOverride } from '@/redux/cmsConfig';
import { getFontStyles } from '@/redux/cmsConfig/utils';

interface Item {
  value: unknown;
  label: string;
}
interface Props {
  items: Item[];
  label: string;
  onChange: (value: unknown) => Promise<void> | void;
  onUpdate: (value: string) => void;
  required?: boolean;
  placeholder?: string;
}
export function Combobox({
  children,
  items,
  label,
  required = false,
  placeholder = '',
  onChange,
  onUpdate,
}: PropsWithChildren<Props>) {
  const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      items,
      onInputValueChange: ({ inputValue }) => onUpdate(String(inputValue)),
      itemToString(item) {
        return item ? item.label : '';
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem) {
          void onChange(selectedItem.value);
        }
      },
    });

  const { background_color, border_color, border_radius } = useConfigOverride('forms');

  return (
    <Container>
      <Label {...getLabelProps()}>{label}</Label>

      <Field
        {...getInputProps()}
        placeholder={placeholder}
        required={required}
        $overrides={{
          backgroundColor: background_color ?? 'transparent',
          borderColor: border_color ?? '$shade300',
          borderRadius: `${Number(border_radius)}px`,
        }}
      />

      <Results hidden={!(isOpen && items.length)} {...getMenuProps()}>
        {isOpen &&
          items.map((item, index) => (
            <Item
              key={`${item.label}${index}`}
              {...getItemProps({ item, index })}
              highlighted={highlightedIndex === index}
            >
              {item.label}
            </Item>
          ))}

        {children}
      </Results>
    </Container>
  );
}

const Container = styled.div({
  position: 'relative',
});

interface FieldProps {
  $overrides: Record<string, string>;
}

const Label = styled.label(({ theme }) => ({
  ...getFontStyles(theme.forms.font, {
    font_weight: () => 700,
  }),
}));

const Field = styled.input<FieldProps>(({ theme, $overrides }) => ({
  alignItems: 'center',
  appearance: 'none',
  backgroundColor: 'transparent',
  border: '1px solid #eee',
  borderRadius: '0.5rem',
  boxSizing: 'border-box',
  cursor: 'text',
  flex: 1,
  height: '3rem',
  lineHeight: 1,
  margin: '.5rem 0 0',
  minWidth: 0,
  padding: '.5rem .75rem',
  position: 'relative',
  transition: 'all .2s ease',
  width: '100%',
  zIndex: '$10',
  ...$overrides,
  ...getFontStyles(theme.forms.font),

  '&:focus': {
    borderColor: theme.global.primary_active_color,
    outline: 'none',
  },
}));

const Results = styled.ul(({ hidden }) => ({
  backgroundColor: 'white',
  borderRadius: '4px',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  listStyleType: 'none',
  margin: 'unset',
  padding: 'unset',
  position: 'absolute',
  width: '100%',
  zIndex: 100,
}));

interface ItemProps {
  highlighted?: boolean;
}

const Item = styled.li<ItemProps>(({ theme, highlighted = false }) => ({
  appearance: 'none',
  backgroundColor: highlighted ? '#eee' : 'transparent',
  border: 'none',
  borderRadius: 'none',
  borderBottom: '1px solid #ddd',
  cursor: 'pointer',
  padding: '0.5rem 1rem',
  textAlign: 'left',
  transition: 'all 0.2s ease',
  ...getFontStyles(theme.primary_font.bold),

  '&:last-of-type': {
    borderBottom: 0,
  },

  '&:hover': {
    opacity: 0.5,
  },

  '&[data-highlighted]': {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));
