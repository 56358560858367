import { type Font } from '@koala/sdk';
import { type CSSProperties } from 'react';
import { type RuleSet } from 'styled-components/dist/types';

/** @TODO rename this function since it does more post-processing. */
function snakeToPascal(fontProperty: Partial<Font>): CSSProperties {
  const transformed: CSSProperties = {};
  if (fontProperty.color) {
    transformed.color = fontProperty.color;
  }
  if (fontProperty.font_size) {
    transformed.fontSize =
      typeof fontProperty.font_size === 'number'
        ? `${fontProperty.font_size}px`
        : fontProperty.font_size;
  }
  if (fontProperty.font_style) {
    transformed.fontStyle = fontProperty.font_style;
  }
  if (fontProperty.font_weight) {
    transformed.fontWeight = fontProperty.font_weight;
  }
  if (fontProperty.font_family) {
    transformed.fontFamily = fontProperty.font_family;
  }
  if (fontProperty.line_height) {
    transformed.lineHeight =
      typeof fontProperty.line_height === 'number'
        ? `${fontProperty.line_height}px`
        : fontProperty.line_height;
  }
  return transformed;
}

type Override<T extends keyof Font> = Partial<Record<T, (val: Font[T]) => Font[T]>>;

function getOverrides<T extends keyof Font>(
  fontProp: Font,
  overrides?: Override<T>,
): Partial<Font> {
  let transformed: Partial<Font> = fontProp;

  if (!overrides) {
    return transformed;
  }

  // in order to iterate over an object in a type safe way, we're using `for..in`
  for (const key in overrides) {
    const fn = overrides[key];
    const value = fontProp[key];

    transformed = {
      ...transformed,
      [key]: typeof fn === 'function' ? fn(value) : value,
    };
  }

  return transformed;
}

/**
 * Returns a list of Font config properites in CSSProperties object syntax
 *
 * (For example: `font_size` is returned as `fontSize`)
 *
 * This function is overloaded, and can be used in various ways:
 *
 * **Examples**
 *
 * Retrieving all CSS values for a font config:
 * ```tsx
 * const properties = getFontStyles(theme.menu.text.primary_font);
 * ```
 *
 * Retrieving all CSS values for a font config and modifying some properties:
 * ```tsx
 * const properties = getFontStyles(theme.menu.text.primary_font, {
 *  font_size: (value) => {
 *    if(typeof value === "number") {
 *      return value * 2;
 *    }
 *    return value;
 *  }
 * });
 * ```
 *
 * Retrieving a subset of CSS values for a font config:
 * ```tsx
 * const {fontStyle} = getFontStyles(theme.menu.text.primary_font, ["font_style"]);
 * ```
 *
 * Retrieving a subset of CSS values for a font config and modifying them:
 * ```tsx
 * const {fontStyle} = getFontStyles(theme.menu.text.primary_font, ["font_size"], {
 *  font_size: (value) => {
 *    if(typeof value === "number") {
 *      return value * 2;
 *    }
 *    return value;
 *  }
 * });
 * ```
 *
 * @param fontProperty
 */
export function getFontStyles(fontProp: Font): RuleSet<CSSProperties> & CSSProperties;
export function getFontStyles<T extends keyof Font>(
  fontProp: Font,
  includeOrOverride: T[] | Override<T>,
): RuleSet<CSSProperties> & CSSProperties;
export function getFontStyles<T extends keyof Font>(
  fontProp: Font,
  includeOrOverride: T[],
  overrides: Override<T>,
): RuleSet<CSSProperties> & CSSProperties;
export function getFontStyles<T extends keyof Font>(
  fontProp: Font,
  includeOrOverride?: T[] | Override<T>,
  overrides?: Override<T>,
): RuleSet<CSSProperties> & CSSProperties {
  // normalize include and override values due to overload variations
  const scopedInclude = Array.isArray(includeOrOverride) ? includeOrOverride : undefined;
  const scopedOverride = Array.isArray(includeOrOverride) ? overrides : includeOrOverride;

  // check all properties for overrides first
  let transformed = getOverrides(fontProp, scopedOverride);

  // finally, filter down the property list, if requested
  if (scopedInclude) {
    transformed = scopedInclude.reduce<Partial<Font>>(
      (acc, key) => ({ ...acc, [key]: transformed[key] }),
      {},
    );
  }

  return snakeToPascal(transformed) as RuleSet<CSSProperties> & CSSProperties;
}
