import { getOrgConfig } from '@koala/sdk/v4';
import { call, put, takeLatest, type SagaReturnType } from 'redux-saga/effects';
import actions from './actions';
import { ERROR_MESSAGES } from '@/constants/events';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireKAnalyticsError } from '@/utils/koalaAnalytics';

export function* fetchOrganizationSaga() {
  try {
    const client = createHttpClient({
      origin: getOrigin(window.location.host),
    });
    const response: SagaReturnType<typeof getOrgConfig> = yield call(getOrgConfig, { client });

    // Success
    // @ts-expect-error
    yield put(actions.fetchOrganizationSuccess(response));
  } catch (error) {
    yield put(actions.fetchOrganizationFail());

    // KA Events
    const errorResponse: SagaReturnType<typeof prepareErrorMessage> = yield call(
      prepareErrorMessage,
      null,
      error,
    );
    fireKAnalyticsError(ERROR_MESSAGES.FETCH_ORG_ERROR, error, errorResponse);
  }
}

export default function* rootSaga() {
  yield takeLatest(actions.FETCH_ORGANIZATION, fetchOrganizationSaga);
}
