import { type IGoogleEventParams } from '@/types/analytics';

/**
 * GA Actions
 */
export const gaActions = {
  addToBasket: 'Add to Basket',
  addCrossSell: 'Add Cross-sell',
  asapTimeSelected: 'ASAP Pickup Time Selected',
  basketNotValid: 'Basket not valid',
  basketExpired: 'Basket Expired',
  basketStructureInvalid: 'Basket Structure Invalid',
  basketFulfillmentNotSet: 'Basket fulfillment not set on existing basket',
  basketFulfillmenSet: 'Basket fulfillment set on existing basket',
  backToMenu: 'Back to Menu',
  cancelGuestCheckout: 'Cancel Guest Checkout',
  cancelSignInCheckout: 'Cancel Sign In Checkout',
  cancelSignUpCheckout: 'Cancel Sign Up Checkout',
  changeLocation: 'Change Location',
  checkoutAbandoned: 'Checkout Abandoned',
  checkoutError: 'Checkout Error',
  clickCategory: 'Click Category',
  clickCheckOut: 'Click Check Out',
  collapseShopDetails: 'Collapse Shop Details',
  couponApplied: 'Coupon applied',
  couponDenied: 'Coupon denied',
  couponRemoved: 'Coupon removed',
  couponRemovalFailed: 'Coupon removal failed',
  dietaryPreferencesUpdated: 'Dietary preferences updated',
  editItem: 'Edit Item',
  editContactInfo: 'Edit Contact Info',
  exitItemModifier: 'Exit Item Modifier',
  expandShopDetails: 'Expand Shop Details',
  geolocationSearch: 'Geolocation Search',
  leftSite: 'Left Site',
  locations: 'Locations',
  locationNotAvailable: 'Online Ordering Not Available',
  mapSearchArea: 'Search Map Area',
  menuSearch: 'Menu Search',
  nutritionInfo: 'Nutrition Info',
  orderDelivery: 'Order Delivery',
  orderConfirmation: 'Order Confirmation',
  orderFavorited: 'Order Favorited',
  orderUnfavorited: 'Order Unfavorited',
  mapPrimaryCta: 'Map Primary CTA',
  mapSecondaryCta: 'Map Secondary CTA',
  mapTertiaryCta: 'Map Tertiary CTA',
  mapQuaternaryCta: 'Map Quaternary CTA',
  paymentApplied: 'Payment applied',
  paymentDenied: 'Payment denied',
  pciProxyTokenFailure: 'Error exchanging PCI Proxy token for aliased card data',
  pciProxyApplied: 'PCI Proxy applied',
  pciProxyFailed: 'PCI Proxy failed',
  pickupTimeSelected: 'Pickup Time Selected',
  removeItem: 'Remove Item',
  saveCard: 'Save Card',
  savedPaymentSelected: 'Saved Payment Selected',
  scanCard: 'Scan Card',
  submitOrder: 'Submit Order',
  viewAccount: 'View Account',
  viewMenu: 'View Menu',
  viewOrder: 'View Order',
  viewPendingOrder: 'View Pending Order',
  viewProductDetail: 'View Product Detail',
  clickMenuItem: 'Click Menu Item',
  clickCrossSelllItem: 'Click Cross-Sell Item',
  clickLocationCardTertiaryCta: 'Click Location Card Tertiary CTA',
};

/**
 * GA Categories
 */
export const gaCats = {
  account: 'Account',
  browse: 'Browse',
  category: 'Category where event should roll up to',
  checkout: 'Checkout Flow',
  global: 'Global',
  findShop: 'Find Shop',
  nutrition: 'Nutrition',
  order: 'Order',
  orderHistory: 'Order History',
  orderStatus: 'Order Status',
};

/**
 * GA Tracker Name
 */
export const GOOGLE_TRACKER_NAME = 't0.';

/*
  Fire a GA event

  @param {String} category
  @param {String} action
  @param {Object} params
                    label
                    value
                    nonInteraction
                    transport
*/
export const fireGaEvent = (category: string, action: string, params?: IGoogleEventParams) => {
  if (typeof window?.dataLayer?.push === 'function') {
    // Push into dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: action });
  }

  // Conditionally fire GA
  //@TODO: This should be removed after UA is deprecated on July 1, 2023
  if (typeof window?.ga === 'function') {
    const mergedParams = {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: params?.label,
      eventValue: params?.value,
    };

    window.ga(`${GOOGLE_TRACKER_NAME}send`, mergedParams);
  }

  //Conditionally fire gtag.js when a tag id is present
  if (window?.gtag) {
    window.gtag('event', action, {
      eventCategory: category,
      eventLabel: params?.label,
      eventValue: params?.value,
    });
  }
};
