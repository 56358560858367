import { type ParsedNumber, parseNumber } from 'libphonenumber-js';
import filter from 'lodash/filter';
import { DATE_FORMAT } from '@/constants/dates';
import { addTime, getDate, isDateBefore } from '@/utils/dates';

// Phone Validation
export const invalidPhone = (phoneNumber: string | undefined) => {
  let invalid = true;

  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '').substring(0, 10);
  }

  const parsedNumber: {} | ParsedNumber = parseNumber(`+1${phoneNumber}`, 'US');
  if ('phone' in parsedNumber) {
    phoneNumber = parsedNumber.phone;
    invalid = false;
  }

  return invalid;
};

// Check to see if a page's window.location.pathname is included in the INCLUSIONS array in
// component_config by doing a substring check items in the array relative to the current path
export const inInclusionArray = (inclusionArray: string[], currentPath: string) => {
  if (
    inclusionArray &&
    !filter(inclusionArray, (path: string) => currentPath.includes(path)).length
  ) {
    return false;
  }

  return true;
};

export const creditCardExpiryErrors = {
  REQUIRED: 'Exp MM/YY required',
  FORMAT: 'Format must be MM/YY',
  EXPIRED: 'Expiry date must be in the future',
};
export const isCreditCardExpiryValid = (expiry: string): null | string => {
  // Existence check
  if (!expiry) {
    return creditCardExpiryErrors.REQUIRED;
  }

  // Format check
  if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(expiry)) {
    return creditCardExpiryErrors.FORMAT;
  }

  // Ensure exp is in the future (inclusive of current month)
  const expires = addTime(getDate(expiry, DATE_FORMAT.MONTH_WITH_YEAR), 1, 'months');
  const today = new Date();

  if (isDateBefore(expires, today)) {
    return creditCardExpiryErrors.EXPIRED;
  }

  return null;
};
