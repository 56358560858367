import { type Category } from '@koala/sdk';

const menuActions = {
  // Basket Menu
  FETCH_BASKET_MENU: '/koala/menu/FETCH_BASKET_MENU',
  FETCH_BASKET_MENU_ERROR: '/koala/menu/FETCH_BASKET_ERROR',
  SET_BASKET_MENU: '/koala/menu/SET_BASKET_MENU',
  SET_BASKET_MENU_FROM_STORAGE: '/koala/menu/SET_BASKET_MENU_FROM_STORAGE',
  HYDRATE_BASKET_MENU: '/koala/menu/HYDRATE_BASKET_MENU',
  PUSH_NAVIGATION_STATUS: '/koala/menu/PUSH_NAVIGATION_STATUS',
  REQUEST_NAVIGATION_UPDATE: '/koala/menu/REQUEST_NAVIGATION_UPDATE',

  refreshBasketMenu: (id: number, wantedAt?: string) => ({
    type: menuActions.FETCH_BASKET_MENU,
    id,
    wantedAt,
  }),
  hydrateBasketMenu: () => ({
    type: menuActions.HYDRATE_BASKET_MENU,
  }),
  setBasketMenu: (menuCategories: Category[], compressedCategories?: Uint8Array) => ({
    type: menuActions.SET_BASKET_MENU,
    menuCategories,
    compressedCategories,
  }),
  setBasketMenuFromStorage: (menuCategories: Category[]) => ({
    type: menuActions.SET_BASKET_MENU_FROM_STORAGE,
    menuCategories,
  }),
  pushNavigationStatus: (categoryId: string) => ({
    type: menuActions.PUSH_NAVIGATION_STATUS,
    categoryId,
  }),
  requestNavigationUpdate: (categoryId: string) => ({
    type: menuActions.REQUEST_NAVIGATION_UPDATE,
    categoryId,
  }),
};

export default menuActions;
