import { type AnyAction } from 'redux';
import actions from './actions';
import { FALLBACK_FEATURE_BAG } from '@/constants/features';
import { useDispatch } from '@/redux';
import { type RootState } from '@/types/app';
import { type IGlobalState } from '@/types/global';
import { toggleBodyScroll } from '@/utils/global';

export const initialState: IGlobalState = {
  token: null,
  basketOpen: false,
  scrollDisabled: false,
  fulfillmentModal: {
    display: false,
    location: null,
    type: null,
    confirm: false,
    addressValidity: null,
    timeslotOverride: null,
    errorMessage: null,
    revalidate: null,
    disableModalClose: false,
    miniConveyanceSwitch: false,
  },
  toast: {
    display: false,
    message: '',
    status: null,
    forceDismiss: false,
  },
  postAdd: {
    item: null,
    index: null,
  },
  cartMigration: {
    type: null,
    pendingItem: null,
  },
  routerStatus: {
    persistentParameters: {},
    currentPath: '',
  },
  featureBag: FALLBACK_FEATURE_BAG,
};

export const global = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.SET_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
      });
    case actions.REMOVE_TOKEN:
      return Object.assign({}, state, {
        token: null,
      });
    case actions.TOGGLE_BASKET:
      const basketOpen = typeof action.show !== 'undefined' ? action.show : !state.basketOpen;

      // Disable / enable body scrolling
      toggleBodyScroll(!basketOpen);

      return Object.assign({}, state, {
        basketOpen: typeof action.show !== 'undefined' ? action.show : !state.basketOpen,
      });
    case actions.TOGGLE_FULFILLMENT_MODAL:
      // Disable / enable body scrolling
      toggleBodyScroll(!action.show);

      return Object.assign({}, state, {
        fulfillmentModal: {
          display: action.show,
          location: action.location,
          type: action.fulfillmentType,
          confirm: action.confirm || false,
          addressValidity: null,
          timeslotOverride: action.timeslotOverride,
          revalidate: action.revalidate,
          disableModalClose: action.disableModalClose || false,
          miniConveyanceSwitch: action.miniConveyanceSwitch || false,
        },
      });
    case actions.DELIVERY_ADDRESS_VALID:
      return Object.assign({}, state, {
        fulfillmentModal: {
          ...state.fulfillmentModal,
          addressValidity: true,
          disableModalClose: false,
        },
      });
    case actions.DELIVERY_ADDRESS_INVALID:
      return Object.assign({}, state, {
        fulfillmentModal: {
          ...state.fulfillmentModal,
          addressValidity: false,
          errorMessage: action.errorMessage,
        },
      });
    case actions.DISPLAY_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          display: true,
          status: action.status,
          message: action.message,
          forceDismiss: action.forceDismiss,
        },
      };
    case actions.DISMISS_TOAST:
      return {
        ...state,
        toast: {
          ...state.toast,
          display: false,
          message: '',
          status: null,
          forceDismiss: false,
        },
      };
    case actions.RENDER_POST_ITEM_ADD_COMPONENT:
      return {
        ...state,
        postAdd: {
          item: action.item,
          index: action.index,
        },
      };
    case actions.SET_CART_MIGRATION:
      return {
        ...state,
        cartMigration: {
          type: action.cartMigration.type || null,
          pendingItem: action.cartMigration.pendingItem || null,
        },
      };
    case actions.SET_PERSISTENT_PARAMETERS:
      return {
        ...state,
        routerStatus: {
          ...state.routerStatus,
          persistentParameters: action.routeParameters,
        },
      };
    case actions.CLEAR_PERSISTENT_PARAMETERS:
      return {
        ...state,
        routerStatus: {
          ...state.routerStatus,
          persistentParameters: {},
        },
      };
    case actions.SET_ROUTER_PATH:
      return {
        ...state,
        routerStatus: {
          ...state.routerStatus,
          currentPath: action.pathname,
        },
      };
    case actions.HYDRATE_PERSISTENT_PARAMETERS:
      return state;
    case actions.UPDATE_FEATURE_BAG:
      return {
        ...state,
        featureBag: action.featureBag,
      };
    case actions.CLEAR_FEATURE_BAG:
      return {
        ...state,
        featureBag: FALLBACK_FEATURE_BAG,
      };
    case actions.GENERATE_FEATURE_BAG:
    case actions.UPDATE_FEATURE_BAG_FLAG:
    default:
      return state;
  }
};

export const globalSliceSelector = (state: RootState) => state.app.global;

export const useGlobalDispatch = () => {
  const dispatch = useDispatch();

  return {
    dispatch,
    ...actions,
  };
};
