import { type RequestMeta, KoalaEnv } from '@koala/sdk';
import { z } from 'zod';

/** Shorthand for checking if we're running in local development mode. */
export const __DEV = process.env.NODE_ENV !== 'production';

/** zzz */
export function sleep(ms: number) {
  return new Promise<null>((resolve) => setTimeout(resolve, ms));
}

/**
 * There's a limitation with the way we're able to fetch a request's origin
 * header in server-side-rendered components using React Query.
 *
 * Since the `NextRequest` object (which contains the header we need) is only
 * available within `getServerSideProps` functions in Next@12, we aren't
 * able to easily pass it down to child components rendered on the server.
 *
 * HOWEVER, in practice this isn't actually an issue because all server-side
 * React-Query queries should be pre-fetched using `queryClient.prefetchQuery`
 * inside of `getServerSideProps` — meaning that they will have access to
 * the `NextRequest`. When the `useQuery` hook runs server-side, it will
 * use the cached response from the prefetched query and not actually make
 * a network request with the empty request header.
 *
 * Let's look at the example below:
 * ```ts
 * let meta: RequestMeta;
 * if (typeof window !== "undefined") {
 *   meta = getRequestOrigin(window.location.host);
 * } else {
 *   meta = getRequestOrigin(__DANGEROUS_EMPTY_REQUEST_ORIGIN);
 * }
 * ```
 *
 * This code will run safely in 2 contexts:
 * ✅ On client-side route transitions — `window` will be defined.
 * ✅ On SSR — React Query will return a cached response and not hit the network.
 * ❌ This only fails if the query isn't pre-fetched before an SSR pass.
 *
 * @TODO a less fragile approach would be to:
 * 1. Create a new context/store/atom/etc. that wraps `_app.tsx`
 * 2. Pass the request origin header from `getInitialProps` to that context.
 * 3. Consume the request origin via that context in all components.
 */
export const __DANGEROUS_EMPTY_REQUEST_ORIGIN = '';

export function getOrigin(origin: string): string {
  /** FOR LOCAL DEVELOPMENT ONLY! */
  if (__DEV) {
    let brand: string;
    let environment: KoalaEnv;
    try {
      brand = z.string().parse(process.env.NEXT_PUBLIC_DEV_BRAND);
      environment = KoalaEnv.parse(process.env.NEXT_PUBLIC_ENV);
    } catch (e) {
      throw new Error(`🚨 Invalid environment or brand!
      ——————————————————————————————————————————————————————————————
      Please check your .env.local file and ensure that both
      \`NEXT_PUBLIC_ENV\` and \`NEXT_PUBLIC_DEV_BRAND\` are set.
      ——————————————————————————————————————————————————————————————`);
    }
    const origin =
      environment === 'production' ? `${brand}.onlineorder.site` : `${brand}.chowly.io`;

    return origin;
  }
  return origin;
}

export function getRequestOrigin(origin: string): RequestMeta {
  const originString = getOrigin(origin);
  if (__DEV) {
    return {
      headers: { 'x-request-origin': originString, 'x-local-dev': 'true' },
    };
  }
  return { headers: { 'x-request-origin': originString } };
}
