import { type AnyAction } from 'redux';
import actions from './actions';
import { type IPaymentHandlerState } from '@/types/paymentHandler';

export const initialState: IPaymentHandlerState = {
  getPayment: false,
  paymentData: null,
};

export const paymentHandler = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.RESET_CARD_DATA:
      return Object.assign({}, state, {
        getPayment: false,
      });
    case actions.TRIGGER_GET_PAYMENT:
      return Object.assign({}, state, {
        getPayment: true,
      });
    case actions.START_SUBMIT_PAYMENT:
      return Object.assign({}, state, {
        getPayment: false,
      });
    case actions.START_SQUARE_SUBMIT_PAYMENT:
      return Object.assign({}, state, {
        getPayment: false,
        paymentData: {
          id: action.nonce,
        },
      });
    default:
      return state;
  }
};
